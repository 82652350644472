import Vue from 'vue';
import allowedCountriesDetails from '~/config/allowedCountriesDetails';
import { priceFilter } from '~/filters/pricingFilters';

export default class MeService {
    constructor({ $axios, $auth, $sentry }) {
        this.$axios = $axios;
        this.$auth = $auth;
        this.$sentry = $sentry;
    }

    /**
     * Get current user info
     */
    async getMe() {
        const config = {
            method: 'get',
            url: '/me',
        };

        const response = await this.$axios(config);
        const user = response.data.data;

        this.$auth.setUser(user);

        return user;
    }

    /**
     * Make a request to store profile details
     * @param {object} data
     */
    async updateMe(data) {
        const config = {
            method: 'put',
            url: `/me`,
            data,
        };

        const response = await this.$axios(config);
        const user = response.data.data;

        this.$auth.setUser(user);

        return user;
    }

    /**
     * Make a request to delete user's account
     * @param {string} password
     */
    deleteMe(password) {
        const config = {
            method: 'delete',
            url: `/me`,
            data: { password },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Make a request to update password
     * @param {string} currentPassword
     * @param {string} newPassword
     */
    updatePassword(currentPassword, newPassword) {
        const config = {
            method: 'post',
            url: `/me/update-password`,
            data: { currentPassword, newPassword },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Update user's avatar (e.g. change type)
     */
    updateAvatar(data) {
        const config = {
            method: 'put',
            url: `/me/avatar`,
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Rotate user's avatar right
     */
    rotateAvatarRight() {
        const config = {
            method: 'post',
            url: `/me/avatar/rotate-right`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Rotate user's avatar left
     */
    rotateAvatarLeft() {
        const config = {
            method: 'post',
            url: `/me/avatar/rotate-left`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Delete user's avatar
     */
    deleteAvatar() {
        const config = {
            method: 'delete',
            url: `/me/avatar`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Rotate user's organization logo right
     */
    rotateOrganizationLogoRight() {
        const config = {
            method: 'post',
            url: `/me/organization-logotype/rotate-right`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Rotate user's organization logo left
     */
    rotateOrganizationLogoLeft() {
        const config = {
            method: 'post',
            url: `/me/organization-logotype/rotate-left`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Delete user's organization logo
     */
    deleteOrganizationLogo() {
        const config = {
            method: 'delete',
            url: `/me/organization-logotype`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get current user status
     */
    getStatus(assetId) {
        const config = {
            method: 'get',
            url: '/me/status',
        };

        if (assetId) {
            config.params = { assetId };
        }

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get current user's conversations
     */
    getConversations() {
        const config = {
            method: 'get',
            url: '/me/conversations',
        };

        return this.$axios(config).then(response => response.data.data);
    }

    async getConversationWith(otherUserId) {
        const conversations = (await this.getConversations()).filter(conversation => {
            return conversation.users.some(user => user.id === otherUserId);
        });

        return conversations[0];
    }

    /**
     * Get current user's written and received reviews
     */
    getReviews() {
        const config = {
            method: 'get',
            url: '/user-reviews',
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get payment method for current user
     */
    getPaymentMethods(stripeAccount) {
        const config = {
            method: 'get',
            url: '/me/payment-methods',
            params: { 'stripe-account': stripeAccount, filter: { status: ['succeeded', 'requires_confirmation'] } },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Make a request to store payment method using the given Stripe source id
     * @param {string} stripeAccount
     * @param {string} paymentMethodId
     */
    addPaymentMethod(stripeAccount, paymentMethodId) {
        const config = {
            method: 'post',
            url: '/me/payment-methods',
            data: {
                stripeAccount,
                paymentMethodId,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Make a request to detach payment method using the given Stripe source id
     * @param {string} stripeAccount
     * @param {string} paymentMethodId
     */
    deletePaymentMethod(id) {
        const config = {
            method: 'delete',
            url: `/me/payment-methods/${id}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get bank account details for current user
     */
    getBankAccount() {
        const config = {
            method: 'get',
            url: '/me/bank-account',
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get yearly stats current or given user
     */
    getYearStats(userId) {
        const config = {
            method: 'get',
            url: `/me/year-stats`,
            params: {
                userId,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Make a request to update Stripe Account verification status
     */
    updateStripeAccountVerificationStatus() {
        const config = {
            method: 'post',
            url: '/me/update-stripe-account-verification-status',
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Store user's bank details
     * @param {string} stripeAccount
     * @param {object} iban StripeJS IBAN element
     * @param {object} accountData Verification data required by Stripe
     * @param {string} currency Currency code, e.g. 'sek'
     */
    saveBankAccount($stripeService, stripeAccount, iban, accountData, currency) {
        const individualData = accountData.individual;
        const birthday = individualData.dob;

        const name = `${individualData.first_name} ${individualData.last_name}`;
        const bankAccountData = {
            currency: currency.toLowerCase(),
            account_holder_name: name,
            account_holder_type: 'individual',
        };

        const apiConfig = {
            method: 'post',
            url: '/me/bank-account',
            data: { stripeAccount },
        };

        return $stripeService.withStripeAccount(stripeAccount).then(stripe => {
            const tokenPromises = [stripe.createToken(iban, bankAccountData)];

            // Only get a token for Stripe Account creation if a birthday is given
            if (birthday.year && birthday.month && birthday.day) {
                tokenPromises.push(stripe.createToken('account', accountData));
            }

            return Promise.all(tokenPromises).then(tokens => {
                const bankAccountToken = tokens[0];
                const accountToken = tokens[1];

                apiConfig.data.accountToken = accountToken;
                apiConfig.data.bankAccountToken = bankAccountToken;

                return this.$axios(apiConfig).then(response => response.data.data);
            });
        });
    }

    /**
     * Handles Google Analytics for a favored asset
     * @param {object} asset
     */
    trackFavoredEvent(asset) {
        let pageType;
        let itemListName;

        // Gets page type based on the route name where user favored the asset
        const relevantRoutes = [
            { nuxtName: 'index', googleAnalyticsName: 'homepage' },
            { nuxtName: 'all', googleAnalyticsName: 'searchresult' },
            { nuxtName: 'vehicle', googleAnalyticsName: 'pdp' },
        ];
        relevantRoutes.forEach(route => {
            if (asset.savedPageName.includes(route.nuxtName)) {
                pageType = route.googleAnalyticsName;
            }
        });

        // Details page has two places where you can favor an asset: Main image and similar assets list.
        if (asset.indexInList && pageType === 'pdp') {
            itemListName = 'pdp_similar';
        } else {
            itemListName = pageType;
        }

        const ownerStatus = asset.owner?.topOwner ? 'top owner' : 'regular';
        const totalPrice = asset.totalPrice && Math.round(priceFilter(asset.totalPrice));

        Vue.prototype.$gtm.trackEvent({
            event: 'add_to_wishlist',
            page_type: pageType,
            ecommerce: {
                currency: asset.currency,
                value: totalPrice,
                items: [
                    {
                        item_id: asset.id,
                        index: asset.indexInList + 1,
                        item_owner_status: ownerStatus,
                        item_brand: asset.brand,
                        item_category: asset.type,
                        item_category2: asset.subType,
                        item_list_name: itemListName,
                        item_country: asset.country,
                        price: totalPrice,
                        item_cancellation_policy: asset.cancellationPolicy,
                        item_booking_method: 'Non-binding booking',
                        item_number_of_reviews: asset.reviewCount,
                        item_rating: asset.averageRating || 0,
                    },
                ],
            },
        });
    }

    /**
     * Adds an asset to favorites for the current user
     * @param {object} asset
     */
    async addAssetToFavorites(asset) {
        const config = {
            method: 'post',
            url: `/me/favorite-assets`,
            data: {
                assetId: asset.id,
            },
        };

        try {
            const response = await this.$axios(config);
            this.trackFavoredEvent(asset);

            return response.data.data;
        } catch (error) {
            this.$sentry.captureException(error);
        }
    }

    /**
     * Removes an asset from favorites for the current user
     * @param {object} assetId
     */
    removeAssetFromFavorites(assetId) {
        const config = {
            method: 'delete',
            url: `/me/favorite-assets`,
            data: {
                assetId,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Gets all favored assets for the current user
     */
    fetchAllFavoredAssets() {
        const config = {
            method: 'get',
            url: `/me/favorite-assets`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Gets users location based on the timezone
     * @returns Either an allowed country or null
     */
    getUserCountryBasedOnTimezone() {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (!timezone) {
            return null;
        }

        const country = allowedCountriesDetails.find(c => c.timezones.includes(timezone));

        return country || null;
    }

    /**
     * Gets owner's booking statistic
     */
    getOwnerPerformance() {
        const config = {
            method: 'get',
            url: '/me/owner-performance-data',
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Gets user unread conversations with last message
     */
    getUnreadConversations() {
        const config = {
            method: 'get',
            url: '/me/unread-conversations',
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Updates user's subscription
     * @param {string} subscriptionKey
     * @param {boolean} isSubscribed
     */
    updateSubscription(subscriptionKey, isSubscribed) {
        const config = {
            method: 'put',
            url: `/me/subscriptions`,
            data: {
                subscriptionKey,
                isSubscribed,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    getOldPlatformBookings() {
        const config = {
            method: 'get',
            url: '/me/old-platform-bookings',
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
