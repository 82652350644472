import LocalStorageService, { IS_IMPERSONATING, IMPERSONATE_ADMIN_ID } from '~/services/LocalStorageService';

export const mutationTypes = {
    IS_IMPERSONATING_SET: 'IMPERSONATE/IS_IMPERSONATING_SET',
    ADMIN_ID_SET: 'IMPERSONATE/ADMIN_ID_SET',
};

export const actionTypes = {
    SET_IS_IMPERSONATING: 'IMPERSONATE/SET_IS_IMPERSONATING',
    SET_ADMIN_ID: 'IMPERSONATE/SET_ADMIN_ID',
    LOAD_FROM_STORAGE: 'IMPERSONATE/LOAD_FROM_STORAGE',
    CLEAR: 'IMPERSONATE/CLEAR',
};

export const state = () => ({
    isImpersonating: false,
    adminId: null,
});

export const getters = {
    isImpersonating: state => state.isImpersonating,
    getAdminId: state => state.adminId,
};

export const mutations = {
    [mutationTypes.IS_IMPERSONATING_SET](state, payload) {
        state.isImpersonating = payload;
    },
    [mutationTypes.ADMIN_ID_SET](state, payload) {
        state.adminId = payload;
    },
};

export const actions = {
    [actionTypes.LOAD_FROM_STORAGE]({ dispatch, commit, getters }) {
        if (!getters.isLoggedIn) {
            return dispatch(actionTypes.CLEAR);
        }

        const localStorage = new LocalStorageService();
        const storedIsImpersonating = localStorage.getItem(IS_IMPERSONATING);
        const storedAdminId = localStorage.getItem(IMPERSONATE_ADMIN_ID);

        if (storedIsImpersonating) {
            commit(mutationTypes.IS_IMPERSONATING_SET, JSON.parse(storedIsImpersonating));
        }

        if (storedAdminId) {
            commit(mutationTypes.ADMIN_ID_SET, JSON.parse(storedAdminId));
        }
    },
    [actionTypes.SET_IS_IMPERSONATING]({ commit }, payload) {
        commit(mutationTypes.IS_IMPERSONATING_SET, payload);
    },
    [actionTypes.SET_ADMIN_ID]({ commit }, payload) {
        commit(mutationTypes.ADMIN_ID_SET, payload);
    },
    [actionTypes.CLEAR]({ commit }) {
        commit(mutationTypes.IS_IMPERSONATING_SET, false);
        commit(mutationTypes.ADMIN_ID_SET, null);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
};
