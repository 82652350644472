export default class PageService {
    constructor({ $axios, $sentry }) {
        this.$axios = $axios;
        this.$sentry = $sentry;
    }

    getPageTree(locale) {
        const config = {
            method: 'get',
            url: '/pages',
            params: {
                filter: {
                    isRoot: true,
                    locale,
                },
                include: 'children',
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    getPageWithPath(path, locale) {
        // Trim leading and trailing slashes
        const pathParts = path.replace(/^\/|\/$/g, '').split('/');

        let params;

        if (pathParts.length === 1) {
            params = {
                filter: {
                    isRoot: true,
                    slug: pathParts[0],
                    locale,
                },
                include: 'children',
            };
        } else {
            params = {
                filter: {
                    path,
                    locale,
                },
                include: 'parent,children',
            };
        }

        const config = {
            method: 'get',
            url: '/pages',
            params,
        };

        return this.$axios(config)
            .then(rawResponse => {
                const response = rawResponse.data;

                if (!response.data || response.data.length === 0) {
                    throw new Error('404 page not found');
                }

                return response;
            })
            .then(response => response.data[0]);
    }

    /**
     * Tries to find a translation for the locale in the given page or its tree of ancestors
     *
     * @param {object} page CMS page object
     * @param {string} locale Locale to find matching translation for
     * @returns object|null
     */
    findMatchingTranslation(page, locale) {
        let translation = page.translations && page.translations.find(p => p.locale === locale);

        // Recursively check parent pages for a matching translation
        if (!translation && page.parent) {
            translation = this.findMatchingTranslation(page.parent, locale);
        }

        return translation || null;
    }

    /**
     * Removes any locale prefix from the given path
     *
     * @param {string} path E.g. /en/about-us
     * @param {array} locales Array of locale objects
     * @returns string E.g. /about-us
     */
    removeAnyLocalePrefix(path, locales) {
        for (const locale of locales) {
            const prefix = `/${locale.code}/`;

            if (path.startsWith(prefix)) {
                return path.substring(prefix.length - 1);
            }
        }

        return path;
    }

    /**
     * Builds a route params object for the given page for all locales except the current one
     *
     * The route params object is used by the language switcher to build links to translated pages.
     *
     * @param {object} page CMS page object
     * @param {array} allLocales Array of locale objects
     * @param {string} currentLocale Current locale code from the i18n module
     */
    getRouteParams(page, allLocales, currentLocale) {
        const routeParams = {};

        allLocales.forEach(locale => {
            if (currentLocale === locale.code) {
                return;
            }

            const translatedPage = this.findMatchingTranslation(page, locale.iso);

            if (translatedPage) {
                routeParams[locale.code] = {
                    pathMatch: translatedPage.path.replace(/^\//, ''),
                    fullUrl: translatedPage.url,
                };
            }
        });

        return routeParams;
    }
}
