// All countries that we allow
// @note Sync with allowed_country_codes array in backend's config

const allowedCountriesDetails = [
    {
        name: 'Austria',
        dialCode: '+43',
        code: 'AT',
        timezones: ['Europe/Vienna'],
        locale: 'de',
        mapCenter: { lat: 47.5162, lng: 14.5501 },
        mapZoom: 7,
    },
    {
        name: 'Australia',
        dialCode: '+61',
        code: 'AU',
        timezones: [
            'Australia/Sydney',
            'Australia/Melbourne',
            'Australia/Perth',
            'Australia/Brisbane',
            'Australia/Adelaide',
        ],
        locale: 'en',
        mapCenter: { lat: -25.2744, lng: 133.7751 },
        mapZoom: 4,
    },
    {
        name: 'Belgium',
        dialCode: '+32',
        code: 'BE',
        timezones: ['Europe/Brussels'],
        locale: 'nl',
        mapCenter: { lat: 50.5039, lng: 4.4699 },
        mapZoom: 7,
    },
    {
        name: 'Bulgaria',
        dialCode: '+359',
        code: 'BG',
        timezones: ['Europe/Sofia'],
        locale: 'bg',
        mapCenter: { lat: 42.7339, lng: 25.4858 },
        mapZoom: 6,
    },
    {
        name: 'Canada',
        dialCode: '+1',
        code: 'CA',
        timezones: ['America/Toronto', 'America/Vancouver', 'America/Edmonton', 'America/Halifax', 'America/St_Johns'],
        locale: 'en',
        mapCenter: { lat: 56.1304, lng: -106.3468 },
        mapZoom: 4,
    },
    {
        name: 'Croatia',
        dialCode: '+385',
        code: 'HR',
        timezones: ['Europe/Zagreb'],
        locale: 'hr',
        mapCenter: { lat: 45.1, lng: 15.2 },
        mapZoom: 6,
    },
    {
        name: 'Cyprus',
        dialCode: '+357',
        code: 'CY',
        timezones: ['Asia/Nicosia'],
        locale: 'el',
        mapCenter: { lat: 35.1264, lng: 33.4299 },
        mapZoom: 6,
    },
    {
        name: 'Czech Republic',
        dialCode: '+420',
        code: 'CZ',
        timezones: ['Europe/Prague'],
        locale: 'cs',
        mapCenter: { lat: 49.817492, lng: 15.472962 },
        mapZoom: 5,
    },
    {
        name: 'Denmark',
        dialCode: '+45',
        code: 'DK',
        timezones: ['Europe/Copenhagen'],
        locale: 'dk',
        mapCenter: { lat: 55.4968389623689, lng: 9.474533545291404 },
        mapZoom: 7,
    },
    {
        name: 'Estonia',
        dialCode: '+372',
        code: 'EE',
        timezones: ['Europe/Tallinn'],
        locale: 'et',
        mapCenter: { lat: 58.5953, lng: 25.0136 },
        mapZoom: 6,
    },
    {
        name: 'Finland',
        dialCode: '+358',
        code: 'FI',
        timezones: ['Europe/Helsinki'],
        locale: 'fi',
        mapCenter: { lat: 65.192059, lng: 24.945831 },
        mapZoom: 5,
    },
    {
        name: 'France',
        dialCode: '+33',
        code: 'FR',
        timezones: ['Europe/Paris'],
        locale: 'fr',
        mapCenter: { lat: 46.227638, lng: 2.213749 },
        mapZoom: 5,
    },
    {
        name: 'Germany',
        dialCode: '+49',
        code: 'DE',
        timezones: ['Europe/Berlin', 'Europe/Busingen'],
        locale: 'de',
        mapCenter: { lat: 51.1657, lng: 10.4515 },
        mapZoom: 6,
    },
    {
        name: 'Greece',
        dialCode: '+30',
        code: 'GR',
        timezones: ['Europe/Athens'],
        locale: 'el',
        mapCenter: { lat: 39.074208, lng: 21.824312 },
        mapZoom: 5,
    },
    {
        name: 'Hungary',
        dialCode: '+36',
        code: 'HU',
        timezones: ['Europe/Budapest'],
        locale: 'hu',
        mapCenter: { lat: 47.1625, lng: 19.5033 },
        mapZoom: 6,
    },
    {
        name: 'Iceland',
        dialCode: '+354',
        code: 'IS',
        timezones: ['Atlantic/Reykjavik'],
        locale: 'is',
        mapCenter: { lat: 64.963051, lng: -19.020835 },
        mapZoom: 5,
    },
    {
        name: 'Ireland',
        dialCode: '+353',
        code: 'IE',
        timezones: ['Europe/Dublin'],
        locale: 'en',
        mapCenter: { lat: 53.41291, lng: -8.24389 },
        mapZoom: 5,
    },
    {
        name: 'Italy',
        dialCode: '+39',
        code: 'IT',
        timezones: ['Europe/Rome'],
        locale: 'it',
        mapCenter: { lat: 41.87194, lng: 12.56738 },
        mapZoom: 5,
    },
    {
        name: 'Latvia',
        dialCode: '+371',
        code: 'LV',
        timezones: ['Europe/Riga'],
        locale: 'lv',
        mapCenter: { lat: 56.8796, lng: 24.6032 },
        mapZoom: 6,
    },
    {
        name: 'Liechtenstein',
        dialCode: '+423',
        code: 'LI',
        timezones: ['Europe/Vaduz'],
        locale: 'de',
        mapCenter: { lat: 47.166, lng: 9.555373 },
        mapZoom: 7,
    },
    {
        name: 'Lithuania',
        dialCode: '+370',
        code: 'LT',
        timezones: ['Europe/Vilnius'],
        locale: 'lt',
        mapCenter: { lat: 55.1694, lng: 23.8813 },
        mapZoom: 6,
    },
    {
        name: 'Luxembourg',
        dialCode: '+352',
        code: 'LU',
        timezones: ['Europe/Luxembourg'],
        locale: 'de',
        mapCenter: { lat: 49.815273, lng: 6.129583 },
        mapZoom: 7,
    },
    {
        name: 'Malta',
        dialCode: '+356',
        code: 'MT',
        timezones: ['Europe/Malta'],
        locale: 'en',
        mapCenter: { lat: 35.937496, lng: 14.375416 },
        mapZoom: 7,
    },
    {
        name: 'Netherlands',
        dialCode: '+31',
        code: 'NL',
        timezones: ['Europe/Amsterdam'],
        locale: 'nl',
        mapCenter: { lat: 52.132633, lng: 5.291266 },
        mapZoom: 7,
    },
    {
        name: 'New Zealand',
        dialCode: '+64',
        code: 'NZ',
        timezones: ['Pacific/Auckland', 'Pacific/Chatham'],
        locale: 'en',
        mapCenter: { lat: -40.9006, lng: 174.886 },
        mapZoom: 6,
    },
    {
        name: 'Norway',
        dialCode: '+47',
        code: 'NO',
        timezones: ['Europe/Oslo'],
        locale: 'no',
        mapCenter: { lat: 64.329, lng: 10.501 },
        mapZoom: 5,
    },
    {
        name: 'Poland',
        dialCode: '+48',
        code: 'PL',
        timezones: ['Europe/Warsaw'],
        locale: 'pl',
        mapCenter: { lat: 51.9194, lng: 19.1451 },
        mapZoom: 6,
    },
    {
        name: 'Portugal',
        dialCode: '+351',
        code: 'PT',
        timezones: ['Europe/Lisbon', 'Atlantic/Azores', 'Atlantic/Madeira'],
        locale: 'pt',
        mapCenter: { lat: 39.399872, lng: -8.224454 },
        mapZoom: 5,
    },
    {
        name: 'Romania',
        dialCode: '+40',
        code: 'RO',
        timezones: ['Europe/Bucharest'],
        locale: 'ro',
        mapCenter: { lat: 45.9432, lng: 24.9668 },
        mapZoom: 6,
    },
    {
        name: 'Slovakia',
        dialCode: '+421',
        code: 'SK',
        timezones: ['Europe/Bratislava'],
        locale: 'sk',
        mapCenter: { lat: 48.669, lng: 19.699 },
        mapZoom: 6,
    },
    {
        name: 'Slovenia',
        dialCode: '+386',
        code: 'SI',
        timezones: ['Europe/Ljubljana'],
        locale: 'sl',
        mapCenter: { lat: 46.1512, lng: 14.9955 },
        mapZoom: 6,
    },
    {
        name: 'Spain',
        dialCode: '+34',
        code: 'ES',
        timezones: ['Europe/Madrid', 'Atlantic/Canary'],
        locale: 'es',
        mapCenter: { lat: 40.46366700000001, lng: -3.74922 },
        mapZoom: 5,
    },
    {
        name: 'Sweden',
        dialCode: '+46',
        code: 'SE',
        timezones: ['Europe/Stockholm'],
        locale: 'se',
        mapCenter: { lat: 62.329, lng: 15.101 },
        mapZoom: 5,
    },
    {
        name: 'Switzerland',
        dialCode: '+41',
        code: 'CH',
        timezones: ['Europe/Zurich'],
        locale: 'de',
        mapCenter: { lat: 46.8182, lng: 8.2275 },
        mapZoom: 7,
    },
    {
        name: 'United Kingdom',
        dialCode: '+44',
        code: 'UK',
        timezones: ['GB', 'Europe/London', 'Europe/Belfast', 'GB-Eire'],
        locale: 'en',
        mapCenter: { lat: 55.378051, lng: -3.435973 },
        mapZoom: 5,
    },
    {
        name: 'United States',
        dialCode: '+1',
        code: 'US',
        timezones: [
            'America/New_York',
            'America/Chicago',
            'America/Denver',
            'America/Los_Angeles',
            'America/Anchorage',
            'Pacific/Honolulu',
        ],
        locale: 'en',
        mapCenter: { lat: 37.0902, lng: -95.7129 },
        mapZoom: 4,
    },
];

export function getAllowedCountryCodes() {
    return allowedCountriesDetails.map(country => {
        return country.code;
    });
}

export default allowedCountriesDetails;
