export default class OrderService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    /**
     * Get the given order
     * @param {integer} orderId
     */
    get(orderId) {
        const config = {
            method: 'get',
            url: `/orders/${orderId}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Confirm the given order
     * @param {integer} orderId
     */
    confirm(orderId) {
        const config = {
            method: 'post',
            url: `/orders/${orderId}/confirm`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get installments related to given order
     * @param {integer} orderId Order to fetch installments for
     * @param {string|string[]} status Filter by installment statuses (see installmentStatuses.js)
     * @param {string|string[]} type Filter by installment type (see installmentTypes.js)
     */
    getInstallmentsFor(orderId, status = null, type = null) {
        const config = {
            method: 'get',
            url: `/orders/${orderId}/installments`,
        };

        if (status || type) {
            config.params = { filter: {} };

            if (status) {
                config.params.filter.status = status;
            }

            if (type) {
                config.params.filter.type = type;
            }
        }

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Add an order item to the given order
     * @param {integer} orderId Order to add to
     * @param {integer} productId Product to add
     * @param {integer|null} quantity Quantity of the product to add
     */
    addOrderItem(orderId, productId, quantity) {
        const data = { productId };

        if (quantity) {
            data.quantity = quantity;
        }

        const config = {
            method: 'post',
            url: `/orders/${orderId}/items`,
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * @param orderId
     * @returns {Promise<AxiosResponse<any>>}
     */
    extendOwnerConfirmation(orderId) {
        const config = {
            method: 'post',
            url: `/orders/${orderId}/extend-confirm`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get order items for the given order
     * @param {integer} orderId
     */
    getOrderItems(orderId) {
        const config = {
            method: 'get',
            url: `/orders/${orderId}/items`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get order discounts for the given order
     * @param {integer} orderId
     * @param {string} type Filter by discount type (owner or platform_fee)
     */
    getOrderDiscounts(orderId, type = null) {
        const config = {
            method: 'get',
            url: `/orders/${orderId}/discounts`,
        };

        if (type) {
            config.params = { filter: { type } };
        }

        return this.$axios(config).then(response => response.data.data);
    }
}
