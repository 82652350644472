<template>
    <div class="loading-indicator">
        <div v-if="hasText" class="loading-indicator__text">
            <slot />
        </div>
        <div class="loading-indicator__dots">
            <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
            <span class="loading-indicator__dot"> • </span>
            <span class="loading-indicator__dot"> • </span>
            <span class="loading-indicator__dot"> • </span>
            <!-- eslint-enable @intlify/vue-i18n/no-raw-text -->
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            hasText: this.$slots.default,
        };
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

@keyframes typing {
    0% {
        opacity: 0.1;
    }
    20% {
        opacity: 0.6;
    }
    100% {
        opacity: 0.1;
    }
}

.loading-indicator {
    text-align: center;
    &__text {
        @extend %heading-3;
        margin-bottom: 10px;
    }

    &__dots {
        height: 16px;
    }

    &__dot {
        @include font-size(30px);
        display: inline-block;
        line-height: 16px;
        animation: typing 1.6s infinite;
        &:nth-child(2) {
            animation-delay: 0.4s;
        }
        &:last-child {
            animation-delay: 0.8s;
        }
    }
}
</style>
