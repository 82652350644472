import LocalStorageService, { CART } from '~/services/LocalStorageService';

/* eslint-disable no-param-reassign, no-shadow */
export const mutationTypes = {
    SET_BOOKING_REQUEST: 'CART/SET_BOOKING_REQUEST',
    SET_MESSAGE: 'CART/SET_MESSAGE',
    UPDATE_CART_TIMESTAMP: 'CART/UPDATE_CART_TIMESTAMP',
    CART_RESET: 'CART/RESET',
};

export const actionTypes = {
    STORE_BOOKING_REQUEST: 'CART/STORE_BOOKING_REQUEST',
    STORE_PENDING_MESSAGE: 'CART/STORE_PENDING_MESSAGE',
    UPDATE_CART_TIMESTAMP: 'CART/UPDATE_CART_TIMESTAMP',
    LOAD_FROM_STORAGE: 'CART/LOAD_FROM_STORAGE',
    CLEAR: 'CART/CLEAR',
};

export const emptyState = {
    pendingBookingRequest: null,
    pendingMessage: null,
    cartTimestamp: null,
};

function getInitialState() {
    const localStorage = new LocalStorageService();
    let state = localStorage.getItem(CART);

    if (state) {
        state = JSON.parse(state);
    } else {
        state = {};
    }

    return { ...emptyState, ...state };
}

export const state = () => getInitialState();

export const getters = {
    pendingBookingRequest: state => state.pendingBookingRequest,
    hasPendingBookingRequest: state => !!state.pendingBookingRequest,
    pendingMessage: state => state.pendingMessage,
    hasPendingMessage: state => !!state.pendingMessage,
    cartTimestamp: state => state.cartTimestamp,
};

export const mutations = {
    [mutationTypes.SET_BOOKING_REQUEST](state, payload) {
        state.pendingBookingRequest = { ...payload };
    },
    [mutationTypes.SET_MESSAGE](state, payload) {
        state.pendingMessage = { ...payload };
    },
    [mutationTypes.UPDATE_CART_TIMESTAMP](state, timestamp) {
        if (!timestamp) {
            const date = new Date();
            // Format to: yyyy-MM-dd HH:mm:ss
            state.cartTimestamp = `${date.toLocaleDateString('sv-SE')} ${date.toLocaleTimeString('sv-SE')}`;
        } else {
            state.cartTimestamp = timestamp;
        }
    },
    // eslint-disable-next-line no-unused-vars
    [mutationTypes.CART_RESET](state) {
        Object.keys(emptyState).forEach(key => {
            state[key] = emptyState[key];
        });
    },
};

export const actions = {
    [actionTypes.STORE_BOOKING_REQUEST]({ commit }, bookingRequestData) {
        commit(mutationTypes.SET_BOOKING_REQUEST, bookingRequestData);
        commit(mutationTypes.UPDATE_CART_TIMESTAMP);
    },
    [actionTypes.STORE_PENDING_MESSAGE]({ commit }, messageData) {
        commit(mutationTypes.SET_MESSAGE, messageData);
        commit(mutationTypes.UPDATE_CART_TIMESTAMP);
    },
    [actionTypes.LOAD_FROM_STORAGE]({ commit }) {
        const storedState = getInitialState();

        if (storedState.pendingBookingRequest) {
            commit(mutationTypes.SET_BOOKING_REQUEST, storedState.pendingBookingRequest);
        }

        if (storedState.pendingMessage) {
            commit(mutationTypes.SET_MESSAGE, storedState.pendingMessage);
        }

        if (storedState.cartTimestamp) {
            commit(mutationTypes.UPDATE_CART_TIMESTAMP, storedState.cartTimestamp);
        }
    },
    [actionTypes.CLEAR]({ commit }) {
        commit(mutationTypes.CART_RESET);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
    mutationTypes,
};
