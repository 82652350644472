/**
 * Vuex plugin that subscribes on user actions to start and stop polling this.$meService.getStatus()
 * when the user logs in and out.
 */

import { actionTypes as userStatusActions } from '~/store/userStatus';

export const POLL_INTERVAL_MS = 30 * 1000;

const pollUserStatusPlugin = store => {
    let intervalId = null;

    const startPolling = () => {
        if (intervalId !== null) {
            return;
        }

        // Fetch immediately and setup interval
        store.dispatch(userStatusActions.FETCH);

        intervalId = setInterval(() => store.dispatch(userStatusActions.FETCH), POLL_INTERVAL_MS);
    };

    const stopPolling = () => {
        clearInterval(intervalId);
        store.dispatch(userStatusActions.CLEAR);
        intervalId = null;
    };

    store.subscribe(mutation => {
        const { type, payload } = mutation;

        switch (type) {
            case 'auth/SET':
                if (payload.key !== 'loggedIn') {
                    return;
                }

                if (payload.value) {
                    startPolling();
                } else {
                    stopPolling();
                }

                break;
            default: // Do nothing
        }
    });
};

export default pollUserStatusPlugin;
