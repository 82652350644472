<template>
    <div class="avatar-wrapper" @click.prevent="handleClick">
        <div v-if="badgeCount > 0" class="badge avatar-wrapper__badge">
            {{ badgeCount }}
        </div>
        <template v-if="avatarUrl">
            <picture>
                <source v-if="webpAvatarUrl" :srcset="webpAvatarUrl" type="image/webp" />
                <img
                    :class="modifierClasses"
                    :src="avatarUrl"
                    class="avatar"
                    :alt="$t('user_info.avatar_alt_text', { personName })"
                />
            </picture>
        </template>

        <div v-if="!avatarUrl" class="avatar avatar--fallback" :class="modifierClasses">
            <svg-icon name="person" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // Either provide :user...
        user: {
            type: Object,
            default: () => {},
        },
        // ...or :url and :name (+optionally :webp-url)
        url: {
            type: String,
            default: null,
        },
        webpUrl: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },

        size: {
            type: String,
            default: 'default',
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        useFullSizeImg: {
            type: Boolean,
            default: false,
        },
        badgeCount: {
            type: Number,
            default: null,
        },
    },
    computed: {
        avatarUrl() {
            if (this.useFullSizeImg && this.user?.fullSizeAvatarUrl) {
                return this.user.fullSizeAvatarUrl;
            }

            if (!this.user || !this.user.avatarUrl) {
                return this.url;
            }

            return this.user.avatarUrl;
        },
        webpAvatarUrl() {
            if (this.useFullSizeImg && this.user?.fullSizeWebpAvatarUrl) {
                return this.user.fullSizeWebpAvatarUrl;
            }

            if (!this.user || !this.user.webpAvatarUrl) {
                return this.webpUrl;
            }

            return this.user.webpAvatarUrl;
        },
        personName() {
            if (!this.user || !this.user.firstName) {
                return this.name;
            }

            return this.user.firstName;
        },
        modifierClasses() {
            return {
                'avatar--upload': this.size === 'upload',
                'avatar--small': this.size === 'small',
                'avatar--large': this.size === 'large',
                'avatar--extra-small': this.size === 'extra-small',
                'avatar--medium': this.size === 'medium',
                'avatar--selectable': this.selectable,
                'is-selected': this.isSelected,
            };
        },
    },
    methods: {
        handleClick() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.avatar-wrapper {
    position: relative;

    &__badge {
        position: absolute;
    }
}

.avatar {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;

    &--extra-small {
        width: 30px;
        max-width: 30px;
        min-width: 30px;
        height: 30px;
        max-height: 30px;
        min-height: 30px;
    }

    &--small {
        width: 35px;
        max-width: 35px;
        min-width: 35px;
        height: 35px;
        max-height: 35px;
        min-height: 35px;
    }

    &--medium {
        width: 60px;
        max-width: 60px;
        min-width: 60px;
        height: 60px;
        max-height: 60px;
        min-height: 60px;
    }

    &--large {
        width: 80px;
        max-width: 80px;
        min-width: 80px;
        height: 80px;
        max-height: 80px;
        min-height: 80px;
    }

    &--upload {
        width: 180px;
        max-width: 180px;
        min-width: 180px;
        height: 180px;
        max-height: 180px;
        min-height: 180px;
    }

    &--selectable {
        border: 2px solid transparent;

        &.is-selected {
            border-color: $primary;
        }
    }

    &--fallback {
        background: $gray-light;
        text-align: center;
        margin: auto;

        .svg-icon {
            color: $gray;
            width: 90%;
            height: 90%;
        }
    }
}
</style>
