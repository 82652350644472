import Vue from 'vue';
import { getAdditionalFiltersKeys, getMainFilterKeys } from '~/config/filters';
import { getDefaultFilterValue } from '~/helpers/queryParser';
import { RADIUS, SUB_TYPE, LOCATION, PLACE_DESCRIPTION } from '~/config/filterKeys';

export const mutationTypes = {
    SET_STAND_BY_FILTERS: 'FILTERS/SET_STAND_BY_FILTERS',
    CLEAR_STAND_BY_FILTERS: 'FILTERS/CLEAR_STAND_BY_FILTERS',
    CLEAR_ADDITIONAL_STAND_BY_FILTERS: 'FILTERS/CLEAR_ADDITIONAL_STAND_BY_FILTERS',
    CLEAR_MAIN_STAND_BY_FILTERS: 'FILTERS/CLEAR_MAIN_STAND_BY_FILTERS',
    SET_USER_MODIFIED_AUTO_ACTIVATED_FILTERS: 'FILTERS/SET_USER_MODIFIED_AUTO_ACTIVATED_FILTERS',
    CLEAR_USER_MODIFIED_AUTO_ACTIVATED_FILTERS: 'FILTERS/CLEAR_USER_MODIFIED_AUTO_ACTIVATED_FILTERS',
};

export const actionTypes = {
    COLLECT_STAND_BY_FILTERS: 'FILTERS/COLLECT_STAND_BY_FILTERS',
    CLEAR_STAND_BY_FILTERS: 'FILTERS/CLEAR_STAND_BY_FILTERS',
    CLEAR_ADDITIONAL_STAND_BY_FILTERS: 'FILTERS/CLEAR_ADDITIONAL_STAND_BY_FILTERS',
    CLEAR_MAIN_STAND_BY_FILTERS: 'FILTERS/CLEAR_MAIN_STAND_BY_FILTERS',
    SET_USER_MODIFIED_AUTO_ACTIVATED_FILTERS: 'FILTERS/SET_USER_MODIFIED_AUTO_ACTIVATED_FILTERS',
    CLEAR_USER_MODIFIED_AUTO_ACTIVATED_FILTERS: 'FILTERS/CLEAR_USER_MODIFIED_AUTO_ACTIVATED_FILTERS',
};

export const state = () => ({
    standByFilters: {},
    modifiedAutoActivatedFilters: {}, // Filters that were activated by default but were modified by the user
});

export const getters = {
    standByFilters: state => state.standByFilters,
    cmsPageFilters: (state, getters, rootState, rootGetters) => (query, isAllVehiclesRoute) => {
        const cmsPage = rootGetters.cmsPage;
        let cmsPageFilters = {};

        // Use CMS page filters if they are not set in the query and not set in the store
        if (cmsPage?.searchSettings?.location && !query[LOCATION] && state.standByFilters[LOCATION] === undefined) {
            const { location } = cmsPage.searchSettings;
            cmsPageFilters = {
                [LOCATION]: {
                    lat: location.lat,
                    lng: location.lng,
                },
                [PLACE_DESCRIPTION]: location.name,
            };

            if (!query[RADIUS] && state.standByFilters[RADIUS] === undefined && location.radius) {
                cmsPageFilters[RADIUS] = location.radius;
            }
        }

        if (
            cmsPage?.searchSettings?.vehicleTypes?.length > 0 &&
            !query[SUB_TYPE] &&
            state.standByFilters[SUB_TYPE] === undefined &&
            !isAllVehiclesRoute
        ) {
            const { vehicleTypes } = cmsPage.searchSettings;
            cmsPageFilters[SUB_TYPE] = vehicleTypes.join(',');
        }

        return cmsPageFilters;
    },
    isAnyAdditionalStandByFilterActive: state => {
        const additionalFiltersKeys = getAdditionalFiltersKeys();

        return additionalFiltersKeys?.some(
            key => state.standByFilters[key] !== undefined && state.standByFilters[key] !== getDefaultFilterValue(key)
        );
    },
    modifiedAutoActivatedFilters: state => state.modifiedAutoActivatedFilters,
};

export const mutations = {
    [mutationTypes.SET_STAND_BY_FILTERS](state, filters) {
        // For any key that already exists and was sent as an object, we need to merge the new object with the existing one (not replace it)
        Object.keys(filters).forEach(key => {
            if (
                typeof filters[key] === 'object' &&
                !Array.isArray(filters[key]) &&
                filters[key] !== null &&
                state.standByFilters[key]
            ) {
                filters[key] = { ...state.standByFilters[key], ...filters[key] };
            }
        });

        state.standByFilters = { ...state.standByFilters, ...filters };
    },
    [mutationTypes.CLEAR_STAND_BY_FILTERS](state) {
        state.standByFilters = {};
    },
    [mutationTypes.CLEAR_MAIN_STAND_BY_FILTERS](state) {
        const mainFilterKeys = getMainFilterKeys();

        for (const key in state.standByFilters) {
            if (mainFilterKeys.includes(key)) {
                Vue.delete(state.standByFilters, key);
            }
        }
    },
    [mutationTypes.CLEAR_ADDITIONAL_STAND_BY_FILTERS](state) {
        const additionalFiltersKeys = getAdditionalFiltersKeys();

        for (const key in state.standByFilters) {
            if (additionalFiltersKeys.includes(key)) {
                delete Vue.delete(state.standByFilters, key);
            }
        }
    },
    [mutationTypes.SET_USER_MODIFIED_AUTO_ACTIVATED_FILTERS](state, filters) {
        state.modifiedAutoActivatedFilters = { ...state.modifiedAutoActivatedFilters, ...filters };
    },
    [mutationTypes.CLEAR_USER_MODIFIED_AUTO_ACTIVATED_FILTERS](state) {
        state.modifiedAutoActivatedFilters = {};
    },
};

export const actions = {
    [actionTypes.COLLECT_STAND_BY_FILTERS]({ commit }, filters) {
        commit(mutationTypes.SET_STAND_BY_FILTERS, filters);
    },
    [actionTypes.CLEAR_STAND_BY_FILTERS]({ commit }) {
        commit(mutationTypes.CLEAR_STAND_BY_FILTERS);
    },
    [actionTypes.CLEAR_MAIN_STAND_BY_FILTERS]({ commit }) {
        commit(mutationTypes.CLEAR_MAIN_STAND_BY_FILTERS);
    },
    [actionTypes.CLEAR_ADDITIONAL_STAND_BY_FILTERS]({ commit }) {
        commit(mutationTypes.CLEAR_ADDITIONAL_STAND_BY_FILTERS);
    },
    [actionTypes.SET_USER_MODIFIED_AUTO_ACTIVATED_FILTERS]({ commit }, filters) {
        commit(mutationTypes.SET_USER_MODIFIED_AUTO_ACTIVATED_FILTERS, filters);
    },
    [actionTypes.CLEAR_USER_MODIFIED_AUTO_ACTIVATED_FILTERS]({ commit }) {
        commit(mutationTypes.CLEAR_USER_MODIFIED_AUTO_ACTIVATED_FILTERS);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
};
