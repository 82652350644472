/* eslint-disable no-param-reassign, no-shadow */

export const mutationTypes = {
    MODAL_SET: 'MODAL/SET_MODAL',
};

export const actionTypes = {
    MODAL_OPEN: 'MODAL/OPEN',
    MODAL_CLOSE: 'MODAL/CLOSE',
};

export const state = () => ({
    config: null,
});

export const getters = {
    modalConfig: state => state.config,
    modalIsObtrusive: state => state.config && !!state.config.obtrusive,
};

export const mutations = {
    [mutationTypes.MODAL_SET](state, config) {
        state.config = config;
    },
};

export const actions = {
    [actionTypes.MODAL_OPEN]({ commit }, config) {
        return new Promise((resolve, reject) => {
            const extendedConfig = { ...config, resolve, reject };

            commit(mutationTypes.MODAL_SET, extendedConfig);
        });
    },

    [actionTypes.MODAL_CLOSE]({ commit }) {
        commit(mutationTypes.MODAL_SET, null);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
};
