<template>
    <div
        class="badge"
        :class="{
            'badge--empty': count === 0,
            'badge--double-digit': count > 10,
            'badge--warning': type === 'warning',
            'badge--discrete': type === 'discrete',
        }"
    >
        <template v-if="count > 0">{{ count }}</template>
    </div>
</template>

<script>
export default {
    props: {
        count: {
            type: Number,
            default: 0,
        },
        type: {
            type: String,
            default: 'normal', // 'normal', 'discrete' or 'warning'
        },
    },
};
</script>
