import { ADDITIONAL_DRIVER } from '~/config/productTypes';

export default class ProductService {
    constructor({ $axios, $sentry }) {
        this.$axios = $axios;
        this.$sentry = $sentry;
    }

    /**
     * Helper method to get the additional driver product for given currency
     */
    getAdditionalDriver(currency) {
        return this.getProduct({ type: ADDITIONAL_DRIVER, currency });
    }

    /**
     * Get a single global product matching given filters
     * If multiple products are returned by the request, the first is selected
     * (+it's reported to Sentry that we found multiple)
     *
     * @param filters Object with optional keys type, vendor, currency (see backends ProductController for more details)
     * @returns object|null
     */
    getProduct(filters) {
        return this.getProducts(filters).then(products => {
            if (products.length > 1 && this.$sentry) {
                this.$sentry.withScope(scope => {
                    scope.setExtra('products', products);
                    scope.setExtra('filters', filters);
                    this.$sentry.captureMessage(
                        `Received more than one product from backend, returning the first`,
                        'warning'
                    );
                });
            }

            return products[0] || null;
        });
    }

    /**
     * Get the global products matching given filters
     *
     * Reports to Sentry if we find no products
     *
     * @param filters Object with optional keys type, vendor, currency (see backends ProductController for more details)
     * @returns array Empty if no matches are found
     */
    getProducts(filters) {
        const config = {
            method: 'get',
            url: `/products/`,
            params: {
                filter: filters,
            },
        };

        return this.$axios(config).then(response => {
            const products = response.data.data;

            if (products.length === 0 && this.$sentry) {
                this.$sentry.withScope(scope => {
                    scope.setExtra('products', products);
                    scope.setExtra('filters', filters);
                    this.$sentry.captureMessage(`Received no matching products from backend`, 'error');
                });
            }

            return products;
        });
    }
}
