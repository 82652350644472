export default class IdVerificationService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    /**
     * Get ID verification for current user
     */
    getForUser() {
        const config = {
            method: 'get',
            url: `/me/id-verifications`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Creates a new ID verification for current user
     *
     * Note that an image cannot be passed in here, it's pushed to the backend using the
     * ImageUpload component
     */
    create() {
        const config = {
            method: 'post',
            url: `/me/id-verifications`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Update an ID verification
     *
     * Note that an image cannot be passed in here, it's pushed to the backend using the
     * ImageUpload component

     * @param {integer} id ID of the ID verification
     * @param {object} data Data to be updated
     */
    update(id, data) {
        const config = {
            method: 'put',
            url: `/id-verifications/${id}`,
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Deletes an ID verification
     *
     * @param {integer} id ID of the ID verification to remove
     */
    delete(id) {
        const config = {
            method: 'delete',
            url: `/id-verifications/${id}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Rotates an image 90 degrees left (counter-clockwise)
     *
     * @param {integer} id ID of the ID verification
     * @param {string} type Which image to rotate (idImage / selfieImage)
     */
    rotateImageLeft(id, type) {
        const config = {
            method: 'post',
            url: `/id-verifications/${id}/rotate-image-left`,
            data: { type },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Rotates an image 90 degrees right (clockwise)
     *
     * @param {integer} id ID of the ID verification
     * @param {string} type Which image to rotate (idImage / selfieImage)
     */
    rotateImageRight(id, type) {
        const config = {
            method: 'post',
            url: `/id-verifications/${id}/rotate-image-right`,
            data: { type },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Deletes an image from given ID verification
     *
     * @param {integer} id ID of the ID verification
     * @param {string} type Which image to delete (idImage / selfieImage)
     */
    deleteImage(id, type) {
        const config = {
            method: 'post',
            url: `/id-verifications/${id}/delete-image`,
            data: { type },
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
