<template>
    <div v-if="!mobileMenuOpen" class="main-header-banner" :class="{ 'main-header-banner--margin': isImpersonating }">
        <client-only>
            <HeaderBanner v-if="user && isImpersonating" type="error" class="impersonate-header">
                <span>{{ $t('logged_in_as_user', { user: user.email }) }}</span>
                <a href="#" @click="logout"> {{ $t('main_menu.logout') }} </a>
            </HeaderBanner>
        </client-only>
        <HeaderBanner v-if="showOutageBanner" type="warning">
            {{ $t('outage_banner') }}
        </HeaderBanner>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderBanner from '~/components/layout/HeaderBanner.vue';
import { actionTypes as userActions } from '~/store/user';

export default {
    components: { HeaderBanner },
    computed: {
        ...mapGetters(['healthStatus', 'mobileMenuOpen', 'user', 'isImpersonating']),
        showOutageBanner() {
            return this.healthStatus !== 'ok';
        },
    },
    methods: {
        logout() {
            if (this.$route.matched.some(record => record.meta.requiresAuth)) {
                this.$router.push(this.localePath({ path: '/' }));
            }

            this.$store.dispatch(userActions.LOGOUT).catch(err => {
                this.$sentry.captureException(err);
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';
$impersonate-header-height: 80px;

.main-header-banner {
    &--margin {
        margin-bottom: $impersonate-header-height;
    }

    &__text,
    span {
        color: $secondary-text-color;
    }

    .impersonate-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: $impersonate-header-height;
        padding: 0;
        z-index: 100;
        background-color: $error;
        text-align: center;
        color: $white;
        a {
            margin-left: 16px;
            color: $white;
        }
    }
}
</style>
