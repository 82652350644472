import { actionTypes as localizationActions } from '~/store/localization';

export default function (context) {
    /**
     * This is a workaround for the fact that the siteCountryCode and the locale are frequently out of sync during the client init.
     */
    const siteCountryCode = context.store.getters.siteCountryCode;
    const locale = new Intl.Locale(context.i18n.localeProperties.iso).region || null;

    if (siteCountryCode !== locale) {
        context.store.dispatch(localizationActions.SWITCH_LOCALE, context.i18n);
        context.$sentry.withScope(scope => {
            scope.setExtra('siteCountryCode', siteCountryCode);
            scope.setExtra('i18n', context.i18n);

            context.$sentry.captureMessage(
                'siteCountryCode and i18n are out of sync during the client init',
                'warning'
            );
        });
    }
}
