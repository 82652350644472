/**
 * Transfer type names
 *
 * @note Keep this in sync with the type constants in the backend (see Transfer.php)
 */
export const DOWN_PAYMENT = 'down_payment';
export const TOTAL = 'total';
export const INSTALLMENT_PAYMENT = 'installment_payment';
export const EXTRA_CHARGES = 'extra_charges';
export const MANUAL = 'manual';
export const OTHER = 'other';
