export default function ({ $auth, $sentry }) {
    const setSentryUser = () => {
        if ($auth.loggedIn && $auth.user) {
            $sentry.setUser({ id: $auth.user.id });
        } else {
            $sentry.setUser(null);
        }
    };

    // Set user on initial load
    setSentryUser();

    // Watch for auth state changes
    $auth.$storage.watchState('loggedIn', setSentryUser);
}
