<template>
    <div class="maintenance">
        <div class="maintenance__header">
            <div class="maintenance-logo">
                <img class="maintenance-logo__image" src="~/assets/images/mycamper-logo-with-text.svg" alt="MyCamper" />
                <div class="maintenance-logo__text">{{ $t('modals.login.rent_note') }}</div>
            </div>
            <img class="maintenance__illustration-mobile" src="~/assets/images/maintenance-mobile.svg" alt="MyCamper" />
        </div>
        <div class="maintenance__content-wrapper">
            <img
                class="maintenance__illustration-desktop"
                src="~/assets/images/maintenance-desktop.svg"
                alt="MyCamper maintenance"
            />
            <div class="maintenance__content">
                <div class="maintenance__heading">{{ $t('pages.maintenance.heading') }}</div>
                <div class="maintenance__description">{{ $t('pages.maintenance.description') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Maintenance',
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.maintenance-logo {
    &__image {
        width: 141px;
        height: 20px;
        @include lg {
            width: 190px;
            height: 27px;
        }
    }
    &__text {
        @include font-size(16px);
        margin-top: 5px;
    }
}

.maintenance {
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        flex-direction: column;
        background-color: $white;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        padding: 24px 20px;

        @include lg {
            background-color: unset;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__illustration-mobile {
        align-self: center;
        margin-top: 24px;
        max-width: 95%;

        @include lg {
            display: none;
        }
    }
    &__illustration-desktop {
        width: 100%;
        height: 100%;
        display: none;

        @include lg {
            display: block;
        }
    }

    &__content-wrapper {
        width: 100%;
        padding: 30px 20px;
        box-sizing: border-box;
        position: relative;

        @include lg {
            max-width: 1500px;
            padding: 0 20px;
            margin: 0 auto;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;

        @include lg {
            position: absolute;
            top: 25%;
            left: 45px;
        }
    }

    &__heading {
        @extend %heading-1;
        margin-bottom: 24px;

        @include lg {
            margin-bottom: 30px;
        }

        @media (min-width: $bp-lg-min) and (max-width: $bp-xl-min) {
            max-width: 460px;
        }

        @media (min-width: $bp-xl-min) {
            max-width: 650px;
        }
    }

    &__description {
        @include font-size(18px);

        @media (min-width: $bp-lg-min) and (max-width: $bp-xl-min) {
            max-width: 280px;
        }

        @media (min-width: $bp-xl-min) {
            max-width: 600px;
        }
    }
}
</style>
