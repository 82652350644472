/**
 * Mixin for checking feature flags
 */
export default {
    methods: {
        featureEnabled(feature) {
            return this.$config.featureFlags[feature];
        },
    },
};
