export function currencyUnit(value, defaultCurrency, locale) {
    const currency = value || defaultCurrency;

    const formatter = Intl.NumberFormat(Intl.NumberFormat.supportedLocalesOf(locale), {
        style: 'currency',
        currencyDisplay: 'code',
        currency,
    });

    const hasFormatToParts = typeof Intl.NumberFormat.prototype.formatToParts === 'function';

    if (hasFormatToParts) {
        const currencyPart = formatter.formatToParts(currency).find(part => part.type === 'currency');

        return currencyPart.value;
    }

    return value.toUpperCase();
}

export default function currencyUnitFilter({ store, i18n }) {
    return value => currencyUnit(value, store.getters.defaultCurrency, i18n.localeProperties.iso);
}
