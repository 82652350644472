/* eslint-disable no-param-reassign, no-shadow */
export const mutationTypes = {
    SET_EXCHANGE_RATES: 'CONFIG/SET_EXCHANGE_RATES',
    SET_USER_REVIEWS: 'CONFIG/SET_USER_REVIEWS',
    SET_PLATFORM_VEHICLES_COUNT: 'CONFIG/SET_PLATFORM_VEHICLES_COUNT',
    SET_USER_TERMS: 'CONFIG/SET_USER_TERMS',
    SET_HEALTH_STATUS: 'CONFIG/SET_HEALTH_STATUS',
    SET_MOBILE_MENU_OPEN: 'CONFIG/SET_MOBILE_MENU_OPEN',
    SET_IS_LARGE_SCREEN: 'CONFIG/SET_IS_LARGE_SCREEN',
    UPDATE_USER_REVIEWS: 'CONFIG/UPDATE_USER_REVIEWS',
};

export const actionTypes = {
    FETCH_RATES: 'CONFIG/FETCH_RATES',
    FETCH_USER_REVIEWS: 'FETCH_USER_REVIEWS',
    FETCH_PLATFORM_VEHICLES_COUNT: 'FETCH_PLATFORM_VEHICLES_COUNT',
    FETCH_USER_TERMS: 'CONFIG/FETCH_USER_TERMS',
    HEALTH_CHECK: 'CONFIG/HEALTH_CHECK',
    SET_MOBILE_MENU_OPEN: 'CONFIG/SET_MOBILE_MENU_OPEN',
    SET_IS_LARGE_SCREEN: 'CONFIG/SET_IS_LARGE_SCREEN',
};

export const initialState = {
    exchangeRates: null,
    userReviews: {
        averageScore: 0,
        reviewCountRounded: 0,
        displayedReviewCountRounded: '',
    },
    platformVehicles: {
        count: 0,
        roundedCount: '',
    },
    userTerms: null,
    healthStatus: 'ok',
    mobileMenuOpen: false,
    isLargeScreen: false,
};

export const state = () => initialState;

export const getters = {
    exchangeRates: state => state.exchangeRates,
    userReviews: state => state.userReviews,
    platformVehicles: state => state.platformVehicles,
    userTerms: state => state.userTerms,
    healthStatus: state => state.healthStatus,
    isHeaderBannerShown: (state, getters) => {
        return getters.healthStatus !== 'ok';
    },
    mobileMenuOpen: state => state.mobileMenuOpen,
    isLargeScreen: state => state.isLargeScreen,
    vehicleLinkTarget: state => (state.isLargeScreen ? '_blank' : '_self'),
};

export const mutations = {
    [mutationTypes.SET_EXCHANGE_RATES](state, exchangeRates) {
        state.exchangeRates = exchangeRates;
    },
    [mutationTypes.SET_USER_REVIEWS](state, data) {
        const { averageScore, reviewCountRounded } = data.data;
        const isoCodePlaceholder = {
            en: 'en-GB',
            de: 'de-DE',
        };
        const localeIsoCode = isoCodePlaceholder[data.i18n.localeProperties.iso]
            ? isoCodePlaceholder[data.i18n.localeProperties.iso]
            : data.i18n.localeProperties.iso;

        state.userReviews = {
            averageScore,
            reviewCountRounded,
            displayedReviewCountRounded: new Intl.NumberFormat(localeIsoCode).format(reviewCountRounded),
        };
    },
    [mutationTypes.SET_PLATFORM_VEHICLES_COUNT](state, { data, i18n }) {
        const { count, roundedCount } = data;
        const isoCodePlaceholder = {
            en: 'en-GB',
            de: 'de-DE',
        };
        const localeIsoCode = isoCodePlaceholder[i18n.localeProperties.iso]
            ? isoCodePlaceholder[i18n.localeProperties.iso]
            : i18n.localeProperties.iso;

        state.platformVehicles = {
            count,
            roundedCount: new Intl.NumberFormat(localeIsoCode).format(roundedCount),
        };
    },
    [mutationTypes.UPDATE_USER_REVIEWS](state, isoCode) {
        // skip update if data is not retrieved yet
        if (state.userReviews.reviewCountRounded) {
            const { averageScore, reviewCountRounded } = state.userReviews;
            const isoCodePlaceholder = {
                en: 'en-GB',
                de: 'de-DE',
            };
            const localeIsoCode = isoCodePlaceholder[isoCode] ? isoCodePlaceholder[isoCode] : isoCode;

            state.userReviews = {
                averageScore,
                reviewCountRounded,
                displayedReviewCountRounded: new Intl.NumberFormat(localeIsoCode).format(reviewCountRounded),
            };
        }
    },
    [mutationTypes.SET_USER_TERMS](state, userTerms) {
        state.userTerms = userTerms;
    },
    [mutationTypes.SET_HEALTH_STATUS](state, healthStatus) {
        state.healthStatus = healthStatus;
    },
    [mutationTypes.SET_MOBILE_MENU_OPEN](state, mobileMenuOpen) {
        state.mobileMenuOpen = mobileMenuOpen;
    },
    [mutationTypes.SET_IS_LARGE_SCREEN](state, isLargeScreen) {
        state.isLargeScreen = isLargeScreen;
    },
};

export const actions = {
    [actionTypes.FETCH_RATES]({ commit, state }) {
        if (state.exchangeRates) {
            return;
        }

        this.app.$configService.getExchangeRates().then(rates => {
            commit(mutationTypes.SET_EXCHANGE_RATES, rates);
        });
    },
    async [actionTypes.FETCH_USER_REVIEWS]({ commit }, i18n) {
        const data = await this.app.$configService.getUserReviews();

        commit(mutationTypes.SET_USER_REVIEWS, { data, i18n });
    },
    async [actionTypes.FETCH_PLATFORM_VEHICLES_COUNT]({ commit }, i18n) {
        const data = await this.app.$vehicleService.getVehiclesCount({});

        commit(mutationTypes.SET_PLATFORM_VEHICLES_COUNT, { data, i18n });
    },
    async [actionTypes.FETCH_USER_TERMS]({ commit }) {
        const terms = await this.app.$userTermsService.getCurrent();

        commit(mutationTypes.SET_USER_TERMS, terms);

        return terms;
    },
    [actionTypes.HEALTH_CHECK]({ commit }) {
        this.app.$configService
            .getHealthStatus()
            .then(status => {
                commit(mutationTypes.SET_HEALTH_STATUS, status);
            })
            .catch(() => {}); // noop on fail to not show false positive outage banner
    },
    [actionTypes.SET_MOBILE_MENU_OPEN]({ commit }, mobileMenuOpen) {
        commit(mutationTypes.SET_MOBILE_MENU_OPEN, mobileMenuOpen);
    },
    [actionTypes.SET_IS_LARGE_SCREEN]({ commit }, isLargeScreen) {
        commit(mutationTypes.SET_IS_LARGE_SCREEN, isLargeScreen);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
};
