export const CART = 'hc-cart';
export const IS_IMPERSONATING = 'mc-is-impersonating';
export const IMPERSONATE_ADMIN_ID = 'mc-impersonate-admin-id';

export default class LocalStorageService {
    getItem(key, defaultValue = null) {
        /* istanbul ignore next */
        if (process.server || typeof localStorage === 'undefined') {
            // Avoid errors if localStorage is not enabled
            return defaultValue;
        }

        return localStorage.getItem(key) ?? defaultValue;
    }

    setItem(key, value) {
        /* istanbul ignore next */
        if (process.server || typeof localStorage === 'undefined') {
            return;
        }

        localStorage.setItem(key, value);
    }

    removeItem(key) {
        /* istanbul ignore next */
        if (process.server || typeof localStorage === 'undefined') {
            return;
        }

        localStorage.removeItem(key);
    }
}
