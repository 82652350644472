/**
 * Formats the given float as percentage (without the percentage sign)
 * @param {number} value Value to format
 */
export default function percentageFilter(incomingValue, decimals = 0) {
    let value = incomingValue;

    if (!value) {
        value = 0;
    }

    value *= 100;
    value = Math.round(value * 10 ** decimals) / 10 ** decimals;

    return value;
}
