<template>
    <div
        v-if="number"
        class="progress-icon"
        :class="{
            'is-complete': isComplete && !isPending,
            'is-pending': isPending,
        }"
    >
        <template v-if="!isComplete || isPending">{{ number }}</template>
        <svg-icon v-else name="success" />
    </div>
</template>

<script>
export default {
    props: {
        number: {
            type: Number,
            default: null,
        },
        isComplete: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        isPending: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.progress-icon {
    @include font-size(14px);
    font-family: $base-font;
    font-weight: 600;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    border: 3px solid $primary;
    line-height: 22px;

    @include md {
        @include font-size(16px);
        padding-top: 0;
        width: 34px;
        height: 34px;
        line-height: 28px;
    }

    .svg-icon {
        color: $badge-default;
        width: 32px;
        height: 32px;
        margin-top: -2px;
        margin-left: -2px;

        @include md {
            width: 38px;
            height: 38px;
        }
    }

    &.is-pending {
        border-color: $badge-warning;
    }

    &.is-complete {
        border: none;
        padding-top: 0;

        .svg-icon {
            color: $badge-complete;
        }
    }
}
</style>
