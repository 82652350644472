import { mapGetters } from 'vuex';
import { actionTypes as userActions } from '~/store/user';

export default {
    computed: {
        ...mapGetters(['isLoggedIn', 'logoutDueToInvalidJwt']),
    },
    watch: {
        isLoggedIn(newValue) {
            if (!newValue) {
                this.$cookies.set('auth.redirect', undefined);
                const redirectRoute = this.logoutDueToInvalidJwt ? 'login' : 'index';
                setTimeout(() => {
                    const route = { name: redirectRoute };

                    if (this.logoutDueToInvalidJwt) {
                        route.query = { 'auth-status': 'invalid-jwt' };
                        // Reset the flag after use
                        this.$store.dispatch(userActions.SET_LOGOUT_DUE_TO_INVALID_JWT, false);
                    }

                    this.$router.push(this.localePath(route));
                });
            }
        },
    },
};
