import Vue from 'vue';
import GmapVue from 'gmap-vue';

export default function ({ $config, i18n }) {
    Vue.use(GmapVue, {
        load: {
            key: $config.googleMapsApiKey,
            libraries: 'places', // necessary for places input
            region: i18n.localeProperties ? new Intl.Locale(i18n.localeProperties.iso).region : 'SE',
            language: i18n.locale,
        },
        installComponents: true,
    });
}
