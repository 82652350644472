
import merge from '~lodash.mergewith'
import { init, Replay } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F261e4e7d67214907abdb6fe6b05e599e@o244581.ingest.sentry.io\u002F6212596",
    environment:"production",
    release:"15264",
    ignoreErrors:[new RegExp("Network Error", ""),new RegExp("NetworkError", ""),new RegExp("NavigationDuplicated", ""),new RegExp("Navigation cancelled", ""),new RegExp("Cannot set headers after they are sent to the client", ""),new RegExp("ERR_HTTP_HEADERS_SENT", "")],
    beforeSend:function(event, hint) {
        const apiError = hint.originalException;
        // List of API error slugs/messages to avoid sending to Sentry
        const ignoreApiErrors = ['jwt_token_expired', 'auth_refresh_failed', 'Token not provided'];

        if (apiError && apiError.status && apiError.slug && apiError.message) {
          if (ignoreApiErrors.includes(apiError.slug) || ignoreApiErrors.includes(apiError.message)) {
            return null;
          }

          /* eslint-disable no-param-reassign */
          event.message = `API error [${apiError.slug}]: ${apiError.message}`;
          event.extra.apiError = apiError;
          event.fingerprint = [apiError.slug];
          event.tags = event.tags || {};
          event.tags.apiErrorSlug = apiError.slug;
          /* eslint-enable no-param-reassign */
        }

        return event;
      },
    replaysSessionSampleRate:0.1,
    replaysOnErrorSampleRate:1,
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
    new Replay(),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
