<template>
    <div :class="mainClasses">
        <McTag v-if="showTopOwnerBadge" class="vehicle-icon-bar__top-owner-badge">
            {{ $t('vehicle_card.top_owner') }}
        </McTag>
        <div class="vehicle-icon-bar__right">
            <div :class="iconWrapperClasses">
                <svg-icon :name="heartIcon" class="vehicle-icon-bar__heart" @click.prevent="handleHeartClick" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionTypes as userActions } from '~/store/user';
import modalMixin from '~/mixins/modalMixin';

export default {
    name: 'VehicleIconBar',
    components: {
        McTag: () => import('~/components/McTag.vue'),
    },
    mixins: [modalMixin],
    props: {
        vehicle: {
            type: Object,
            required: true,
        },
        emptyHeartIcon: {
            type: String,
            default: 'heart_white_outline',
            validator(value) {
                return ['heart_white_outline', 'heart_black_outline'].includes(value);
            },
        },
        showTopOwnerBadge: {
            type: Boolean,
            default: false,
        },
        circledIcons: {
            type: Boolean,
            default: false,
        },
        indexInList: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            vehicleFavored: this.vehicle.isFavored,
            favoringInProgress: false,
        };
    },
    computed: {
        ...mapGetters(['isLoggedIn']),
        mainClasses() {
            return {
                'vehicle-icon-bar': true,
                'vehicle-icon-bar--full-width': this.showTopOwnerBadge,
            };
        },
        iconWrapperClasses() {
            return {
                'vehicle-icon-bar__icon-wrapper': true,
                'vehicle-icon-bar__icon-wrapper--circled': this.circledIcons,
            };
        },
        heartIcon() {
            if (!this.isLoggedIn) {
                return this.emptyHeartIcon;
            }

            return this.vehicleFavored ? 'heart-full' : this.emptyHeartIcon;
        },
    },
    methods: {
        handleHeartClick(event) {
            event.stopPropagation();

            if (this.favoringInProgress) {
                return;
            }

            const modifiedVehicle = { ...this.vehicle };
            modifiedVehicle.savedPageName = this.$route.name;
            modifiedVehicle.indexInList = this.indexInList;

            if (!this.isLoggedIn) {
                // Save for later
                this.$store.dispatch(userActions.SET_FAVORED_ASSET, modifiedVehicle);
                this.displaySignUpModal();
                this.vehicleFavored = true;

                return;
            }

            this.favoringInProgress = true;

            const onSuccess = favored => {
                this.vehicleFavored = favored;
                this.favoringInProgress = false;
            };

            if (this.vehicleFavored) {
                this.$meService
                    .removeAssetFromFavorites(modifiedVehicle.id)
                    .then(() => {
                        onSuccess(false);
                    })
                    .catch(err => {
                        this.$sentry.captureException(err);
                    });
            } else {
                this.$meService
                    .addAssetToFavorites(modifiedVehicle)
                    .then(() => {
                        onSuccess(true);
                    })
                    .catch(err => {
                        this.$sentry.captureException(err);
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.vehicle-icon-bar {
    $spacing: 10px;

    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    &--full-width {
        width: 100%;
    }

    svg {
        filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.196));
    }

    svg:not(:last-child) {
        margin-right: 0;
    }

    svg:last-child {
        margin-left: 0;
    }

    &__top-owner-badge {
        margin: $spacing;
        line-height: 1.2;
    }

    &__icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: fit-content;
        padding: calc($spacing/2); // easier clicking
        margin: calc($spacing/2);

        &--circled {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.5);
            padding: 0;
            margin: $spacing;

            svg {
                margin-top: 2px; // heart shape does not look centered otherwise
            }
        }
    }

    &__heart {
        width: 1.5rem;
        height: 1.5rem;
    }
}
</style>
