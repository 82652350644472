/**
 * This file contains configuration for vehicle fields
 *
 * The purpose of this config files is to have labels and other settings gathered on one place
 * for all vehicle fields. The fields are printed on a few places throughout the frontend, and
 * it's good to keep the config on one place.
 *
 * There are a few different frontend components that are used to print vehicle fields.
 * At the time of writing these components are:
 * - CheckboxListItem
 * - OverviewListItem
 * - IconListItem
 * - RulesListItem
 *
 * Depending on which of the components you'll use to display the new field you're configuring,
 * they require different properties for the field. All components require an 'output' key,
 * which sets how the value is printed (see below). Some components also require the 'label'
 * key (today, only OverviewListItem requires this).
 *
 * There's also an optional boolean property called 'pricingField'. This is used for fields
 * that are found in the vehicle's pricing object (e.g. vehicle.pricing.myField).
 * See example field: highWeeklyPriceWithoutCleaning
 *
 * How the 'label' and 'output' keys are used can be seen in the VehicleField component.
 *
 * For the 'output' property, there are three different formatting options:
 * - String format:       This is just a string, potentially including "{value}" which will be
 *                        replaced by the vehicle's current field value.
 *                        Example field: sleepsNumber
 * - Multi-choice format: This is is an object with keys for each of the possible values the
 *                        multi-choice field can have.
 *                        Example field: allowedNationalities
 * - Function format:     This is a function that receives the vehicles field value and
 *                        returns a formatted string.
 *                        Example field: pricePerKilometer
 *
 * Lastly, if the field is not supposed to be shown for a certain vehicle type, add it to the
 * corresponding hiddenFieldsByVehicleType array.
 */

import { formatAsPrice, roundedFilter, priceFilter } from '~/filters/pricingFilters';
import {
    ASSET_SUB_TYPE_MOTORHOME,
    ASSET_SUB_TYPE_CAMPERVAN,
    ASSET_SUB_TYPE_CARAVAN,
    ASSET_SUB_TYPE_OTHER_CAMPER,
} from '~/config/assetTypes';
import equipmentFields, { featureFlagEquipmentFields } from '~/config/equipmentFields';

/**
 * Field keys for the fields that should not be displayed for the given vehicle type
 *
 * This both hides the input fields on the EditVehicle* pages and in the output components.
 *
 * @note The 'towbarSocket' field is hidden manually (with isVehicleCaravan) in EditVehicleEquipment
 *       since it's a custom multi-choice field
 */
export const hiddenFieldsByVehicleType = {
    [ASSET_SUB_TYPE_MOTORHOME]: ['mover', 'towingMirrors', 'towbarSocket', 'towbarSocketAdapter'],
    [ASSET_SUB_TYPE_CAMPERVAN]: ['mover', 'towingMirrors', 'towbarSocket', 'towbarSocketAdapter'],
    [ASSET_SUB_TYPE_CARAVAN]: [
        'sitsNumber',
        'towbar',
        'includedDistance',
        'pricePerKilometer',
        'pricePerKilometerIncFee',
        'childSafetySeat',
        'gps',
        'backupCamera',
        'parkingSensors',
        'transmission',
        'allWheelDrive',
        'euro6Compliant',
        'fueltype',
        'rentersCarParking',
    ],
    [ASSET_SUB_TYPE_OTHER_CAMPER]: [],
};

/**
 * Display configuration for all vehicle fields
 *
 * @note See documentation in the top of this file
 */
let vehicleFields = null;

export const setupVehicleFields = ({ i18n, store }) => {
    /**
     * Formats a pricing value by using the price and rounded filters
     * @param {number} value Price value in ören/cents
     */
    const priceFormatter = (value, currency, fractionDigits = 0, shouldConvert = true) => {
        return formatAsPrice(
            priceFilter(value),
            currency,
            fractionDigits,
            shouldConvert,
            i18n.localeProperties.iso,
            store.getters.userCurrency,
            store.getters.exchangeRates
        );
    };

    vehicleFields = {
        airCondition: {
            label: i18n.t('vehicle_fields.equipment.airCondition'),
            output: i18n.t('vehicle_fields.equipment.airCondition'),
        },
        airConditionDriving: {
            label: i18n.t('vehicle_fields.equipment.airConditionDriving'),
            output: i18n.t('vehicle_fields.equipment.airConditionDriving'),
        },
        auxPort: {
            label: i18n.t('vehicle_fields.equipment.auxPort'),
            output: i18n.t('vehicle_fields.equipment.auxPort'),
        },
        awning: {
            label: i18n.t('vehicle_fields.equipment.awning'),
            output: i18n.t('vehicle_fields.equipment.awning'),
        },
        awningCarpet: {
            label: i18n.t('vehicle_fields.equipment.awningCarpet'),
            output: i18n.t('vehicle_fields.equipment.awningCarpet'),
        },
        backupCamera: {
            label: i18n.t('vehicle_fields.equipment.backupCamera'),
            output: i18n.t('vehicle_fields.equipment.backupCamera'),
        },
        barbequeGrill: {
            label: i18n.t('vehicle_fields.equipment.barbequeGrill'),
            output: i18n.t('vehicle_fields.equipment.barbequeGrill'),
        },
        bedLinenIncluded: {
            label: i18n.t('vehicle_fields.equipment.bedLinenIncluded'),
            output: i18n.t('vehicle_fields.equipment.bedLinenIncluded'),
        },
        bicycleCarrier: {
            label: i18n.t('vehicle_fields.equipment.bicycleCarrier'),
            output: i18n.t('vehicle_fields.equipment.bicycleCarrier'),
        },
        blackoutCurtain: {
            label: i18n.t('vehicle_fields.equipment.blackoutCurtain'),
            output: i18n.t('vehicle_fields.equipment.blackoutCurtain'),
        },
        blinds: {
            label: i18n.t('vehicle_fields.equipment.blinds'),
            output: i18n.t('vehicle_fields.equipment.blinds'),
        },
        bluetoothAudio: {
            label: i18n.t('vehicle_fields.equipment.bluetoothAudio'),
            output: i18n.t('vehicle_fields.equipment.bluetoothAudio'),
        },
        brand: { label: i18n.t('vehicle_fields.brand_label'), output: '{value}' },
        cdPlayer: {
            label: i18n.t('vehicle_fields.equipment.cdPlayer'),
            output: i18n.t('vehicle_fields.equipment.cdPlayer'),
        },
        cancellationPolicy: {
            label: i18n.t('vehicle_fields.cancellation_policy_label'),
            output: {
                standard: i18n.t('vehicle_fields.cancellation_policy.standard'),
                flex: i18n.t('vehicle_fields.cancellation_policy.flex'),
                strict: i18n.t('vehicle_fields.cancellation_policy.strict'),
            },
            fallbackValue: i18n.t('vehicle_fields.cancellation_policy.strict'),
        },
        cashDeposit: {
            label: i18n.t('vehicle_fields.deposit_label'),
            output: (value, vehicle) => priceFormatter(value, vehicle.currency),
            pricingField: true,
        },
        city: { output: '{value}' },
        cleaningFee: {
            label: i18n.t('vehicle_fields.cleaning_fee_label'),
            output: (value, vehicle) => priceFormatter(value, vehicle.currency),
            pricingField: true,
        },
        coffeeMachine: {
            label: i18n.t('vehicle_fields.equipment.coffeeMachine'),
            output: i18n.t('vehicle_fields.equipment.coffeeMachine'),
        },
        curbWeight: { output: '{value} kg', label: i18n.t('vehicle_fields.curb_weight_label') },
        currentOdometerValue: { output: '{value} km', label: i18n.t('vehicle_fields.current_odometer_value_label') },
        deductible: {
            label: i18n.t('vehicle_fields.deductible_label'),
            output: (value, vehicle) => priceFormatter(value, vehicle.currency, 0, false),
        },
        deposit: {
            label: i18n.t('vehicle_fields.deposit_label'),
            output: (value, vehicle) => `${priceFormatter(value, vehicle.currency)}`,
            pricingField: true,
        },
        drivingLicenseClass: { label: i18n.t('vehicle_fields.driving_license_class_label'), output: '{value}' },
        floorHeating: {
            label: i18n.t('vehicle_fields.equipment.floorHeating'),
            output: i18n.t('vehicle_fields.equipment.floorHeating'),
        },
        freezer: {
            label: i18n.t('vehicle_fields.equipment.freezer'),
            output: i18n.t('vehicle_fields.equipment.freezer'),
        },
        fullyEquippedKitchen: {
            label: i18n.t('vehicle_fields.equipment.fullyEquippedKitchen'),
            output: i18n.t('vehicle_fields.equipment.fullyEquippedKitchen'),
        },
        gps: {
            label: i18n.t('vehicle_fields.equipment.gps'),
            output: i18n.t('vehicle_fields.equipment.gps'),
        },
        heating: {
            label: i18n.t('vehicle_fields.equipment.heating'),
            output: i18n.t('vehicle_fields.equipment.heating'),
        },
        height: {
            label: i18n.t('vehicle_fields.height_label'),
            output: value => i18n.t('common.length_in_meters', { value: value / 100 }),
        },
        highWeeklyPriceWithoutCleaning: {
            label: i18n.t('vehicle_fields.weekly_price_label'),
            output: (value, vehicle) =>
                i18n.t('pricing.weekly_price_without_cleaning', { price: priceFormatter(value, vehicle.currency) }),
            pricingField: true,
        },
        hotWater: {
            label: i18n.t('vehicle_fields.equipment.hotWater'),
            output: i18n.t('vehicle_fields.equipment.hotWater'),
        },
        includedDistance: {
            label: i18n.t('vehicle_fields.included_distance_label'),
            output: value => {
                return i18n.t('vehicle_fields.included_distance', {
                    includedDistanceMiles: roundedFilter(value / 10),
                    includedDistanceKm: roundedFilter(value),
                });
            },
        },
        length: {
            label: i18n.t('vehicle_fields.length_label'),
            output: value => i18n.t('common.length_in_meters', { value: value / 100 }),
        },
        licensePlate: { label: i18n.t('vehicle_fields.license_plate_label'), output: '{value}' },
        lowWeeklyPriceWithoutCleaning: {
            label: i18n.t('vehicle_fields.weekly_price_label'),
            output: (value, vehicle) =>
                i18n.t('pricing.weekly_price_without_cleaning', { price: priceFormatter(value, vehicle.currency) }),
            pricingField: true,
        },
        marquis: {
            label: i18n.t('vehicle_fields.equipment.marquis'),
            output: i18n.t('vehicle_fields.equipment.marquis'),
        },
        maximumAuthorizedMass: { label: i18n.t('vehicle_fields.max_authorized_mass_label'), output: '{value} kg' },
        microwaveOven: {
            label: i18n.t('vehicle_fields.equipment.microwaveOven'),
            output: i18n.t('vehicle_fields.equipment.microwaveOven'),
        },
        minBookingLengthHigh: {
            label: i18n.t('vehicle_fields.min_booking_length_high_label'),
            output: value => i18n.tc('common.nights_count', value),
        },
        minBookingLengthLow: {
            label: i18n.t('vehicle_fields.min_booking_length_low_label'),
            output: value => i18n.tc('common.nights_count', value),
        },
        minimumAge: {
            label: i18n.t('vehicle_fields.minimum_age_label'),
            output: value => i18n.t('common.age', { value }),
        },
        model: { label: i18n.t('vehicle_fields.model_label'), output: '{value}' },
        modelYear: { label: i18n.t('vehicle_fields.model_year_label'), output: '{value}' },
        mosquitoNet: {
            label: i18n.t('vehicle_fields.equipment.mosquitoNet'),
            output: i18n.t('vehicle_fields.equipment.mosquitoNet'),
        },
        mover: {
            label: i18n.t('vehicle_fields.equipment.mover'),
            output: i18n.t('vehicle_fields.equipment.mover'),
        },
        outdoorFurniture: {
            label: i18n.t('vehicle_fields.equipment.outdoorFurniture'),
            output: i18n.t('vehicle_fields.equipment.outdoorFurniture'),
        },
        oven: {
            label: i18n.t('vehicle_fields.equipment.oven'),
            output: i18n.t('vehicle_fields.equipment.oven'),
        },
        parkingSensors: {
            label: i18n.t('vehicle_fields.equipment.parkingSensors'),
            output: i18n.t('vehicle_fields.equipment.parkingSensors'),
        },
        pillowsAndBlanketsIncluded: {
            label: i18n.t('vehicle_fields.equipment.pillowsAndBlanketsIncluded'),
            output: i18n.t('vehicle_fields.equipment.pillowsAndBlanketsIncluded'),
        },
        priceHighDiscount: {
            label: i18n.t('vehicle_fields.discounted_price_label'),
            output: (value, vehicle) => `${value * 100} %`,
        },
        priceHighIncrease: {
            label: i18n.t('vehicle_fields.price_increase_label'),
            output: value => `${value * 100} %`,
        },
        priceLowDiscount: {
            label: i18n.t('vehicle_fields.discounted_price_label'),
            output: value => `${value * 100} %`,
        },
        priceLowIncrease: { label: i18n.t('vehicle_fields.price_increase_label'), output: value => `${value * 100} %` },
        pricePerKilometer: {
            label: i18n.t('vehicle_fields.price_per_kilometer_label'),
            output: (value, vehicle) =>
                i18n.t('pricing.price_per_distance', {
                    pricePerMile: priceFormatter(value * 10, vehicle.currency, 2),
                    pricePerKilometer: priceFormatter(value, vehicle.currency, 2),
                }),
        },
        pricePerKilometerIncFee: {
            label: i18n.t('vehicle_fields.price_per_kilometer_label'),
            output: (value, vehicle) =>
                i18n.t('pricing.price_per_distance', {
                    pricePerMile: priceFormatter(value * 10, vehicle.currency, 2),
                    pricePerKilometer: priceFormatter(value, vehicle.currency, 2),
                }),
        },
        refrigerator: {
            label: i18n.t('vehicle_fields.equipment.refrigerator'),
            output: i18n.t('vehicle_fields.equipment.refrigerator'),
        },
        shower: {
            label: i18n.t('vehicle_fields.equipment.shower'),
            output: i18n.t('vehicle_fields.equipment.shower'),
        },
        sitsNumber: {
            label: i18n.t('vehicle_fields.sits_number_label'),
            output: value => i18n.tc('vehicle_fields.sits_number_count', value),
        },
        sleepsNumber: { output: value => i18n.tc('vehicle_fields.sleeps_number_count', value) },
        solarPanel: {
            label: i18n.t('vehicle_fields.equipment.solarPanel'),
            output: i18n.t('vehicle_fields.equipment.solarPanel'),
        },
        stove: {
            label: i18n.t('vehicle_fields.equipment.stove'),
            output: i18n.t('vehicle_fields.equipment.stove'),
        },
        timeCheckin: {
            label: i18n.t('vehicle_fields.time_checkin_label'),
            output: value => i18n.t('vehicle_fields.time_value', { time: value }),
        },
        insuranceCompany: {
            label: i18n.t('vehicle_fields.insurance_company_label'),
            fallbackValue: i18n.t('vehicle_fields.no_value_fallback'),
            output: '{value}',
        },
        timeCheckout: {
            label: i18n.t('vehicle_fields.time_checkout_label'),
            output: value => i18n.t('vehicle_fields.time_value', { time: value }),
        },
        towbar: {
            label: i18n.t('vehicle_fields.equipment.towbar'),
            output: i18n.t('vehicle_fields.equipment.towbar'),
        },
        towbarSocketAdapter: {
            label: i18n.t('vehicle_fields.equipment.towbarSocketAdapter'),
            output: i18n.t('vehicle_fields.equipment.towbarSocketAdapter'),
        },
        towingMirrors: {
            label: i18n.t('vehicle_fields.equipment.towingMirrors'),
            output: i18n.t('vehicle_fields.equipment.towingMirrors'),
        },
        tv: {
            label: i18n.t('vehicle_fields.equipment.tv'),
            output: i18n.t('vehicle_fields.equipment.tv'),
        },
        width: {
            label: i18n.t('vehicle_fields.width_label'),
            output: value => i18n.t('common.length_in_meters', { value: value / 100 }),
        },
        toiletType: {
            label: i18n.t('vehicle_fields.toilet_label'),
            output: {
                none: i18n.t('vehicle_fields.toilet_types.none'),
                incinerating: `${i18n.t('vehicle_fields.toilet_label')} - ${i18n.t(
                    'vehicle_fields.toilet_types.incinerating'
                )}`,
                cassette: `${i18n.t('vehicle_fields.toilet_label')} - ${i18n.t(
                    'vehicle_fields.toilet_types.cassette'
                )}`,
            },
        },
        towbarSocket: {
            output: {
                '7pin': i18n.t('vehicle_fields.towbar_socket_output.old'),
                '13pin': i18n.t('vehicle_fields.towbar_socket_output.new'),
            },
        },
        transmission: {
            label: i18n.t('vehicle_fields.transmission_label'),
            output: {
                automatic: i18n.t('vehicle_fields.transmission_types.automatic'),
                manual: i18n.t('vehicle_fields.transmission_types.manual'),
            },
        },
        fueltype: {
            label: i18n.t('vehicle_fields.fueltype_label'),
            output: {
                petrol: i18n.t('vehicle_fields.fueltypes.petrol'),
                diesel: i18n.t('vehicle_fields.fueltypes.diesel'),
                electric: i18n.t('vehicle_fields.fueltypes.electric'),
                naturalGas: i18n.t('vehicle_fields.fueltypes.naturalGas'),
                petroleumGas: i18n.t('vehicle_fields.fueltypes.petroleumGas'),
            },
        },
        winterized: {
            label: i18n.t('vehicle_fields.winterized_label'),
            output: {
                true: i18n.t('common.yes'),
                false: i18n.t('common.no'),
            },
        },
        winterTyreType: {
            label: i18n.t('vehicle_fields.winter_tyres_label'),
            fallbackValue: i18n.t('vehicle_fields.no_value_fallback'),
            output: {
                none: i18n.t('vehicle_fields.winter_tyre_types.none'),
                studded: i18n.t('vehicle_fields.winter_tyre_types.studded'),
                non_studded: i18n.t('vehicle_fields.winter_tyre_types.non_studded'),
            },
        },
        insuranceIncludesRoadsideAssistance: {
            label: i18n.t('vehicle_fields.roadside_assistance_label'),
            output: {
                true: i18n.t('common.yes'),
                false: i18n.t('common.no'),
            },
        },
        euro6Compliant: {
            label: i18n.t('vehicle_fields.euro6_label'),
            output: {
                true: i18n.t('common.yes'),
                false: i18n.t('common.no'),
            },
        },
        allWheelDrive: {
            label: i18n.t('vehicle_fields.all_wheel_drive_label'),
            output: {
                true: i18n.t('common.yes'),
                false: i18n.t('common.no'),
            },
        },
        childSafetySeat: {
            label: i18n.t('vehicle_fields.equipment.childSafetySeat'),
            output: {
                true: i18n.t('common.yes'),
                false: i18n.t('common.no'),
            },
        },
        petsAllowed: {
            output: {
                true: i18n.t('vehicle_fields.pets_allowed_yes'),
                false: i18n.t('vehicle_fields.pets_allowed_no'),
            },
        },
        smokingAllowed: {
            output: {
                true: i18n.t('vehicle_fields.smoking_allowed_yes'),
                false: i18n.t('vehicle_fields.smoking_allowed_no'),
            },
        },
        festivalsAllowed: {
            output: {
                true: i18n.t('vehicle_fields.festivals_allowed_yes'),
                false: i18n.t('vehicle_fields.festivals_allowed_no'),
            },
        },
        childrenAllowed: {
            output: {
                true: i18n.t('vehicle_fields.children_allowed_yes'),
                false: i18n.t('vehicle_fields.children_allowed_no'),
            },
        },
        lpgIncluded: {
            output: {
                true: i18n.t('vehicle_fields.lpg_included_yes'),
                false: i18n.t('vehicle_fields.lpg_included_no'),
            },
        },
        mustEmptyWaterTank: {
            output: {
                true: i18n.t('vehicle_fields.must_empty_water_tank'),
                false: i18n.t('vehicle_fields.owner_empties_water_tank'),
            },
        },
        mustEmptyToiletCassette: {
            output: {
                true: i18n.t('vehicle_fields.must_empty_toilet_cassette'),
                false: i18n.t('vehicle_fields.owner_empties_toilet_cassette'),
            },
        },
        rentersCarParking: {
            label: i18n.t('vehicle_fields.renters_car_parking_label'),
            output: {
                true: i18n.t('common.yes'),
                false: i18n.t('common.no'),
            },
        },
        subType: {
            label: i18n.t('vehicle_fields.sub_type_label'),
            output: (value, vehicle) => {
                switch (value) {
                    case ASSET_SUB_TYPE_MOTORHOME:
                        return i18n.t('vehicle_fields.sub_type.motorhome');
                    case ASSET_SUB_TYPE_CAMPERVAN:
                        return i18n.t('vehicle_fields.sub_type.campervan');
                    case ASSET_SUB_TYPE_CARAVAN:
                        return i18n.t('vehicle_fields.sub_type.caravan');
                    case ASSET_SUB_TYPE_OTHER_CAMPER:
                        return i18n.t('vehicle_fields.sub_type.other_camper');
                    default:
                        return i18n.t('vehicle_fields.sub_type.motorhome');
                }
            },
        },
    };
};

const assertFieldConfigExists = field => {
    if (typeof vehicleFields[field] === 'undefined') {
        throw new TypeError(`Missing vehicle field config for field "${field}".`);
    }
};

const assertFieldConfigHasKey = (field, key) => {
    assertFieldConfigExists(field);

    const config = vehicleFields[field];

    if (typeof config[key] === 'undefined') {
        throw new TypeError(
            `Invalid vehicle field config for field "${field}": Missing key "${key}". ${JSON.stringify(config)}`
        );
    }
};

export function getVehicleFieldLabel(_, field) {
    assertFieldConfigHasKey(field, 'label');

    return vehicleFields[field].label;
}

export function getVehicleFieldFallbackValue(field) {
    const fieldConfig = vehicleFields[field];

    return fieldConfig.fallbackValue;
}

export function getVehicleFieldValue(vehicle, field) {
    const fieldConfig = vehicleFields[field];

    let value = vehicle[field];

    if (fieldConfig.pricingField) {
        value = vehicle.pricing[field];
    }

    return value;
}

export function getVehicleFieldFormattedValue(vehicle, field) {
    assertFieldConfigHasKey(field, 'output');

    const fieldConfig = vehicleFields[field];
    const value = getVehicleFieldValue(vehicle, field);
    const fallbackValue = getVehicleFieldFallbackValue(field);

    if (typeof value === 'undefined' && typeof fallbackValue !== 'undefined') {
        return fallbackValue;
    }

    const isFunctionFormat = typeof fieldConfig.output === 'function';
    const isMultiChoiceFormat = !isFunctionFormat && typeof fieldConfig.output === 'object';

    if (isFunctionFormat) {
        return fieldConfig.output(value, vehicle);
    }

    if (isMultiChoiceFormat) {
        return fieldConfig.output[value];
    }

    // String format
    return fieldConfig.output.replace('{value}', value);
}

/**
 * Generates field objects for specified vehicle equipment sections.
 * @param {string|string[]} subTypes Vehicle sub types
 * @param {string[]} equipment Equipment fields to include
 *
 * @returns {Object[]} Array of field objects
 */
const getEquipmentFields = (subTypes, equipment) => {
    if (!subTypes) {
        subTypes = [];
    }

    if (typeof subTypes === 'string') {
        subTypes = subTypes.split(',');
    }

    // Motorhome|campervan with caravan should not hide each others fields
    const returnAllFields =
        subTypes.includes(ASSET_SUB_TYPE_CARAVAN) &&
        (subTypes.includes(ASSET_SUB_TYPE_MOTORHOME) || subTypes.includes(ASSET_SUB_TYPE_CAMPERVAN));
    const allEquipmentFields = process.env.OWNER_ADDONS_OWNER ? featureFlagEquipmentFields : equipmentFields;

    // If it is a special case then all fields are returned. Otherwise returns only the fields that are not hidden for the given vehicle type
    return equipment
        .filter(field => {
            return (
                allEquipmentFields.includes(field) &&
                (returnAllFields || !subTypes.some(subType => hiddenFieldsByVehicleType[subType]?.includes(field)))
            );
        })
        .map(field => ({ field, label: getVehicleFieldLabel(null, field) }));
};

export const getKitchenEquipmentFields = subTypes =>
    getEquipmentFields(subTypes, [
        'refrigerator',
        'freezer',
        'fullyEquippedKitchen',
        'oven',
        'stove',
        'microwaveOven',
        'coffeeMachine',
    ]);

// Toilet handled separately
export const getBathroomEquipmentFields = subTypes => getEquipmentFields(subTypes, ['hotWater', 'shower']);

export const getDrivingEquipmentFields = subTypes =>
    getEquipmentFields(subTypes, ['gps', 'backupCamera', 'parkingSensors', 'airConditionDriving']);

// TowbarSocket handled separately
export const getOutdoorEquipmentFields = subTypes =>
    getEquipmentFields(subTypes, [
        'towbarSocketAdapter',
        'towingMirrors',
        'bicycleCarrier',
        'towbar',
        'mover',
        'solarPanel',
        'awning',
        'awningCarpet',
        'marquis',
        'outdoorFurniture',
        'barbequeGrill',
    ]);

export const getCommonAreaEquipmentFields = subTypes =>
    getEquipmentFields(subTypes, [
        'airCondition',
        'auxPort',
        'bluetoothAudio',
        'cdPlayer',
        'tv',
        'heating',
        'floorHeating',
        'childSafetySeat',
        'blackoutCurtain',
        'blinds',
        'mosquitoNet',
        'pillowsAndBlanketsIncluded',
        'bedLinenIncluded',
    ]);
