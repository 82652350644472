/**
 * Mixin for helper methods to format values
 *
 * These are particularly useful when values need to be formatted before being passed as values in translations
 */
export default {
    methods: {
        formattedPrice(amount = 0, currency, decimals = 2, shouldConvert = true) {
            const price = this.$root.$options.filters.price(amount);

            return this.$root.$options.filters.formatAsPrice(price, currency, decimals, shouldConvert);
        },
        formattedDistanceMiles(distance) {
            return this.$root.$options.filters.rounded(distance / 10, 1);
        },
        formattedDistanceKm(distance) {
            return this.$root.$options.filters.rounded(distance);
        },
        formattedPercentage(value, decimals = 0) {
            return this.$root.$options.filters.percentage(value, decimals);
        },
    },
};
