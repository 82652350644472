/**
 * Class for ID verifications using Criipto
 */

/**
 * Available ACR values
 * @see https://docs.criipto.com/how-to/acr-values
 */
export const SE_BANKID_SAME_DEVICE = 'urn:grn:authn:se:bankid:same-device';
export const SE_BANKID_ANOTHER_DEVICE_QR = 'urn:grn:authn:se:bankid:another-device:qr';
export const NO_BANKID = 'urn:grn:authn:no:bankid';
export const FI_BANK_AND_MOBILE = 'urn:grn:authn:fi:all';
export const DK_MITID_LOW = 'urn:grn:authn:dk:mitid:low';
export const DK_MITID_SUBSTANTIAL = 'urn:grn:authn:dk:mitid:substantial';

export default class CriiptoService {
    constructor(context) {
        this.$axios = context.$axios;
    }

    verifyUsingOauth(tokenData, redirectUri) {
        const config = {
            method: 'post',
            url: '/verify/eid-oauth',
            data: {
                tokenData,
                redirectUri,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
