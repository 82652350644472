/**
 * Booking change request status names
 *
 * @note Keep this in sync with the status constants in the backend (see BookingChangeRequest.php)
 */
export const REQUESTED = 'requested'; // Default
export const ACCEPTED = 'accepted';
export const DENIED = 'denied';
export const EXPIRED = 'expired';
export const CANCELED = 'canceled';
