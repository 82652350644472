/**
 * Country code lists
 *
 * @note Sync the with the country code arrays in backend's config
 */
export const nordicCountryCodes = ['SE', 'NO', 'FI', 'DK'];
export const swissCountryCodes = [
    'CH',
    'LI',

    // The following are also considered Swiss but the platform, but are not allowed Baloise insurance (handled by vehicle.countryAllowsPlatformInsurance)
    'DE',
    'FR',
    'ES',
    'IE',
    'IT',
    'AT',
    'BE',
];

// Additional countries only for renter
export const additionalRenterCountryCodes = [
    'AU',
    'CA',
    'CZ',
    'GR',
    'IE',
    'BG',
    'HR',
    'CY',
    'EE',
    'HU',
    'LV',
    'LT',
    'NZ',
    'PL',
    'RO',
    'SK',
    'SI',
    'US',
];

// Only these "Swiss" countries are allowed to onboard currently
export const swissAssetCountryCodes = ['CH', 'LI'];

// Countries from which we require an international driving license
// @note Sync with backend's config
export const countriesRequiringIDL = ['AU', 'CA', 'NZ', 'US'];

export const allowedAssetCountryCodes = [...nordicCountryCodes, ...swissAssetCountryCodes];
