<template>
    <div class="footer-menu">
        <div class="footer-menu__wrapper">
            <p class="footer-menu__subtitle">{{ $t('footer_menu.mycamper_subtitle') }}</p>
            <ul>
                <li>
                    <LocalizedLink :to="{ name: 'about' }" class="footer-link">
                        {{ $t('footer_menu.about_mycamper') }}
                    </LocalizedLink>
                </li>
                <li v-if="$i18n.localeProperties.tld === 'ch'">
                    <LocalizedLink :to="{ name: 'our-partners' }" class="footer-link">
                        {{ $t('footer_menu.our_partners') }}
                    </LocalizedLink>
                </li>
                <li>
                    <LocalizedLink :to="{ name: 'gift-cards' }" class="footer-link">
                        {{ $t('footer_menu.gift_cards') }}
                    </LocalizedLink>
                </li>
                <li>
                    <LocalizedLink :to="{ name: 'cookies' }" class="footer-link">
                        {{ $t('footer_menu.cookies') }}
                    </LocalizedLink>
                </li>
            </ul>
        </div>

        <div class="footer-menu__wrapper">
            <p class="footer-menu__subtitle">{{ $t('footer_menu.rent_subtitle') }}</p>
            <ul>
                <li>
                    <LocalizedLink :to="{ name: 'insurance' }" class="footer-link">
                        {{ $t('footer_menu.insurance_info') }}
                    </LocalizedLink>
                </li>
                <li>
                    <LocalizedLink :to="{ name: 'rent-out' }" class="footer-link">
                        {{ $t('footer_menu.rent_out') }}
                    </LocalizedLink>
                </li>
                <li>
                    <LocalizedLink
                        class="footer-link"
                        :to="{ path: `/${$t('common.motorhomes_page_path')}` }"
                        :class="{ 'nuxt-link-active': activeMenuItem === 'motorhomes-page' }"
                    >
                        {{ $t('footer_menu.rent_motorhome') }}
                    </LocalizedLink>
                </li>
                <li>
                    <LocalizedLink
                        class="footer-link"
                        :to="{ path: `/${$t('common.caravans_page_path')}` }"
                        :class="{ 'nuxt-link-active': activeMenuItem === 'caravans-page' }"
                    >
                        {{ $t('footer_menu.rent_caravan') }}
                    </LocalizedLink>
                </li>
            </ul>
        </div>

        <div class="footer-menu__wrapper">
            <p class="footer-menu__subtitle">{{ $t('footer_menu.support_subtitle') }}</p>
            <ul>
                <li>
                    <a :href="'tel:' + supportPhoneConfig.supportPhoneNormalized" class="footer-link">
                        {{ supportPhoneConfig.supportPhone }}
                    </a>
                </li>
                <li>
                    <a :href="'mailto:' + supportEmail" class="footer-link">
                        {{ supportEmail }}
                    </a>
                </li>
                <li>
                    <a :href="$t('links.faq')" target="_blank" class="footer-link">
                        {{ $t('footer_menu.help_center') }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'FooterMenu',
    computed: {
        ...mapGetters(['activeMenuItem', 'supportEmail', 'supportPhoneNormalizedPerLocale', 'supportPhonePerLocale']),
        supportPhoneConfig() {
            return {
                supportPhone: this.supportPhonePerLocale(this.$i18n.locale),
                supportPhoneNormalized: this.supportPhoneNormalizedPerLocale(this.$i18n.locale),
            };
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/sass/_variables.scss';

.footer-menu {
    li:not(:last-child) {
        margin-bottom: 8px;
    }

    &__wrapper:not(:last-child) {
        margin-bottom: 24px;

        @include menu-breakpoint {
            margin-right: 70px;
        }
    }

    &__subtitle {
        font-weight: bold;
        color: $gray-dark;
    }
}
</style>
