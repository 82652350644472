const NearViewportFoldDetection = {
    bind(el, binding) {
        const options = {
            rootMargin: '300px',
            threshold: 0.1,
        };

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    binding.value();
                    observer.unobserve(el);
                }
            });
        }, options);

        observer.observe(el);
    },
};

export default NearViewportFoldDetection;
