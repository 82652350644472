export default class InstallmentService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    get(installmentId) {
        const config = {
            method: 'get',
            url: `/installments/${installmentId}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
