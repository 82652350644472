/**
 * Depost status names
 *
 * @note Keep this in sync with the status constants in the backend (see Deposit.php)
 */
export const PENDING = 'pending';
export const PAYABLE = 'payable';
export const PAID = 'paid';
export const CLOSED = 'closed';
export const EXPIRED = 'expired';
