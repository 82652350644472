<template>
    <ImageCarousel
        :images="imageAttributes"
        :sizes="srcsetSizes"
        :fullscreen="fullscreen"
        :use-keyboard="useKeyboard"
        :index-of-clicked-image="indexOfClickedImage"
        @arrow-click="$emit('arrow-click')"
        @toggle-carousel="$emit('toggle-carousel')"
        @slide-click="i => $emit('slide-click', i)"
    />
</template>

<script>
import ImageCarousel from '~/components/ImageCarousel.vue';

export default {
    components: { ImageCarousel },
    props: {
        images: {
            type: Array,
            required: true,
        },
        // One of the sizes that are available for vehicle images
        imageSize: {
            type: String,
            default: 'full',
        },
        imageCount: {
            type: Number,
            default: -1,
        },
        srcsetSizes: {
            type: String,
            default: null,
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
        useKeyboard: {
            type: Boolean,
            default: false,
        },
        indexOfClickedImage: {
            type: Number,
            default: 0,
        },
        lazyLoadNearViewport: {
            type: Boolean,
            default: false,
        },
        isNearViewportFold: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        imageAttributes() {
            if (this.lazyLoadNearViewport && !this.isNearViewportFold) {
                return [];
            }

            let images = [...this.images];

            if (this.imageCount > -1) {
                images = images.slice(0, this.imageCount);
            }

            return images.map(image => {
                let src = image.sizes[this.imageSize];
                const alt = this.$t('vehicle_images.common_alt');

                if (!src) {
                    // Fallback to full if the given size doesn't exist for this image
                    src = image.sizes.full;
                }

                if (this.imageSize !== 'full') {
                    const formatted = { src, alt };

                    if (image.webpSizes[this.imageSize]) {
                        formatted.webpSrc = image.webpSizes[this.imageSize];
                    }

                    return formatted;
                }

                const srcset = [];
                const webpSrcset = [];

                // Note: If changes are made here, also update the linkPreload imagesrcet attribute in Vehicle.vue
                Object.keys(image.sizes).forEach(key => {
                    switch (key) {
                        case 'card':
                            srcset.push(`${image.sizes[key]} 400w`);

                            if (image.webpSizes[this.imageSize]) {
                                webpSrcset.push(`${image.webpSizes[key]} 400w`);
                            }

                            break;
                        case 'medium':
                            srcset.push(`${image.sizes[key]} 800w`);

                            if (image.webpSizes[this.imageSize]) {
                                webpSrcset.push(`${image.webpSizes[key]} 800w`);
                            }

                            break;
                        case 'large':
                            srcset.push(`${image.sizes[key]} 1100w`);

                            if (image.webpSizes[this.imageSize]) {
                                webpSrcset.push(`${image.webpSizes[key]} 1100w`);
                            }

                            break;
                        case 'xlarge':
                            srcset.push(`${image.sizes[key]} 1280w`);

                            if (image.webpSizes[this.imageSize]) {
                                webpSrcset.push(`${image.webpSizes[key]} 1280w`);
                            }

                            break;
                        case 'full':
                        default:
                            srcset.push(`${image.sizes[key]} 1600w`);

                            if (image.webpSizes[this.imageSize]) {
                                webpSrcset.push(`${image.webpSizes[key]} 1600w`);
                            }

                            break;
                    }
                });

                const formatted = { src: image.sizes.card, alt, srcset: srcset.join(', ') };

                if (webpSrcset.length > 0) {
                    formatted.webpSrcset = webpSrcset.join(', ');
                }

                return formatted;
            });
        },
    },
};
</script>
