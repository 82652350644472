<template>
    <div class="vehicle-rating">
        <svg-icon :name="numOfReviews > 0 ? 'star' : 'star_border'" />
        <span>{{ rating }}</span>
    </div>
</template>

<script>
export default {
    name: 'VehicleRating',
    props: {
        value: {
            type: Number,
            default: 0,
        },
        numOfReviews: {
            type: Number,
            default: null,
        },
    },
    computed: {
        rating() {
            return this.numOfReviews > 0
                ? `${this.value.toFixed(1)} (${this.numOfReviews})`
                : this.$t('vehicle_card.no_reviews');
        },
    },
};
</script>

<style lang="scss">
.vehicle-rating {
    display: flex;
    align-items: center;
    svg {
        margin-right: 4px;
        width: 20px;
        height: 20px;
    }
}
</style>
