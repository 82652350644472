<template>
    <nav class="main-menu">
        <div class="main-menu__items">
            <MainMenuItem
                v-if="gettingStartedCount > 0"
                :to="{ name: 'getting-started' }"
                :badge-count="gettingStartedCount"
                class="main-menu__item"
            >
                {{ $t('main_menu.getting_started') }}
            </MainMenuItem>
            <template v-if="isOwner">
                <MainMenuItem
                    :to="
                        localePath({
                            path: `/${$t('common.motorhomes_page_path')}`,
                            query: { [$t('common.all_vehicles_query')]: 1 },
                        })
                    "
                    class="main-menu__item"
                >
                    {{ $t('main_menu.all_vehicles') }}
                </MainMenuItem>
                <MainMenuItem v-if="myFirstVehicle" :to="{ name: 'owner-dashboard' }" class="main-menu__item">
                    {{ $t('main_menu.dashboard') }}
                </MainMenuItem>
            </template>
            <template v-else>
                <MainMenuItem
                    name="motorhomes-page"
                    :to="{ path: `/${$t('common.motorhomes_page_path')}` }"
                    class="main-menu__item"
                >
                    {{ $t('main_menu.rent_motorhome') }}
                </MainMenuItem>
                <MainMenuItem
                    name="caravans-page"
                    :to="{ path: `/${$t('common.caravans_page_path')}` }"
                    class="main-menu__item"
                >
                    {{ $t('main_menu.rent_caravan') }}
                </MainMenuItem>
            </template>
            <MainMenuItem
                v-if="bookingsItemVisible"
                :to="{ name: 'bookings' }"
                :badge-count="actionableBookings"
                class="main-menu__item"
            >
                {{ $t('main_menu.bookings') }}
            </MainMenuItem>
            <MainMenuItem
                v-if="user.conversationsCount > 0 && featureEnabled('renterAccess')"
                :to="{ name: 'messages' }"
                :badge-count="unreadConversationCount"
                class="main-menu__item"
            >
                {{ $t('main_menu.messages') }}
            </MainMenuItem>
            <MainMenuItem :to="{ name: 'magazine' }" class="main-menu__item">
                {{ $t('main_menu.magazine') }}
            </MainMenuItem>

            <div v-if="$i18n.locales.length > 1" class="main-menu__item">
                <LanguageSwitcher />
            </div>
        </div>

        <Avatar :user="user" class="main-menu__mobile-avatar" />
        <button
            class="button button--primary main-menu__profile-menu-toggle"
            :class="{ 'submenu-open': isSubmenuOpen }"
            @click="isSubmenuOpen = !isSubmenuOpen"
        >
            <svg-icon :name="isSubmenuOpen ? 'close' : 'menu'" />
            <Avatar :user="user" :selectable="true" class="main-menu__profile-menu-avatar" />
            <Badge v-if="profileWarningBadge" type="warning" class="main-menu__profile-menu-toggle-badge" />
        </button>

        <div class="main-menu__profile">
            <div class="main-menu__profile-menu" :class="{ 'submenu-open': isSubmenuOpen }">
                <MainMenuItem
                    :to="{ name: 'profile' }"
                    :warning-badge="user && user.hasCreditReport && user.creditReportResult !== 0"
                    class="main-menu__item profile-menu__link"
                    @click.native="closeSubmenu"
                >
                    {{ $t('main_menu.my_profile') }}
                </MainMenuItem>
                <MainMenuItem
                    v-if="isOwner && myVehicles"
                    :to="{ name: 'edit-vehicles' }"
                    :submenu-items="editVehiclesSubMenuItems"
                    :badge-count="
                        hasActivatedAnyVehicleAtSomePoint && isFullyOnboarded && !hasAnyActiveVehicle ? 1 : null
                    "
                    class="main-menu__item profile-menu__link"
                    @click.native="closeSubmenu"
                >
                    <template v-if="allowMultipleVehicles">
                        {{ $t('main_menu.my_vehicles') }}
                    </template>
                    <template v-else>
                        {{ $t('main_menu.my_vehicle') }}
                    </template>
                </MainMenuItem>
                <MainMenuItem
                    :to="{ name: 'favorites' }"
                    class="main-menu__item profile-menu__link"
                    @click.native="closeSubmenu"
                >
                    {{ $t('main_menu.my_favorites') }}
                </MainMenuItem>
                <MainMenuItem
                    v-if="user.reviewsCount > 0 && featureEnabled('renterAccess')"
                    :to="{ name: 'reviews' }"
                    class="main-menu__item profile-menu__link"
                    @click.native="closeSubmenu"
                >
                    {{ $t('main_menu.my_reviews') }}
                </MainMenuItem>
                <MainMenuItem
                    v-if="isOwner && featureEnabled('renterAccess') && user.hasBankAccount"
                    :to="{ name: 'payouts' }"
                    class="main-menu__item profile-menu__link"
                    :warning-badge="requiresStripeAccountVerification"
                    :submenu-items="economySubMenuItems"
                    @click.native="closeSubmenu"
                >
                    {{ $t('main_menu.my_economy') }}
                </MainMenuItem>
                <MainMenuItem
                    :to="{ name: 'account' }"
                    class="main-menu__item profile-menu__link"
                    @click.native="closeSubmenu"
                >
                    {{ $t('main_menu.manage_account') }}
                </MainMenuItem>
                <div class="profile-menu__divider" />
                <div class="main-menu__item main-menu-item profile-menu__link" @click="closeSubmenu">
                    <a class="main-menu-item__text" :href="$t('links.faq')" target="_blank">
                        {{ $t('main_menu.faq') }}
                    </a>
                </div>
                <div class="main-menu__item main-menu-item profile-menu__link">
                    <a href="#" class="main-menu-item__text" @click="logout">
                        <strong>{{ $t('main_menu.logout') }}</strong>
                    </a>
                </div>
            </div>
            <div v-if="isSubmenuOpen" class="main-menu__overlay" @click="isSubmenuOpen = false" />
        </div>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionTypes as userActions } from '~/store/user';

import Avatar from '~/components/Avatar.vue';
import MainMenuItem from '~/components/navigation/MainMenuItem.vue';
import LanguageSwitcher from '~/components/navigation/LanguageSwitcher/LanguageSwitcher.vue';
import Badge from '~/components/Badge.vue';

export default {
    components: { Avatar, MainMenuItem, LanguageSwitcher, Badge },
    data() {
        return {
            isSubmenuOpen: false,
        };
    },
    computed: {
        ...mapGetters([
            'isOwner',
            'isRenter',
            'isFullyOnboarded',
            'allowMultipleVehicles',
            'user',
            'actionableBookings',
            'unreadConversationCount',
            'myFirstVehicle',
            'myVehicles',
            'hasActivatedAnyVehicleAtSomePoint',
            'currentVehicle',
            'firstVehicleIncompleteSubviews',
            'hasPendingBookingRequest',
            'requiresStripeAccountVerification',
            'hasActivatedAnyVehicleAtSomePoint',
            'hasAnyActiveVehicle',
            'isLargeScreen',
        ]),
        bookingsItemVisible() {
            return ((this.isRenter && this.isFullyOnboarded) || this.isOwner) && this.featureEnabled('renterAccess');
        },
        gettingStartedCount() {
            if (this.isOwner) {
                let count = 0;

                if (this.myVehicles?.length < 2 && !this.hasActivatedAnyVehicleAtSomePoint) {
                    count += 1;
                }

                return count;
            }

            return 0;
        },
        profileWarningBadge() {
            return this.isOwner && this.requiresStripeAccountVerification;
        },
        editVehiclesSubMenuItems() {
            if (!this.vehicleIdForSubMenu) {
                return null;
            }

            return [
                {
                    to: { name: 'edit-vehicle-basic', params: { id: this.vehicleIdForSubMenu } },
                    label: this.$t('pages.edit_vehicle.basic'),
                },
                {
                    to: { name: 'edit-vehicle-insurance', params: { id: this.vehicleIdForSubMenu } },
                    label: this.$t('pages.edit_vehicle.insurance'),
                },
                {
                    to: { name: 'edit-vehicle-location', params: { id: this.vehicleIdForSubMenu } },
                    label: this.$t('pages.edit_vehicle.location'),
                },
                {
                    to: { name: 'edit-vehicle-equipment', params: { id: this.vehicleIdForSubMenu } },
                    label: this.$t('pages.edit_vehicle.equipment'),
                },
                {
                    to: { name: 'edit-vehicle-checkin-checkout', params: { id: this.vehicleIdForSubMenu } },
                    label: this.$t('pages.edit_vehicle.checkin_checkout'),
                },
                {
                    to: { name: 'edit-vehicle-rules', params: { id: this.vehicleIdForSubMenu } },
                    label: this.$t('pages.edit_vehicle.rules'),
                },
                {
                    to: { name: 'edit-vehicle-cancellation', params: { id: this.vehicleIdForSubMenu } },
                    label: this.$t('pages.edit_vehicle.cancellation'),
                },
                {
                    to: { name: 'edit-vehicle-pricing', params: { id: this.vehicleIdForSubMenu } },
                    label: this.$t('pages.edit_vehicle.pricing'),
                },
                {
                    to: { name: 'edit-vehicle-availability', params: { id: this.vehicleIdForSubMenu } },
                    label: this.$t('pages.edit_vehicle.availability'),
                },
                {
                    to: { name: 'edit-vehicle-images', params: { id: this.vehicleIdForSubMenu } },
                    label: this.$t('pages.edit_vehicle.images'),
                },
                {
                    to: { name: 'edit-vehicle-description', params: { id: this.vehicleIdForSubMenu } },
                    label: this.$t('pages.edit_vehicle.description'),
                },
                {
                    to: { name: 'edit-vehicle-activation', params: { id: this.vehicleIdForSubMenu } },
                    label: this.$t('pages.edit_vehicle.activation'),
                },
            ];
        },
        economySubMenuItems() {
            return [
                { to: { name: 'payouts', query: this.$route.query }, label: this.$t('pages.payouts.heading') },
                {
                    to: { name: 'payout-reports', query: this.$route.query },
                    label: this.$t('pages.payout_reports.heading'),
                },
                {
                    to: { name: 'economy-settings' },
                    label: this.$t('pages.economy_settings.heading'),
                    badgeCount: this.requiresStripeAccountVerification ? 1 : null,
                },
            ];
        },
        vehicleIdForSubMenu() {
            if (this.allowMultipleVehicles) {
                // The menu item says "Mina fordon" for these users so the submenu would be a bit awkward
                return null;
            }

            const vehicle = this.currentVehicle || this.myFirstVehicle || null;

            if (!vehicle || !vehicle.country) {
                // Submenu should not be displayed when vehicle's country has not yet been set (see EditVehicle.vue)
                return null;
            }

            return vehicle.id;
        },
    },
    methods: {
        logout() {
            this.$store.dispatch(userActions.LOGOUT).catch(err => {
                this.$sentry.captureException(err);
            });
        },
        closeSubmenu() {
            setTimeout(() => {
                this.isSubmenuOpen = false;
            }, 100);
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.main-menu {
    overflow: scroll;

    .main-menu &__mobile-avatar.avatar-wrapper {
        position: absolute;
        top: 10px;
        left: 10px;
    }
    &__profile-menu-toggle {
        display: none;
    }

    &__profile-menu {
        &:before {
            content: '';
            display: block;
            width: 150px;
            border-top: 2px solid $cream-white-dark;
            height: 0px;
            position: relative;
            top: -15px;
            left: 80px;
        }

        box-shadow: none;
        background: transparent;
        padding: 20px 0;

        .profile-menu__link {
            text-align: left;
            width: fit-content;
            a {
                font-weight: normal;
                text-shadow: none;
            }
        }
    }

    .avatar-wrapper {
        &__badge {
            display: none;
        }
    }

    // This is used to close the profile menu on click outside it
    &__overlay {
        z-index: 1;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    @include menu-breakpoint {
        overflow: visible;

        &__item--password,
        &__item--reviews {
            display: none;
        }

        &__profile-menu-toggle-badge {
            position: absolute;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
        }

        &__profile-menu-toggle {
            position: relative;
            background-color: $white;
            color: $primary;
            border: 1px solid $primary;
            display: flex;
            align-items: center;
            padding: 3px 2px 0 2px;
            margin-left: 20px;

            .main-header--floating & {
                background-color: transparent;

                .avatar {
                    svg {
                        color: $primary;
                    }
                }

                &:hover,
                &.submenu-open {
                    box-shadow: 0 0 5px 3px rgba(255, 255, 255, 0.4);
                    background-color: transparent;
                }
            }

            &:hover,
            &:focus,
            &:active {
                border-color: $black;
                background-color: transparent;
                color: $primary;
            }

            &.submenu-open {
                background: $gray-light;
            }

            .svg-icon {
                width: 24px;
                height: 24px;
            }

            .avatar {
                width: 35px;
                height: 35px;
                border-radius: $default-border-radius;
                object-fit: cover;
                object-position: center;

                &--fallback {
                    background-color: transparent;
                }
            }
        }

        .main-menu &__mobile-avatar {
            display: none;
        }

        &__profile-menu {
            &:before {
                display: none;
            }

            min-width: 150px;
            box-shadow: $default-shadow;
            background: $white;
            border-radius: $default-border-radius;
            position: absolute;
            padding: 24px;
            right: 0;
            top: 104%;
            margin-left: 0;
            display: none;
            z-index: 2; // To make sure it's on top of the overlay

            &.submenu-open {
                display: block;
            }

            .profile-menu__divider {
                width: 100%;
                margin-top: 8px;
                border-top: 1px solid $border-color;
            }

            .main-menu-item.profile-menu__link {
                margin-top: 10px;
                margin-left: 0;

                &:first-child {
                    margin-top: 0;
                }

                a {
                    color: $primary-text-color;
                    border-bottom: none;

                    &:hover,
                    &.nuxt-link-active {
                        border-bottom: 1px solid $primary;
                    }
                }
            }
        }

        .avatar-wrapper {
            &__badge {
                display: block;
            }
        }
    }
}
</style>
