/**
 * Order status names
 *
 * @note Keep this in sync with the status constants in the backend (see Order.php)
 */
export const DRAFT = 'draft';
export const PENDING_OWNER_CONFIRMATION = 'pending_owner_confirmation';
export const OPEN = 'open';
export const COMPLETED = 'completed';
export const CANCELED = 'canceled';
