<template>
    <ul class="footer-copyright">
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <li>&copy; {{ $t('footer_menu.copyright') }}</li>
        <li>
            <LocalizedLink :to="{ name: 'user-terms' }" class="footer-link">
                {{ $t('footer_menu.user_terms') }}
            </LocalizedLink>
        </li>
        <li>
            <LocalizedLink :to="{ name: 'privacy-policy' }" class="footer-link">
                {{ $t('footer_menu.privacy_policy') }}
            </LocalizedLink>
        </li>
        <li>
            <LocalizedLink :to="{ name: 'company-details' }" class="footer-link">
                {{ $t('footer_menu.imprint') }}
            </LocalizedLink>
        </li>
        <li>
            <LocalizedLink :to="{ name: 'sitemap' }" class="footer-link">
                {{ $t('footer_menu.sitemap') }}
            </LocalizedLink>
        </li>
        <li>
            <a href="javascript: Cookiebot.renew()" class="footer-link">
                {{ $t('footer_menu.change_cookie_consent') }}
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'FooterCopyright',
};
</script>

<style scoped lang="scss">
@import '@/sass/_variables.scss';

.footer-copyright {
    @include font-size(14px);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    li {
        line-height: 16px;
        margin-bottom: 8px;
    }

    li:not(:last-child) {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid $primary;
    }
}
</style>
