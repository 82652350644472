import { mutationTypes as cartMutations } from '~/store/cart';
import { mutationTypes as impersonateMutations } from '~/store/impersonate';
import LocalStorageService, { CART, IS_IMPERSONATING, IMPERSONATE_ADMIN_ID } from '~/services/LocalStorageService';

/**
 * Persists some selected mutations in localStorage
 */
export default function localStorageSync(store) {
    const localStorage = new LocalStorageService();

    const setOrRemove = (key, payload) => {
        if (payload === null) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, payload);
        }
    };

    const setOrRemoveObject = (key, payload) => {
        const stringifiedPayload = JSON.stringify(payload);

        if (stringifiedPayload === '{}' || payload === null) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, stringifiedPayload);
        }
    };

    store.subscribe((mutation, state) => {
        switch (mutation.type) {
            case cartMutations.UPDATE_CART_TIMESTAMP:
            case cartMutations.SET_BOOKING_REQUEST:
            case cartMutations.SET_MESSAGE:
                setOrRemoveObject(CART, state.cart);
                break;
            case impersonateMutations.IS_IMPERSONATING_SET:
                setOrRemove(IS_IMPERSONATING, state.impersonate.isImpersonating);
                break;
            case impersonateMutations.ADMIN_ID_SET:
                setOrRemove(IMPERSONATE_ADMIN_ID, state.impersonate.adminId);
                break;
            case cartMutations.CART_RESET:
                localStorage.removeItem(CART);
                break;
            default: // Do nothing for other mutations
        }
    });
}
