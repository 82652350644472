<template>
    <div class="main-menu">
        <div class="main-menu__items">
            <MainMenuItem class="main-menu__item" :to="{ name: 'rent-out' }">
                {{ $t('main_menu.rent_out') }}
            </MainMenuItem>

            <MainMenuItem
                class="main-menu__item"
                :to="{ path: `/${$t('common.motorhomes_page_path')}` }"
                :class="{ 'nuxt-link-active': activeMenuItem === 'motorhomes-page' }"
            >
                {{ $t('main_menu.rent_motorhome') }}
            </MainMenuItem>
            <MainMenuItem
                class="main-menu__item"
                :to="{ path: `/${$t('common.caravans_page_path')}` }"
                :class="{ 'nuxt-link-active': activeMenuItem === 'caravans-page' }"
            >
                {{ $t('main_menu.rent_caravan') }}
            </MainMenuItem>

            <div class="main-menu__item main-menu-item">
                <a class="main-menu-item__text" :href="$t('links.faq')" target="_blank">
                    {{ $t('main_menu.faq') }}
                </a>
            </div>

            <MainMenuItem class="main-menu__item" :to="{ name: 'magazine' }">
                {{ $t('main_menu.magazine') }}
            </MainMenuItem>

            <template v-if="isAdmin || isEditor">
                <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
                <div class="main-menu__item main-menu-item">
                    <a :href="adminUrl" class="main-menu-item__text" target="_blank">
                        [{{ $t('main_menu.admin.to_admin') }}]
                    </a>
                </div>
                <div class="main-menu__item main-menu-item">
                    <a href="#" class="profile-menu__link main-menu-item__text" @click="logout">
                        [{{ $t('main_menu.logout') }}]
                    </a>
                </div>
                <MainMenuItem v-if="isAdmin" class="main-menu__item" :to="{ name: 'login-as-user' }">
                    {{ $t('main_menu.login_as_user') }}
                </MainMenuItem>
                <!-- eslint-enable @intlify/vue-i18n/no-raw-text -->
            </template>
            <template v-else>
                <div class="main-menu__item main-menu-item">
                    <ModalLink to="sign-up" class="main-menu-item__text">
                        {{ $t('main_menu.signup') }}
                    </ModalLink>
                </div>

                <div class="main-menu__item main-menu-item">
                    <ModalLink to="login" class="main-menu-item__text">
                        {{ $t('main_menu.login') }}
                    </ModalLink>
                </div>
            </template>

            <div v-if="$i18n.locales.length > 1" class="main-menu__item">
                <LanguageSwitcher />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionTypes as userActions } from '~/store/user';
import ModalLink from '~/components/modals/ModalLink.vue';
import LanguageSwitcher from '~/components/navigation/LanguageSwitcher/LanguageSwitcher.vue';
import MainMenuItem from '~/components/navigation/MainMenuItem.vue';

export default {
    components: { ModalLink, LanguageSwitcher, MainMenuItem },
    computed: {
        ...mapGetters(['activeMenuItem', 'isAdmin', 'isEditor', 'cmsPage', 'isLargeScreen']),
        adminUrl() {
            const baseUrl = `${this.$config.mcBackendUrl}/admin`;
            const id = this.$route.params ? this.$route.params.id || null : null;

            switch (this.getRouteBaseName()) {
                case 'booking':
                    return `${baseUrl}/resources/bookings/${id}`;
                case 'vehicle':
                    return `${baseUrl}/resources/assets/${id}`;

                case 'all':
                    if (this.cmsPage) {
                        return `${baseUrl}/resources/pages/${this.cmsPage.id}`;
                    } else {
                        return `${baseUrl}/resources/pages/`;
                    }

                default:
                    return `${baseUrl}/login`;
            }
        },
    },
    methods: {
        logout() {
            if (this.$route.matched.some(record => record.meta.requiresAuth)) {
                this.$router.push(this.localePath({ path: '/' }));
            }

            this.$store.dispatch(userActions.LOGOUT).catch(err => {
                this.$sentry.captureException(err);
            });
        },
    },
};
</script>
