<template>
    <div class="language-switcher">
        <!-- Toggler -->
        <div class="language-switcher__toggler" @click="toggleContentVisibility">
            <svg-icon :name="iconName" class="language-switcher__icon" />
            <div>{{ $i18n.localeProperties.shortName.toUpperCase() }}</div>
            <svg-icon
                :name="mobileSubmenuOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                class="language-switcher__arrow"
            />
        </div>

        <!-- Desktop popup -->
        <div
            v-if="popupVisible"
            v-closable="{
                handler: 'closePopup',
                exclude: ['.language-switcher__toggler'],
            }"
            class="language-switcher__popup"
        >
            <h3 class="language-switcher__popup-title">{{ $t('language_switcher.popup_title') }}</h3>
            <LanguageSwitcherMainContent @optionSelected="closePopup" />
        </div>

        <!-- Mobile submenu -->
        <LanguageSwitcherMainContent class="language-switcher__mobile" :class="{ 'is-open': mobileSubmenuOpen }" />
    </div>
</template>

<script>
import LanguageSwitcherMainContent from '~/components/navigation/LanguageSwitcher/LanguageSwitcherMainContent.vue';

export default {
    components: {
        LanguageSwitcherMainContent,
    },
    data() {
        return {
            popupVisible: false,
            mobileSubmenuOpen: false,
        };
    },
    computed: {
        iconName() {
            const commonIso = ['en', 'de'];
            const { iso } = this.$i18n.localeProperties;
            const regionCode = new Intl.Locale(iso).region;

            if (commonIso.includes(iso)) {
                return 'globe';
            }

            return `${regionCode}_round_flag`;
        },
    },
    methods: {
        toggleContentVisibility() {
            const hamburgerMenuBreakpoint = 1150;

            if (window?.innerWidth > hamburgerMenuBreakpoint) {
                this.popupVisible = !this.popupVisible;
            } else {
                this.mobileSubmenuOpen = !this.mobileSubmenuOpen;
            }
        },
        closePopup() {
            this.popupVisible = false;
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

$mobile-menu-spacing: 78px;

.language-switcher {
    position: initial;
    margin-left: $mobile-menu-spacing; // Align with mobile menu items

    @include menu-breakpoint {
        margin-left: 0;
    }

    @include md {
        position: relative;
    }

    &__toggler {
        display: flex;
        align-items: center;
        @include font-size(16px);
        font-weight: 600;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        div {
            margin-left: 5px;
        }

        .svg-icon {
            margin-right: 0;
        }

        @include menu-breakpoint {
            color: $primary;
        }
    }

    &__arrow {
        color: $primary;
        width: 24px;
        height: 24px;

        @include menu-breakpoint {
            display: none;
        }
    }

    &__popup {
        $padding: 24px;

        display: none;
        position: absolute;
        top: calc(100% + 20px);
        right: 0;
        width: 320px - ($padding * 2);
        background: $white;
        padding: $padding;
        border-radius: $default-border-radius;
        box-shadow: $default-shadow;

        @include menu-breakpoint {
            display: block;
        }
    }

    &__popup-title {
        display: none;
        @extend %heading-3;
        margin-bottom: 32px;
        @include menu-breakpoint {
            display: block;
        }
    }

    &__mobile {
        display: block;
        background: $cream-white-dark;
        margin-top: 15px;
        margin-left: -$mobile-menu-spacing;
        padding-left: $mobile-menu-spacing + 20px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-in-out;
        @include menu-breakpoint {
            display: none;
        }

        &.is-open {
            max-height: 370px;
        }
    }

    &__icon {
        width: 24px;
        height: 24px;
        color: $primary;
        flex-shrink: 0;
        margin-right: 22px;

        @include menu-breakpoint {
            color: $primary;
            margin-right: 32px;
        }
    }
}
</style>
