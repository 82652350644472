/**
 * Password minimum length
 * This is what the backend enforces
 * @var integer
 */
export const PASSWORD_MIN_LENGTH = 8;
export const ORGANISATION_NUMBER_MIN_LENGTH = 8;

const DEFAULT_STRATEGY = 'laravelJWT';

export default class AuthService {
    constructor({ $axios, $cookies, $auth }) {
        this.$axios = $axios;
        this.$cookies = $cookies;
        this.$auth = $auth;
    }

    login(credentials) {
        return this.$auth.loginWith(DEFAULT_STRATEGY, {
            data: credentials,
        });
    }

    logout() {
        return this.$auth.logout();
    }

    signUp(credentials) {
        const config = {
            method: 'post',
            url: '/auth/register',
            data: credentials,
        };

        return this.$axios(config).then(response => {
            const { data } = response.data;

            if (typeof data === 'object' && data.token) {
                return this.handleAuthResponse(data);
            }

            return data;
        });
    }

    forgotPassword(email) {
        const config = {
            method: 'post',
            url: '/auth/create-password-reset',
            data: {
                email,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Resets the user's password using reset code
     * @param {string} code
     * @param {string} password
     */
    resetPassword(code, password) {
        const config = {
            method: 'post',
            url: '/auth/reset-password',
            data: {
                code,
                password,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    getOAuthRedirectUrl(provider, redirectUrl) {
        const config = {
            method: 'get',
            url: `/auth/${provider}/redirect`,
            params: {
                redirectUrl,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    checkEmail(data) {
        const config = {
            method: 'post',
            url: '/auth/check-email',
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Verifies user login and performs actions depending on the status of the fb account:
     *
     * 1) If registering, an UserOnboarding object is returned
     * 2) If logging in, the auth token is stored (see this.handleAuthResponse) and then the user is returned
     * 3) If verifying with Facebook, the whole response data object is returned (containing keys 'status' and 'user')
     *
     * @param {string} code Code returned from the Facebook redirect
     * @param {string} redirectUrl Redirect URL used to initiate Facebook login
     * @returns See above
     */
    confirmOAuthLogin(provider, code, redirectUrl) {
        const config = {
            method: 'post',
            url: `/auth/${provider}/confirm-login`,
            data: {
                code,
                redirectUrl,
            },
        };

        return this.$axios(config).then(response => {
            const { data } = response.data;

            if (data.onboardingId) {
                return data;
            }

            if (data.token) {
                return this.handleAuthResponse(data);
            }

            return data;
        });
    }

    handleAuthResponse(data) {
        const { user, token } = data;

        this.$auth.setUserToken(token, token);
        this.$auth.setUser(user);

        return user;
    }
}
