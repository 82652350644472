<template>
    <div class="magazine-navigation" :class="mainModifierClasses">
        <div ref="innerContainer" class="magazine-navigation__inner-container">
            <div
                v-for="(item, index) in itemsCopy"
                :id="`magazineNavigationItem${index}`"
                :key="`${item.title}-${index}`"
                class="magazine-navigation__item"
                @mouseenter="isLargeScreen ? triggerSubmenu(index) : null"
                @mouseleave="isLargeScreen ? closePreviousSubmenu() : null"
            >
                <!-- Item -->
                <div class="magazine-navigation__title" :class="{ 'active-item': isActiveRoute(item) }">
                    <a v-if="item.linkType === 'custom' || !item.slug" :href="item.link">{{ item.title }}</a>
                    <LocalizedLink
                        v-else
                        :to="{ name: `magazine-${item.linkType}-slug`, params: { slug: item.slug } }"
                        >{{ item.title }}</LocalizedLink
                    >

                    <svg-icon
                        v-if="item.subItems && item.subItems.length > 0"
                        :name="item.subMenuOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                        @click.stop="isLargeScreen ? null : triggerSubmenu(index)"
                    />
                </div>

                <!-- Submenu -->
                <div
                    v-if="item.subMenuOpen && item.subItems && item.subItems.length > 0"
                    class="magazine-navigation__submenu"
                >
                    <div class="magazine-navigation__submenu__mobile-header">
                        <svg-icon name="close" @click="closePreviousSubmenu" />
                        <h3>{{ item.title }}</h3>
                    </div>
                    <template v-for="(subItem, subIndex) in item.subItems">
                        <a
                            v-if="subItem.linkType === 'custom' || !subItem.slug"
                            :key="`${subItem.title}-${subIndex}`"
                            class="magazine-navigation__submenu__item"
                            :class="{ 'active-item': isActiveRoute(subItem) }"
                            :href="subItem.link"
                        >
                            <div>{{ subItem.title }}</div>
                        </a>
                        <LocalizedLink
                            v-else
                            :key="`${subItem.title}-${subIndex}`"
                            class="magazine-navigation__submenu__item"
                            :class="{ 'active-item': isActiveRoute(subItem) }"
                            :to="{ name: `magazine-${subItem.linkType}-slug`, params: { slug: subItem.slug } }"
                        >
                            <div>{{ subItem.title }}</div>
                        </LocalizedLink>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'MagazineNavigation',
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            itemsCopy: [],
            currentSubmenuIndex: null,
            scrollable: false,
            scrolledToEnd: false,
        };
    },
    computed: {
        ...mapGetters(['isLargeScreen']),
        mainModifierClasses() {
            return {
                'magazine-navigation--fade':
                    this.scrollable && !this.scrolledToEnd && this.currentSubmenuIndex === null,
            };
        },
    },
    watch: {
        // Watches for a langauge switch & checks the scroll state (since it doesn't work in 'mounted')
        items: {
            handler(newItems) {
                this.itemsCopy = newItems ? JSON.parse(JSON.stringify(newItems)) : [];
                this.$nextTick(() => {
                    const innerContainer = this.$refs.innerContainer;

                    if (!innerContainer) {
                        return;
                    }

                    this.checkScrollPosition();
                    this.scrollable = innerContainer.scrollWidth > innerContainer.clientWidth;
                });
            },
            immediate: true,
        },
        $route() {
            this.closePreviousSubmenu();
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.innerContainer.addEventListener('scroll', this.checkScrollPosition);
        });
    },
    beforeDestroy() {
        this.$refs.innerContainer.removeEventListener('scroll', this.checkScrollPosition);
    },
    methods: {
        isActiveRoute(item) {
            return this.$route.path.includes(item.linkType) && this.$route.path.includes(item.slug);
        },
        triggerSubmenu(index) {
            const lastSubmenuIndex = this.currentSubmenuIndex;
            this.closePreviousSubmenu();

            // Open submenu if not already open
            if (index !== lastSubmenuIndex) {
                this.$set(this.itemsCopy[index], 'subMenuOpen', true);
                this.currentSubmenuIndex = index;
            }
        },
        closePreviousSubmenu() {
            if (this.currentSubmenuIndex !== null) {
                this.$set(this.itemsCopy[this.currentSubmenuIndex], 'subMenuOpen', false);
                this.currentSubmenuIndex = null;
            }
        },
        checkScrollPosition() {
            if (this.isLargeScreen) {
                return;
            }

            const element = this.$refs.innerContainer;
            const scrollWidth = element.scrollWidth;
            const clientWidth = element.clientWidth;
            const scrollLeft = element.scrollLeft;

            // Not scrollable so we keep scrolledToEnd active
            if (scrollWidth <= clientWidth) {
                this.scrolledToEnd = true;

                return;
            }

            const tolerance = 5; // Allow for a 5-pixel discrepancy

            if (scrollWidth - clientWidth - scrollLeft <= tolerance) {
                this.scrolledToEnd = true;
            } else {
                this.scrolledToEnd = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.magazine-navigation {
    position: relative;

    &--fade {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: -1px; // Fixes a 1px gap on the right side
            width: 100px;
            height: 100%;
            background: linear-gradient(270deg, $gray-light 25%, transparent);
            pointer-events: none; /* Prevent the pseudo-element from blocking clicks */
            z-index: 2;
        }
    }

    &__inner-container {
        display: flex;
        justify-content: flex-start;
        gap: 48px;
        user-select: none;
        white-space: nowrap;
        overflow-x: scroll;
        -webkit-tap-highlight-color: transparent;
        padding: 24px 0;
        @include font-size(14px);

        &::-webkit-scrollbar {
            display: none;
        }

        a {
            display: block;
            color: $primary-text-color;
            text-decoration: none;
        }

        @include lg {
            justify-content: flex-start;
            overflow-x: unset;
            padding: 30px 0 24px;
            @include font-size(16px);

            &--fade {
                mask-image: none;
                -webkit-mask-image: none;
            }
        }

        .active-item {
            cursor: default;
            text-decoration: underline;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        span {
            height: fit-content;
        }

        .svg-icon {
            width: 24px;
            height: 24px;
        }

        &:hover span {
            text-decoration: underline;
        }
    }

    &__title {
        display: flex;
        align-items: center;
    }

    &__submenu {
        // Fullscreen overlay
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: $gray-light;
        z-index: $z-modal;
        padding-top: 75px;
        padding-left: 80px;
        cursor: default;

        h3 {
            @extend %heading-3;
            margin-bottom: $md-magazine-spacing;
        }

        &__mobile-header {
            @include lg {
                display: none;
            }
        }

        &__item {
            margin-bottom: $sm-magazine-spacing;
            width: fit-content;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .svg-icon {
            position: absolute;
            top: $md-magazine-spacing;
            right: $md-magazine-spacing;
            width: 32px;
            height: 32px;
            cursor: pointer;
        }

        @include lg {
            // Popup
            position: absolute;
            right: unset;
            bottom: unset;
            top: 100%;
            padding: $sm-magazine-spacing;
            min-width: 140px;
            border-radius: $default-border-radius;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        }
    }
}
</style>
