<template>
    <div class="magazine">
        <MainHeaderBanner />

        <div class="magazine__content-wrapper">
            <MainHeader class="magazine__header" />
            <div class="magazine__content">
                <section class="mag-section magazine__navigation-section">
                    <div class="mag-wrap mag-wrap--narrow">
                        <MagazineNavigation :items="navigationItems" />
                    </div>
                </section>
                <Nuxt />
                <section class="mag-section mag-alt-bg magazine__popular-campers-section">
                    <div class="mag-wrap mag-wrap--narrow">
                        <PopularCampers />
                    </div>
                </section>
            </div>
            <MainFooter class="magazine__footer" />
            <Modal v-if="modalConfig !== null" :config="modalConfig" />
            <ScrollToTop :show="showScrollToTop" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CmsService from '~/services/CmsService';
import MainHeaderBanner from '~/components/layout/MainHeaderBanner.vue';
import MainHeader from '~/components/layout/MainHeader.vue';
import MainFooter from '~/components/layout/MainFooter.vue';
import MagazineNavigation from '~/components/magazine/MagazineNavigation.vue';
import PopularCampers from '~/components/PopularCampers.vue';
import Modal from '~/components/modals/Modal.vue';
import ScrollToTop from '~/components/ScrollToTop.vue';
import { actionTypes as configActions } from '~/store/config';
import { actionTypes as navigationActions } from '~/store/navigation';
import userModalsHandlerMixin from '~/mixins/userModalsHandlerMixin';

export default {
    name: 'MagazineLayout',
    components: {
        MainHeaderBanner,
        MainHeader,
        MainFooter,
        MagazineNavigation,
        PopularCampers,
        Modal,
        ScrollToTop,
    },
    mixins: [userModalsHandlerMixin],
    middleware: ['isMobileOrLarge'],
    data() {
        return {
            showScrollToTop: false,
            navigationItems: [],
        };
    },
    async fetch() {
        const cmsService = new CmsService(this.$nuxt.context);

        try {
            this.navigationItems = await cmsService.getNavigationMenuItems();
        } catch (error) {
            this.$sentry.captureException(error);
        }
    },
    computed: {
        ...mapGetters(['modalConfig', 'isLoggedIn']),
    },
    watch: {
        isLoggedIn(newValue) {
            if (!newValue) {
                this.$cookies.set('auth.redirect', undefined);
                setTimeout(() => this.$router.push(this.localePath({ name: 'index' })));
            }
        },
        $route: {
            handler() {
                this.$store.dispatch(navigationActions.CLOSE_SUB_MENU);
            },
            immediate: true,
        },
        '$i18n.locale': {
            async handler(newLocale, oldLocale) {
                if (newLocale !== oldLocale) {
                    await this.$fetch();
                }
            },
        },
    },
    mounted() {
        this.$store.dispatch(configActions.HEALTH_CHECK);
        this.checkScreenSize();
        this.handleScrollToTopVisibility();

        window.addEventListener('resize', this.checkScreenSize);
        window.addEventListener('scroll', this.handleScrollToTopVisibility);
    },
    destroyed() {
        window.removeEventListener('resize', this.checkScreenSize);
        window.removeEventListener('scroll', this.handleScrollToTopVisibility);
    },
    methods: {
        checkScreenSize() {
            const isLargeScreen = window.innerWidth > 991;
            this.$store.dispatch(configActions.SET_IS_LARGE_SCREEN, isLargeScreen);
        },
        handleScrollToTopVisibility() {
            this.showScrollToTop = window.scrollY > 300;
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';
@import '@/sass/_magazine.scss';

.magazine {
    min-height: 100vh;
    background: $white;

    &__content-wrapper {
        display: flex;
        flex-direction: column;
    }

    &__content {
        flex-grow: 1;
        position: relative;
    }

    &__navigation-section {
        background-color: $cream-white;
    }

    &__breadcrumbs {
        padding-top: $sm-magazine-spacing;
    }

    &__popular-campers-section {
        margin-top: 0; // an exception to the rule of sections having top margin
        padding: $md-magazine-spacing 0;

        @include lg {
            padding: $lg-magazine-spacing 0;
        }
    }
}
</style>
