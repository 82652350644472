export default class UserTermsService {
    constructor({ $axios, $sentry }) {
        this.$axios = $axios;
        this.$sentry = $sentry;
    }

    getCurrent() {
        const config = {
            method: 'get',
            url: '/user-terms',
        };

        return this.$axios(config)
            .then(response => response.data.data[0])
            .catch(error => {
                this.$sentry.captureMessage(`Could not fetch current user terms: ${error.message}`, 'warning');
            });
    }
}
