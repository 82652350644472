/* eslint-disable no-param-reassign, no-shadow */
import { mutationTypes as configMutationTypes } from './config';
export const mutationTypes = {
    SET_COUNTRY: 'CONFIG/SET_COUNTRY',
};

export const actionTypes = {
    SWITCH_LOCALE: 'CONFIG/SWITCH_LOCALE',
};

export const INITIAL_STATE = {
    siteCountryCode: null,
};

export const state = () => INITIAL_STATE;

export const CONFIG_BY_COUNTRY = {
    SE: {
        currency: 'sek',
        stripeAccount: 'stripe_se',
        supportPhone: '018-7512180',
        supportEmail: 'support@mycamper.com',
        supportPhoneNormalized: '+46187512180',
    },
    NO: {
        currency: 'nok',
        stripeAccount: 'stripe_no',
        supportEmail: 'support@mycamper.com',
        supportPhone: '23 65 33 70',
        supportPhoneNormalized: '+4723653370',
    },
    FI: {
        currency: 'eur',
        stripeAccount: 'stripe_fi',
        supportEmail: 'support@mycamper.com',
        supportPhone: '09 315 45688',
        supportPhoneNormalized: '+358931545688',
    },
    DK: {
        currency: 'dkk',
        stripeAccount: 'stripe_dk',
        supportEmail: 'support@mycamper.com',
        supportPhone: '+4532242343',
        supportPhoneNormalized: '+4532242343',
    },
    CH: {
        currency: 'chf',
        stripeAccount: 'stripe_ch',
        supportEmail: 'support@mycamper.com',
        supportPhone: '+41 61 551 00 20',
        supportPhoneNormalized: '+41615510020',
    },

    // Fallback config for "global" locales, i.e. "en" & "de"
    global: {
        currency: 'eur',
        stripeAccount: 'stripe_se',
        supportEmail: 'support@mycamper.com',
        supportPhone: '+46 18 7512180',
        supportPhoneNormalized: '+46187512180',
    },
};

export const getters = {
    siteCountryCode: state => state.siteCountryCode,
    siteCountryCodeWithFallback: state => state.siteCountryCode || 'SE',
    siteCountryConfig: state => CONFIG_BY_COUNTRY[state.siteCountryCode || 'global'],

    isCountrySweden: state => state.siteCountryCode === 'SE',
    isCountryNorway: state => state.siteCountryCode === 'NO',
    isCountryFinland: state => state.siteCountryCode === 'FI',
    isCountryDenmark: state => state.siteCountryCode === 'DK',
    isCountrySwitzerland: state => state.siteCountryCode === 'CH',

    defaultCurrency: state => CONFIG_BY_COUNTRY[state.siteCountryCode || 'global'].currency,
    defaultStripeAccount: state => CONFIG_BY_COUNTRY[state.siteCountryCode || 'global'].stripeAccount,
    supportEmail: state => CONFIG_BY_COUNTRY[state.siteCountryCode || 'global'].supportEmail,
    supportPhonePerLocale: state => locale => {
        return locale === 'de' ? '+41 61 551 00 20' : CONFIG_BY_COUNTRY[state.siteCountryCode || 'global'].supportPhone;
    },
    supportPhoneNormalizedPerLocale: state => locale => {
        return locale === 'de'
            ? '+41615510020'
            : CONFIG_BY_COUNTRY[state.siteCountryCode || 'global'].supportPhoneNormalized;
    },
};

export const mutations = {
    [mutationTypes.SET_COUNTRY](state, countryCode) {
        state.siteCountryCode = countryCode;
    },
};

export const actions = {
    [actionTypes.SWITCH_LOCALE]({ commit }, i18n) {
        const locale = new Intl.Locale(i18n.localeProperties.iso).region;
        const countryCode = locale || null;

        commit(mutationTypes.SET_COUNTRY, countryCode);
        commit(configMutationTypes.UPDATE_USER_REVIEWS, i18n.localeProperties.iso);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
};
