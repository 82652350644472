/* eslint-disable no-param-reassign, no-shadow */

import LeadService from '~/services/LeadService';

export const mutationTypes = {
    SET_LEAD_DATA: 'LEAD/SET_LEAD_DATA',
};

export const actionTypes = {
    STORE_LEAD: 'LEAD/STORE_LEAD',
    CLEAR_LEAD: 'LEAD/CLEAR_LEAD',
};

export const state = () => ({
    lead: null,
});

export const getters = {
    lead: state => state.lead,
};

export const mutations = {
    [mutationTypes.SET_LEAD_DATA](state, lead) {
        state.lead = lead;
    },
};

export const actions = {
    [actionTypes.STORE_LEAD]({ commit }, lead) {
        const service = new LeadService(this);

        return service.createLead(lead).then(() => {
            commit(mutationTypes.SET_LEAD_DATA, lead);
        });
    },

    [actionTypes.CLEAR_LEAD]({ commit }) {
        commit(mutationTypes.SET_LEAD_DATA, null);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
};
