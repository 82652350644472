export default class BookingChangeRequestService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    /**
     * Create change request for given booking
     * @param {integer} bookingId
     */
    createForBooking(bookingId, newFromDate, newToDate, message) {
        const config = {
            method: 'post',
            url: `/bookings/${bookingId}/change-requests`,
            data: { newFromDate, newToDate },
        };

        if (message && message.length > 0) {
            config.data.message = message;
        }

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get costs of a change request with given dates for the booking
     *
     * @param {integer} bookingId
     * @param {string} newFromDate
     * @param {string} newToDate
     */
    getChangeRequestCosts(bookingId, newFromDate, newToDate) {
        const config = {
            method: 'get',
            url: `/bookings/${bookingId}/change-request-costs`,
            params: { newFromDate, newToDate },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Accept the given change request (only allowed by owners & admins)
     */
    accept(changeRequestId) {
        const config = {
            method: 'post',
            url: `/booking-change-requests/${changeRequestId}/accept`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Deny the given change request (only allowed by owners & admins)
     */
    deny(changeRequestId) {
        const config = {
            method: 'post',
            url: `/booking-change-requests/${changeRequestId}/deny`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Cancel the given change request (only allowed by renters & admins)
     */
    cancel(changeRequestId) {
        const config = {
            method: 'post',
            url: `/booking-change-requests/${changeRequestId}/cancel`,
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
