const middleware = {}

middleware['authRedirection'] = require('../middleware/authRedirection.js')
middleware['authRedirection'] = middleware['authRedirection'].default || middleware['authRedirection']

middleware['isAdmin'] = require('../middleware/isAdmin.js')
middleware['isAdmin'] = middleware['isAdmin'].default || middleware['isAdmin']

middleware['isMobileOrLarge'] = require('../middleware/isMobileOrLarge.js')
middleware['isMobileOrLarge'] = middleware['isMobileOrLarge'].default || middleware['isMobileOrLarge']

middleware['isOwnerOrAdmin'] = require('../middleware/isOwnerOrAdmin.js')
middleware['isOwnerOrAdmin'] = middleware['isOwnerOrAdmin'].default || middleware['isOwnerOrAdmin']

middleware['isRenterOrAdmin'] = require('../middleware/isRenterOrAdmin.js')
middleware['isRenterOrAdmin'] = middleware['isRenterOrAdmin'].default || middleware['isRenterOrAdmin']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

export default middleware
