<template>
    <a :href="hrefValue" :class="{ 'nuxt-link-active': getRouteBaseName() === to }" @click.prevent="displayModal()">
        <slot />
    </a>
</template>

<script>
import modalMixin from '~/mixins/modalMixin';
import { actionTypes as modalActions } from '~/store/modal';

export default {
    mixins: [modalMixin],
    props: {
        to: {
            type: String,
            default: null,
        },
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        hrefValue() {
            if (this.to === 'login' || this.to === 'sign-up') {
                const path = this.localePath({ name: this.to }, this.$i18n.locale);

                return `${this.$config.siteUrl}${path}`;
            }

            return `?popup=${this.to}`;
        },
    },
    methods: {
        displayModal() {
            if (!this.to) {
                return;
            }

            // disable modal opening if user is on login or signup page
            if (/(login|sign-up)/.test(this.$route.name)) {
                if (this.$route.name.includes(this.to)) {
                    return this.$store.dispatch(modalActions.MODAL_CLOSE);
                }

                if (this.to === 'login' || this.to === 'sign-up') {
                    return this.$router.push(this.localePath({ name: this.to }));
                }
            }

            this.$store.dispatch(modalActions.MODAL_CLOSE).then(() => {
                switch (this.to) {
                    case 'login':
                        this.displayLoginModal();
                        break;
                    case 'sign-up':
                        this.displaySignUpModal(this.data);
                        break;
                    case 'support-request':
                        this.displaySupportRequestModal();
                        break;
                    case 'forgot-password':
                        this.displayForgotPasswordModal();
                        break;
                    case 'stripe-account-verification':
                        this.displayStripeAccountVerificationModal();
                        break;
                    default:
                        throw new Error(`Invalid modal name ("to"-prop) passed to ModalLink: ${this.to}`);
                }
            });
        },
    },
};
</script>
