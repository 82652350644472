export default class AdditionalDriverService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    /**
     * Get additional drivers for given booking
     * @param {integer} bookingId
     */
    getForBooking(bookingId) {
        const config = {
            method: 'get',
            url: `/bookings/${bookingId}/additional-drivers`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Creates a new driver for given booking
     *
     * Note that an image cannot be passed in here, it's pushed to the backend using the
     * ImageUpload component
     *
     * @param {integer} bookingId
     * @param {string} name
     */
    createDriver(bookingId, name) {
        const config = {
            method: 'post',
            url: `/bookings/${bookingId}/additional-drivers`,
            data: {
                name,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Update a driver
     *
     * Note that an image cannot be passed in here, it's pushed to the backend using the
     * ImageUpload component

     * @param {integer} id ID of the driver
     * @param {object} data Data to be updated
     */
    updateDriver(id, data) {
        const config = {
            method: 'put',
            url: `/additional-drivers/${id}`,
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Deletes a driver
     *
     * @param {integer} id ID of the driver to remove
     */
    deleteDriver(id) {
        const config = {
            method: 'delete',
            url: `/additional-drivers/${id}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Rotates an image 90 degrees left (counter-clockwise)
     *
     * @param {integer} driverId ID of the driver
     * @param {string} type Which image to rotate (imageFront / imageBack)
     */
    rotateImageLeft(driverId, type) {
        const config = {
            method: 'post',
            url: `/additional-drivers/${driverId}/rotate-image-left`,
            data: { type },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Rotates an image 90 degrees right (clockwise)
     *
     * @param {integer} driverId ID of the driver
     * @param {string} type Which image to rotate (imageFront / imageBack)
     */
    rotateImageRight(driverId, type) {
        const config = {
            method: 'post',
            url: `/additional-drivers/${driverId}/rotate-image-right`,
            data: { type },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Deletes an image from given driver
     *
     * @param {integer} driverId ID of the driver
     * @param {string} type Which image to delete (imageFront / imageBack)
     */
    deleteImage(driverId, type) {
        const config = {
            method: 'post',
            url: `/additional-drivers/${driverId}/delete-image`,
            data: { type },
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
