export default class PayoutService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    /**
     * Get payouts for given or current user
     * @param {int} userId
     * @returns
     */
    getPayoutsForUser(userId) {
        const config = {
            method: 'get',
            url: '/payouts',
            params: {
                userId,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get single payout
     * @param {int} id
     * @param {int} userId
     * @returns
     */
    getPayout(id, userId) {
        const config = {
            method: 'get',
            url: `/payouts/${id}`,
            params: {
                userId,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get payout reports for user
     */
    getPayoutReportsForUser(userId) {
        const config = {
            method: 'get',
            url: '/payout-reports',
            params: {
                userId,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
