/**
 * Installment status names
 *
 * @note Keep this in sync with the status constants in the backend (see Installment.php)
 */
export const PENDING = 'pending';
export const PAYABLE = 'payable';
export const PAID = 'paid';
export const CANCELED = 'canceled';
export const VOIDED = 'voided';
export const EXPIRED = 'expired';
