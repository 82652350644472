const LazyLoadImage = {
    inserted(el, binding) {
        const options = {
            rootMargin: '300px',
            threshold: 0.1,
        };

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (el.tagName === 'IMG') {
                        el.src = binding.value;
                    } else {
                        el.style.backgroundImage = `url(${binding.value})`;
                    }

                    el.classList.add('loaded');
                    observer.unobserve(el);
                }
            });
        }, options);

        observer.observe(el);
    },
};

export default LazyLoadImage;
