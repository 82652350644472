/**
 * Booking status names
 *
 * @note Keep this in sync with the status constants in the backend (see Booking.php)
 */
export const DRAFT = 'draft';
export const PENDING_CONFIRMATION = 'pending_confirmation';
export const CONFIRMED = 'confirmed';
export const ACTIVE = 'active';
export const ENDED = 'ended';
export const COMPLETED = 'completed';
export const CANCELED_BY_OWNER = 'canceled_by_owner';
export const CANCELED_BY_RENTER = 'canceled_by_renter';
export const CANCELED_BY_SYSTEM = 'canceled_by_system';
