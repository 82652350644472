/**
 * CmsService uses our WP installation's REST API
 */

/**
 * Util class to reformat returned data from WP API into formats we want
 */
class WordpressFormatter {
    constructor({ $sentry }) {
        this.$sentry = $sentry;
    }

    /**
     * Format for a post
     * @param {object} wpPost
     * @returns object
     */
    formatPost(wpPost) {
        const customFields = wpPost.acf || {};

        return {
            id: wpPost.id,
            slug: wpPost.slug,
            title: wpPost.title ? wpPost.title.rendered : null,
            content: wpPost.content ? wpPost.content.rendered : null,
            image: wpPost.card_data.image_url_full,
            author: {
                name: wpPost.card_data.author,
                image: wpPost.card_data.author_image,
            },
            date: wpPost.card_data.date,
            category: {
                slug: wpPost.card_data.category_slug,
                title: wpPost.card_data.category_title,
                link: wpPost.card_data.category_link,
            },
            tags: wpPost.card_data.tags,
            tableOfContents: wpPost.toc_data,
            readingTimeSec: wpPost.reading_time_sec,
            wordCount: wpPost.word_count,
            template: customFields.post_template || wpPost.template,
            translations: wpPost.translations,
            seoMeta: wpPost.yoast_head_json,
        };
    }

    /**
     * Format for a post card
     * @param {object} wpPostCard
     * @returns object
     */
    formatPostCard(wpPostCard) {
        const categoryTitle =
            wpPostCard.card_data.category_title === 'Uncategorized' ? null : wpPostCard.card_data.category_title;

        return {
            id: wpPostCard.id,
            link: wpPostCard.card_data.link,
            title: wpPostCard.card_data.title,
            imageUrl: wpPostCard.card_data.image_url,
            imageAltText: wpPostCard.card_data.image_alt_text,
            excerpt: wpPostCard.card_data.excerpt,
            author: wpPostCard.card_data.author,
            date: wpPostCard.card_data.date,
            categoryTitle,
            categoryLink: wpPostCard.card_data.category_link,
            categorySlug: wpPostCard.card_data.category_slug,
            slug: wpPostCard.card_data.slug,
            tags: wpPostCard.card_data.tags,
        };
    }

    /**
     * Format for a page
     * @param {object} wpPage
     * @returns object
     */
    formatPage(wpPage) {
        return {
            id: wpPage.id,
            slug: wpPage.slug,
            title: wpPage.title.rendered,
            content: wpPage.content.rendered,
            excerpt: wpPage.excerpt.rendered,
            template: wpPage.template,
            translations: wpPage.translations,
            seoMeta: wpPage.yoast_head_json,
            tagOrder: wpPage.tag_order,
            navigationData: wpPage.navigation_data,
        };
    }

    /**
     * Format for a taxonomy page
     * @param {object} taxonomyPage
     * @returns object
     */
    formatTaxonomyPage(taxonomyPage) {
        return {
            id: taxonomyPage.id,
            slug: taxonomyPage.slug,
            name: taxonomyPage.name,
            description: taxonomyPage.description,
            content: taxonomyPage.taxonomy_content,
            parent: taxonomyPage.parent,
            count: taxonomyPage.count,
            translations: taxonomyPage.translations,
            navigationData: taxonomyPage.navigation_data,
            subNavigationData: taxonomyPage.sub_navigation_data,
            seoMeta: taxonomyPage.yoast_head_json,
            taxonomySettings: taxonomyPage.taxonomy_settings,
            excludePostIds: taxonomyPage.exclude_post_ids,
        };
    }
}

export default class CmsService {
    constructor(context) {
        this.$axios = context.$axios;
        this.$i18n = context.i18n;
        this.$sentry = context.$sentry;
        this.baseURL = context.$config.cmsApiUrl;
        this.redirect = context.redirect;
        this.app = context.app;
        this.formatter = new WordpressFormatter(context);
    }

    getLang(i18nLocale) {
        return new Intl.Locale(i18nLocale.iso).language;
    }

    getFrontpage() {
        const config = {
            method: 'get',
            baseURL: this.baseURL,
            url: '/frontpage',
            params: {
                _embed: 'wp:term',
                lang: this.getLang(this.$i18n.localeProperties),
            },
        };

        return this.$axios(config)
            .then(response => this.formatter.formatPage(response.data))
            .catch(error => {
                this.$sentry.captureMessage(`Could not fetch CMS frontpage: ${error.message}`, 'warning');

                throw error;
            });
    }

    /**
     * Get magazine page tree
     * @returns object
     */
    getMagazinePageTree() {
        const config = {
            method: 'get',
            baseURL: this.baseURL,
            url: '/sitemap',
            params: {
                lang: this.getLang(this.$i18n.localeProperties),
            },
        };

        return this.$axios(config).then(response => response.data);
    }

    /**
     * Get all posts
     * @returns array
     */
    getAllPosts(includeTermsAndMedia = false) {
        const config = {
            method: 'get',
            baseURL: this.baseURL,
            url: '/posts',
            params: {
                lang: this.getLang(this.$i18n.localeProperties),
                orderby: 'title',
                order: 'asc',
                per_page: 100,
            },
        };

        if (includeTermsAndMedia) {
            config.params._embed = 'wp:term,wp:featuredmedia';
        }

        return this.$axios(config).then(response => response.data.map(p => this.formatter.formatPost(p)));
    }

    /**
     * Get posts associated with taxonomy page type based on given ID
     * @param {string} taxonomyTerm The taxonomy term (e.g., 'categories' or 'tags')
     * @param {string} ids One or more taxonomy ID:s, separated by comma
     * @param {string} page Page number
     * @param {string} exclude One or more post ID:s, separated by comma
     * @returns array
     */
    getPostsForTaxonomy(taxonomyTerm, ids, page = 1, exclude = '') {
        const config = {
            method: 'get',
            baseURL: this.baseURL,
            url: '/posts',
            params: {
                page,
                exclude,
                per_page: 9,
                _embed: 'wp:term,wp:featuredmedia',
                _fields: 'card_data',
                lang: this.getLang(this.$i18n.localeProperties),
            },
        };

        config.params[taxonomyTerm] = ids;

        return this.$axios(config).then(response => {
            const wpTotal = response.headers['x-wp-total'];
            const wpTotalPages = response.headers['x-wp-totalpages'];

            const posts = response.data.map(p => this.formatter.formatPostCard(p));

            return {
                meta: {
                    total: wpTotal,
                    totalPages: wpTotalPages,
                },
                posts,
            };
        });
    }

    /**
     * Get recommended posts for post with given postId
     * @param {string} postId
     * @returns array
     */
    getRecommendedPosts(postId) {
        const config = {
            method: 'get',
            baseURL: this.baseURL,
            url: `/recommended/${postId}`,
            params: {
                lang: this.getLang(this.$i18n.localeProperties),
            },
        };

        return this.$axios(config).then(response => {
            const relatedPosts = response.data?.recommended_data?.related_posts?.map(p =>
                this.formatter.formatPostCard(p)
            );
            const latestPosts = response.data?.recommended_data?.latest_posts?.map(p =>
                this.formatter.formatPostCard(p)
            );

            return {
                relatedPosts,
                latestPosts,
            };
        });
    }

    /**
     * Get the connected translations of post with given postId
     * @param {array} postIds
     * @returns array
     */
    getPostsWithIds(postIds, postType = 'posts') {
        const config = {
            method: 'get',
            baseURL: this.baseURL,
            url: `/${postType}`,
            params: {
                include: postIds,
            },
        };

        return this.$axios(config).then(response => response.data.map(p => this.formatter.formatPost(p)));
    }

    /**
     * Get post with given slug
     * @param {string} slug
     * @returns object
     */
    getPostWithSlug(slug) {
        const config = {
            method: 'get',
            baseURL: this.baseURL,
            url: '/posts',
            params: {
                slug,
                _embed: 'wp:term,wp:featuredmedia',
                lang: this.getLang(this.$i18n.localeProperties),
            },
        };

        return this.$axios(config)
            .then(response => {
                const { data } = response;

                if (!data || data.length === 0) {
                    this.redirect(301, this.app.localePath({ name: 'magazine' }));
                }

                return data;
            })
            .then(data => this.formatter.formatPost(data[0]));
    }

    /**
     * Get taxonomy page with given slug
     * @param {string} endpoint - The endpoint to get data from (e.g., 'categories' or 'tags')
     * @param {string} slug
     * @returns Promise
     */
    getTaxonomyWithSlug(slug, endpoint = 'categories') {
        const config = {
            method: 'get',
            baseURL: this.baseURL,
            url: `/${endpoint}`,
            params: {
                slug,
                lang: this.getLang(this.$i18n.localeProperties),
            },
        };

        return this.$axios(config)
            .then(response => {
                const { data } = response;

                if (!data || data.length === 0) {
                    this.redirect(301, this.app.localePath({ name: 'magazine' }));
                }

                return data;
            })
            .then(data => this.formatter.formatTaxonomyPage(data[0]));
    }

    /**
     * Get the connected translations of a taxonomy page with given ids
     * @param {array} taxonomyIds
     * @returns array
     */
    getTaxonomiesWithIds(taxonomyIds, type = 'categories') {
        const config = {
            method: 'get',
            baseURL: this.baseURL,
            url: `/${type}`,
            params: {
                include: taxonomyIds,
            },
        };

        return this.$axios(config).then(response => response.data.map(p => this.formatter.formatTaxonomyPage(p)));
    }

    /**
     * Get navigation menu items
     * @returns array
     */
    getNavigationMenuItems() {
        const config = {
            method: 'get',
            baseURL: this.baseURL,
            url: '/navigation',
            params: {
                lang: this.getLang(this.$i18n.localeProperties),
            },
        };

        return this.$axios(config).then(response => response.data.navigation_data);
    }
}
