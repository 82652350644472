<template>
    <LocalizedLink :to="{ name: 'getting-started' }" class="getting-started-progress-bar">
        <SteppedProgressBar :steps="progressBarSteps" />
    </LocalizedLink>
</template>

<script>
import { mapGetters } from 'vuex';
import SteppedProgressBar from '~/components/SteppedProgressBar.vue';

export default {
    components: {
        SteppedProgressBar,
    },
    computed: {
        ...mapGetters([
            'user',
            'isRenter',
            'isOwner',
            'isFullyOnboarded',
            'myFirstVehicle',
            'isFirstVehicleComplete',
            'hasAnyActiveVehicle',
        ]),
        progressBarSteps() {
            if (this.isRenter) {
                return [
                    {
                        number: 1,
                        label: this.$t('renter_onboarding.step_create_account'),
                        key: 'create-account',
                        isComplete: true,
                        isActive: false,
                    },
                    {
                        number: 2,
                        label: this.$t('renter_onboarding.step_verify_id'),
                        key: 'id-verification',
                        isComplete: this.user.isIdVerified,
                        isActive: !this.user.isIdVerified,
                    },
                    {
                        number: 3,
                        label: this.$t('renter_onboarding.step_about_me'),
                        key: 'complete-profile',
                        isComplete: this.isFullyOnboarded,
                        isActive: this.user.isIdVerified && !this.isFullyOnboarded,
                    },
                ];
            }

            if (this.isOwner) {
                return [
                    {
                        number: 1,
                        label: this.$t('pages.owner_onboarding.step_create_account'),
                        key: 'create-account',
                        isComplete: true,
                        isActive: false,
                    },
                    {
                        number: 2,
                        label: this.$t('pages.owner_onboarding.step_publish_vehicle'),
                        key: 'create-vehicle',
                        isComplete: this.hasAnyActiveVehicle,
                        isActive: this.myFirstVehicle && !this.hasAnyActiveVehicle,
                    },
                    {
                        number: 3,
                        label: this.$t('pages.owner_onboarding.step_verify_id'),
                        key: 'id-verification',
                        isComplete: this.isFullyOnboarded,
                        isActive: this.hasAnyActiveVehicle && !this.isFullyOnboarded,
                        delayed: true,
                    },
                ];
            }

            return [];
        },
    },
};
</script>

<style lang="scss">
.getting-started-progress-bar {
    text-decoration: none;
}
</style>
