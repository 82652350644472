/* eslint-disable no-param-reassign, no-shadow */

export const mutationTypes = {
    SET_ACTIVE_MENU_ITEM: 'NAVIGATION/SET_ACTIVE_MENU_ITEM',
    SET_OPEN_SUB_MENU: 'NAVIGATION/SET_OPEN_SUB_MENU',
    SET_ROUTE_PARAMS: 'NAVIGATION/SET_ROUTE_PARAMS',
};

export const actionTypes = {
    MARK_ITEM_ACTIVE: 'NAVIGATION/MARK_ITEM_ACTIVE',
    CLEAR_ACTIVE_MENU_ITEM: 'NAVIGATION/CLEAR_ACTIVE_MENU_ITEM',
    OPEN_SUB_MENU: 'NAVIGATION/OPEN_SUB_MENU',
    TOGGLE_SUB_MENU: 'NAVIGATION/TOGGLE_SUB_MENU',
    CLOSE_SUB_MENU: 'NAVIGATION/CLOSE_SUB_MENU',
    SET_ROUTE_PARAMS: 'NAVIGATION/SET_ROUTE_PARAMS',
    CLEAR_ROUTE_PARAMS: 'NAVIGATION/CLEAR_ROUTE_PARAMS',
};

export const state = () => ({
    activeMenuItem: null,
    openSubMenu: null,
    routeParams: {},
});

export const getters = {
    activeMenuItem: state => state.activeMenuItem,
    openSubMenu: state => state.openSubMenu,
    localeRouteParams: state => locale => state.routeParams[locale] || {},
};

export const mutations = {
    [mutationTypes.SET_ACTIVE_MENU_ITEM](state, activeMenuItem) {
        state.activeMenuItem = activeMenuItem;
    },
    [mutationTypes.SET_OPEN_SUB_MENU](state, openSubMenu) {
        state.openSubMenu = openSubMenu;
    },
    [mutationTypes.SET_ROUTE_PARAMS](state, routeParams) {
        state.routeParams = routeParams;
    },
};

export const actions = {
    [actionTypes.MARK_ITEM_ACTIVE]({ commit }, activeMenuItem) {
        commit(mutationTypes.SET_ACTIVE_MENU_ITEM, activeMenuItem);
    },

    [actionTypes.CLEAR_ACTIVE_MENU_ITEM]({ commit }) {
        commit(mutationTypes.SET_ACTIVE_MENU_ITEM, null);
    },

    [actionTypes.TOGGLE_SUB_MENU]({ commit, getters }, openSubMenu) {
        if (getters.openSubMenu === openSubMenu) {
            commit(mutationTypes.SET_OPEN_SUB_MENU, null);
        } else {
            commit(mutationTypes.SET_OPEN_SUB_MENU, openSubMenu);
        }
    },
    [actionTypes.OPEN_SUB_MENU]({ commit }, openSubMenu) {
        commit(mutationTypes.SET_OPEN_SUB_MENU, openSubMenu);
    },
    [actionTypes.CLOSE_SUB_MENU]({ commit }) {
        commit(mutationTypes.SET_OPEN_SUB_MENU, null);
    },
    /**
     * This is a wrapper around the i18n/setRouteParams action
     *
     * We need it since i18n filters out locales that are not enabled
     */
    [actionTypes.SET_ROUTE_PARAMS]({ commit, dispatch }, { routeParams, i18n }) {
        commit(mutationTypes.SET_ROUTE_PARAMS, routeParams);

        // Only set the i18n:s routeParams for locales that are enabled
        // (we need them to use normal <nuxt-link> for internal language switcher links)
        const i18nRouteParams = {};

        Object.keys(routeParams).forEach(locale => {
            if (i18n.locales.map(l => l.code).includes(locale)) {
                i18nRouteParams[locale] = routeParams[locale];
            }
        });

        dispatch('i18n/setRouteParams', i18nRouteParams);
    },
    [actionTypes.CLEAR_ROUTE_PARAMS]({ commit }) {
        commit(mutationTypes.SET_ROUTE_PARAMS, {});
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
};
