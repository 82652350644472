import { nordicCountryCodes } from './countryCodeLists';

// Types
export const OMOCOM = 'omocom';
export const BALOISE = 'baloise';
export const OWN = 'own';

// Vendor IDs
export const OMOCOM_SE_ID = 'omocom_se';
export const OMOCOM_NO_ID = 'omocom_no';
export const OMOCOM_FI_ID = 'omocom_fi';
export const OMOCOM_DK_ID = 'omocom_dk';

export const OMOCOM_VENDOR_IDS = [OMOCOM_SE_ID, OMOCOM_NO_ID, OMOCOM_FI_ID, OMOCOM_DK_ID];

export const BALOISE_ID = 'baloise_ch';

// We don't allow nordic vehicles heavier than this on our platform
// Essentially, not possible at all for nordic vehicles and possible, but without Baloise, for swiss vehicles
export const MAXIMUM_AUTHORIZED_MASS_LIMIT = 7500;

// @note: These limits are derived from Omocom insurance product specifications (except the asset country codes)
export const OMOCOM_INSURANCE_LIMITS = {
    mam: 6000, // kg
    minValueForTrackingDeviceRequirement: 100000000, // SEK-öre
    odometerValue: 300000, // km
    allowedAssetCountryCodes: nordicCountryCodes,
};

export const BALOISE_INSURANCE_LIMITS = {
    mam: MAXIMUM_AUTHORIZED_MASS_LIMIT,
    allowedAssetCountryCodes: ['CH', 'LI'],
    allowedRenterCountryCodes: ['CH', 'LI'],
};

// Limit for whether we should import the meter value or not
export const ODOMETER_VALUE_IMPORT_LIMIT = OMOCOM_INSURANCE_LIMITS.odometerValue * 0.8; // 80% of max

export const INSURANCE_VALUE_OPTIONS = [
    { storedValue: 25000000, valueFrom: 0, valueTo: 25000000 },
    { storedValue: 50000000, valueFrom: 25000000, valueTo: 50000000 },
    { storedValue: 75000000, valueFrom: 50000000, valueTo: 75000000 },
    { storedValue: 100000000, valueFrom: 75000000, valueTo: 100000000 },
    { storedValue: 125000000, valueFrom: 100000000, valueTo: 125000000 },
    { storedValue: 999999900, valueFrom: 125000000, valueTo: -1 },
];

export default {};
