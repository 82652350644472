export default class ConfigService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    getHealthStatus() {
        const config = {
            method: 'get',
            url: '/health.json',
            baseURL: '',
        };

        return this.$axios(config).then(response => {
            const siteHealth = response.data;

            return siteHealth.replace(/\n/g, '').trim();
        });
    }

    getExchangeRates(base = null) {
        const config = {
            method: 'get',
            url: '/exchange-rates',
        };

        if (base !== null) {
            config.params = { base };
        }

        return this.$axios(config).then(response => response.data.data);
    }

    getUserReviews() {
        const config = {
            method: 'get',
            url: '/user-reviews/stats',
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
