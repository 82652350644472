import { render, staticRenderFns } from "./ScrollToTop.vue?vue&type=template&id=24f1636d&scoped=true&"
import script from "./ScrollToTop.vue?vue&type=script&lang=js&"
export * from "./ScrollToTop.vue?vue&type=script&lang=js&"
import style0 from "./ScrollToTop.vue?vue&type=style&index=0&id=24f1636d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24f1636d",
  null
  
)

export default component.exports