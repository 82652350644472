<template>
    <div v-if="isVisible" class="header-banner" :class="modifierClasses">
        <slot />

        <a v-if="isCloseable" class="header-banner__close" @click="close">
            <svg-icon name="close" />
        </a>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'success',
        },
        closeableId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isVisible: this.closeableId === null || !this.$cookies.get(`main_header_banner_${this.closeableId}_closed`),
        };
    },
    computed: {
        isCloseable() {
            return this.closeableId !== null;
        },
        modifierClasses() {
            const classes = [`header-banner--${this.type}`];

            if (this.isCloseable) {
                classes.push('header-banner--closeable');
            }

            return classes;
        },
    },
    methods: {
        close() {
            this.isVisible = false;
            this.$cookies.set(`main_header_banner_${this.closeableId}_closed`, true);
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.header-banner {
    @extend %heading-3;
    background: $bright-blue-light;
    border-bottom: 1px solid $bright-blue;
    padding: 20px;
    position: relative;
    @include font-size(16px);
    font-weight: normal;
    line-height: 1.25em;

    @include lg {
        text-align: center;
    }

    &--warning {
        background: $warning-light;
        border-color: $warning;
    }

    &--error {
        background: $error;
        border-color: $error;
    }

    &--closeable {
        padding-right: 40px;
    }

    &__close {
        position: absolute;
        top: 2px;
        right: 0;
        padding: 15px;

        .svg-icon {
            width: 24px;
            height: 24px;
            color: $primary;
        }
    }
}
</style>
