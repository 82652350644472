export default class BookingService {
    constructor({ $axios, $sentry }) {
        this.$axios = $axios;
        this.$sentry = $sentry;
    }

    /**
     * Sends a request to create a new booking
     *
     * @param {object} data
     */
    createBooking(data) {
        const config = {
            method: 'post',
            url: '/bookings',
            data,
        };

        // Not returning response.data.data here since there's additional info appended with this response
        // (namely isRentersFirstBooking)
        return this.$axios(config).then(response => response.data);
    }

    /**
     * Get all bookings for current user
     */
    getBookings(options = {}) {
        options.include = typeof options.include === 'undefined' ? 'order,changeRequests' : options.include;
        const config = {
            method: 'get',
            url: '/bookings',
            params: options,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get a single booking
     *
     * @param {integer} id
     */
    getBooking(id) {
        const config = {
            method: 'get',
            url: `/bookings/${id}`,
            params: {
                include: [
                    'order',
                    'changeRequests',
                    'additionalDrivers',
                    'extraCharges',
                    'extraChargeDiscounts',
                    'checkinDamages',
                    'checkoutDamages',
                ].join(','),
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Updates given booking with given data
     *
     * @param {integer} id Booking ID
     * @param {string} data Data to set
     */
    updateBooking(id, data) {
        const config = {
            method: 'put',
            url: `/bookings/${id}`,
            params: { include: 'order,extraCharges,extraChargeDiscounts,checkinDamages,checkoutDamages' },
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Sets new dates for given booking ID (only applicable by renters for booking requests)
     *
     * @param {integer} id Booking ID
     * @param {string} fromDate
     * @param {string} toDate
     */
    resetWithNewDates(id, fromDate, toDate) {
        const config = {
            method: 'post',
            url: `/bookings/${id}/new-dates`,
            data: {
                fromDate,
                toDate,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Do given action on the booking
     *
     * @param {integer} id Booking ID
     * @param {object} data has action, e.g. "cancelBooking", cancellation reason (string) & other reason text (if it exists)
     */
    doAction(id, data) {
        const config = {
            method: 'post',
            url: `/bookings/${id}/actions`,
            data: { ...data },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get booking history for the log
     *
     * @param {integer} id
     */
    getHistory(id) {
        const config = {
            method: 'get',
            url: `/bookings/${id}/history`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Download the rental contract PDF for the booking
     *
     * @param {integer} bookingId
     * @returns {Promise<Blob>}
     */
    downloadRentalContract(bookingId) {
        const config = {
            method: 'get',
            url: `/bookings/${bookingId}/rental-contract`,
            responseType: 'blob',
        };

        return this.$axios(config).then(response => response.data);
    }

    /**
     * Get related change requests
     *
     * @param {integer} bookingId
     */
    getChangeRequests(bookingId) {
        const config = {
            method: 'get',
            url: `/bookings/${bookingId}/change-requests`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get related extra charges
     *
     * @param {integer} bookingId
     */
    getExtraCharges(bookingId) {
        const config = {
            method: 'get',
            url: `/bookings/${bookingId}/extra-charges`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    finalizeCheckin(id) {
        const config = {
            method: 'post',
            url: `/bookings/${id}/finalize-checkin`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    approveCheckin(id) {
        const config = {
            method: 'post',
            url: `/bookings/${id}/approve-checkin`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    finalizeCheckout(id) {
        const config = {
            method: 'post',
            url: `/bookings/${id}/finalize-checkout`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    approveCheckout(id) {
        const config = {
            method: 'post',
            url: `/bookings/${id}/approve-checkout`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Rotates a checkin image
     *
     * @param {integer} bookingId
     * @param {string} type Image type to delete
     * @param {string} direction Left or right
     */
    rotateCheckinImage(bookingId, type, direction) {
        const config = {
            method: 'post',
            url: `/bookings/${bookingId}/checkin-images/${type}/rotate-${direction}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Rotates a checkout image
     *
     * @param {integer} bookingId
     * @param {string} type Image type to delete
     * @param {string} direction Left or right
     */
    rotateCheckoutImage(bookingId, type, direction) {
        const config = {
            method: 'post',
            url: `/bookings/${bookingId}/checkout-images/${type}/rotate-${direction}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Deletes a checkin image
     *
     * @param {integer} bookingId
     * @param {string} type Image type to delete
     */
    deleteCheckinImage(bookingId, type) {
        const config = {
            method: 'delete',
            url: `/bookings/${bookingId}/checkin-images/${type}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Deletes a checkout image
     *
     * @param {integer} bookingId
     * @param {string} type Image type to delete
     */
    deleteCheckoutImage(bookingId, type) {
        const config = {
            method: 'delete',
            url: `/bookings/${bookingId}/checkout-images/${type}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    syncCheckinDamages(bookingId) {
        const config = {
            method: 'post',
            url: `/bookings/${bookingId}/sync-checkin-damages`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Creates a review
     * @param {integer} bookingId
     * @param {integer} overallScore
     * @param {string|null} overallComment
     */
    createReview(bookingId, overallScore, overallComment = null) {
        const data = { overallScore };

        if (overallComment) {
            data.overallComment = overallComment;
        }

        const config = {
            method: 'post',
            url: `/bookings/${bookingId}/reviews`,
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get insurance claims for given booking
     */
    getInsuranceClaims(bookingId) {
        const config = {
            method: 'get',
            url: `/bookings/${bookingId}/insurance-claims`,
        };

        return this.$axios(config).then(response => {
            // All of the insurance claims that have a status "NoClaim" should be filtered out
            return response.data.data.filter(claim => claim.status.toLowerCase() !== 'noclaim');
        });
    }

    extendDepositReleaseDeadline(bookingId, reason) {
        const config = {
            method: 'post',
            url: `/bookings/${bookingId}/extend-deposit-release-deadline`,
            data: { reason },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    releaseDeposit(bookingId) {
        const config = {
            method: 'post',
            url: `/bookings/${bookingId}/release-deposit`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Gets insurance policy for given booking
     *
     * @param {integer} bookingId ID of the booking
     */
    getInsurancePolicy(bookingId) {
        const config = {
            method: 'get',
            url: `/bookings/${bookingId}/insurance-policy`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Gets available addons for given booking
     *
     * @param {integer} bookingId ID of the booking
     */
    getAvailableAddons(bookingId) {
        const config = {
            method: 'get',
            url: `/bookings/${bookingId}/available-addons`,
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
