export default function ({ app, redirect, error }) {
    const { mcTld, featureFlags } = app.$config;
    const isMaintenanceMode =
        (featureFlags.comDownForMaintenance && mcTld === 'com') ||
        (featureFlags.chDownForMaintenance && mcTld === 'ch');

    if (isMaintenanceMode) {
        return error({
            statusCode: 503,
            message: 'Service Unavailable - Down for maintenance',
        });
    }
}
