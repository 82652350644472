<template>
    <div
        :class="{
            'main-layout--white-bg': whiteBg,
            [`lang-${$i18n.locale}`]: true,
        }"
        class="main-layout"
    >
        <MainHeaderBanner v-if="displayHeaderWithinErrorPage" />

        <div class="main-layout__content-wrapper">
            <MainHeader v-if="displayHeaderWithinErrorPage" class="main-layout__header" />
            <div class="main-layout__content">
                <Nuxt />
            </div>
            <MainFooter v-if="displayFooter" class="main-layout__footer" />
            <Modal v-if="modalConfig !== null" :config="modalConfig" />
            <StickySupportButton v-if="getRouteBaseName() !== 'magazine-slug'" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MainHeaderBanner from '~/components/layout/MainHeaderBanner.vue';
import MainHeader from '~/components/layout/MainHeader.vue';
import MainFooter from '~/components/layout/MainFooter.vue';
import StickySupportButton from '~/components/StickySupportButton.vue';
import Modal from '~/components/modals/Modal.vue';
import { actionTypes as configActions } from '~/store/config';
import { actionTypes as navigationActions } from '~/store/navigation';
import userModalsHandlerMixin from '~/mixins/userModalsHandlerMixin';
import logoutRedirectMixin from '~/mixins/logoutRedirectMixin';

export default {
    name: 'DefaultLayout',
    components: {
        MainHeaderBanner,
        MainHeader,
        MainFooter,
        StickySupportButton,
        Modal,
    },
    mixins: [userModalsHandlerMixin, logoutRedirectMixin],
    middleware: ['isMobileOrLarge'],
    async fetch() {
        try {
            await this.$store.dispatch(configActions.FETCH_RATES);
        } catch (err) {
            this.$sentry.captureException(err);
        }
    },
    computed: {
        ...mapGetters(['modalConfig', 'isLoggedIn']),
        floatingHeader() {
            const routesWithFloatingHeader = ['rent-out'];

            return routesWithFloatingHeader.includes(this.getRouteBaseName());
        },
        whiteBg() {
            const routesWithWhiteBg = ['booking'];

            return routesWithWhiteBg.includes(this.getRouteBaseName());
        },
        displayFooter() {
            if (this.$nuxt.context.app.nuxt.err) {
                return true;
            }

            const routesWithoutFooter = ['all', 'messages'];

            return !routesWithoutFooter.includes(this.getRouteBaseName());
        },
        displayHeaderWithinErrorPage() {
            return this.$nuxt?.nuxt?.err?.statusCode !== 503; // maintenance mode
        },
    },
    watch: {
        $route: {
            handler() {
                this.$store.dispatch(navigationActions.CLOSE_SUB_MENU);
            },
            immediate: true,
        },
    },
    mounted() {
        this.$store.dispatch(configActions.HEALTH_CHECK);

        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    destroyed() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    methods: {
        checkScreenSize() {
            const isLargeScreen = window.innerWidth > 991;
            this.$store.dispatch(configActions.SET_IS_LARGE_SCREEN, isLargeScreen);
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.main-layout {
    min-height: 100vh;

    &__content-wrapper {
        display: flex;
        flex-direction: column;
    }

    &__content {
        flex-grow: 1;
        position: relative;
    }

    &--floating-header {
        .main-layout__content {
            padding: 0;
        }
    }

    &--white-bg {
        background: $white;
    }
}
</style>
