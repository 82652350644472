<template>
    <div class="stepped-progress-bar" :class="'is-count-' + steps.length">
        <div
            v-for="step in steps"
            :key="step.number"
            class="stepped-progress-bar__step"
            :class="{ 'is-delayed': step.delayed }"
        >
            <ProgressIcon
                class="stepped-progress-bar__icon"
                :number="step.number"
                :is-complete="step.isComplete"
                :is-active="step.isActive"
            />
            <div class="stepped-progress-bar__label">{{ step.label }}</div>
        </div>
    </div>
</template>

<script>
import ProgressIcon from '~/components/getting-started/ProgressIcon.vue';

export default {
    components: {
        ProgressIcon,
    },
    props: {
        steps: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.stepped-progress-bar {
    background: $white;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    color: $primary;

    @include md {
        padding: 20px 0;
    }

    &__step {
        text-align: center;
        position: relative;
        width: 160px;

        &:before {
            content: '';
            position: absolute;
            top: 0.9em;
            border-radius: 2px;
            height: 4px;
            background: $cream-white;
            width: 80px;
            left: -40px;

            .is-count-2 & {
                width: 110px;
                left: -55px;
            }
        }

        &.is-delayed:before {
            background: $cream-white;
        }

        &:first-child:before {
            display: none;
        }

        @include md {
            width: 200px;

            &:before {
                &,
                .is-count-2 & {
                    left: -70px;
                    width: 140px;
                }
            }
        }
    }

    &__icon {
        display: block;
        margin: auto;
        margin-bottom: 5px;
    }

    &__label {
        font-family: $base-font;
        @include font-size(13px);
        font-weight: 400;
    }
}
</style>
