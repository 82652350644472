import locale9ec66352 from '../../lang/en.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: false,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"tld":"com","code":"en","file":"en.json","name":"English","iso":"en","shortName":"en","queryTranslationsPlaceholder":{"all-vehicles":"common.all_vehicles_query"}},{"tld":"com","code":"de","file":"de.json","name":"Deutsch","iso":"de","shortName":"de","queryTranslationsPlaceholder":{"alle-fahrzeuge":"common.all_vehicles_query"}},{"tld":"com","code":"se","file":"sv.json","name":"Svenska","iso":"sv-SE","shortName":"sv","queryTranslationsPlaceholder":{"alla-fordon":"common.all_vehicles_query"}},{"tld":"com","code":"no","file":"nb.json","name":"Norsk","iso":"nb-NO","shortName":"no","queryTranslationsPlaceholder":{"alle-kjoretoy":"common.all_vehicles_query"}},{"tld":"com","code":"fi","file":"fi.json","name":"Suomi","iso":"fi-FI","shortName":"fi","queryTranslationsPlaceholder":{"kaikki-ajoneuvot":"common.all_vehicles_query"}},{"tld":"com","code":"fi-sv","file":"sv.json","name":"Svenska","iso":"sv-FI","shortName":"sv","queryTranslationsPlaceholder":{"alla-fordon":"common.all_vehicles_query"}},{"tld":"com","code":"dk","file":"da.json","name":"Dansk","iso":"da-DK","shortName":"da","queryTranslationsPlaceholder":{"alle-koeretoejer":"common.all_vehicles_query"}}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/app/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"locale","cookieSecure":false,"fallbackLocale":"en","redirectOn":"root","useCookie":true,"redirectedOn":"root"},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  locale: "en",
  normalizedLocales: [{"tld":"com","code":"en","file":"en.json","name":"English","iso":"en","shortName":"en","queryTranslationsPlaceholder":{"all-vehicles":"common.all_vehicles_query"}},{"tld":"com","code":"de","file":"de.json","name":"Deutsch","iso":"de","shortName":"de","queryTranslationsPlaceholder":{"alle-fahrzeuge":"common.all_vehicles_query"}},{"tld":"com","code":"se","file":"sv.json","name":"Svenska","iso":"sv-SE","shortName":"sv","queryTranslationsPlaceholder":{"alla-fordon":"common.all_vehicles_query"}},{"tld":"com","code":"no","file":"nb.json","name":"Norsk","iso":"nb-NO","shortName":"no","queryTranslationsPlaceholder":{"alle-kjoretoy":"common.all_vehicles_query"}},{"tld":"com","code":"fi","file":"fi.json","name":"Suomi","iso":"fi-FI","shortName":"fi","queryTranslationsPlaceholder":{"kaikki-ajoneuvot":"common.all_vehicles_query"}},{"tld":"com","code":"fi-sv","file":"sv.json","name":"Svenska","iso":"sv-FI","shortName":"sv","queryTranslationsPlaceholder":{"alla-fordon":"common.all_vehicles_query"}},{"tld":"com","code":"dk","file":"da.json","name":"Dansk","iso":"da-DK","shortName":"da","queryTranslationsPlaceholder":{"alle-koeretoejer":"common.all_vehicles_query"}}],
  localeCodes: ["en","de","se","no","fi","fi-sv","dk"],
  additionalMessages: [],
}

export const localeMessages = {
  'en.json': () => Promise.resolve(locale9ec66352),
  'de.json': () => import('../../lang/de.json' /* webpackChunkName: "lang-de.json" */),
  'sv.json': () => import('../../lang/sv.json' /* webpackChunkName: "lang-sv.json" */),
  'nb.json': () => import('../../lang/nb.json' /* webpackChunkName: "lang-nb.json" */),
  'fi.json': () => import('../../lang/fi.json' /* webpackChunkName: "lang-fi.json" */),
  'da.json': () => import('../../lang/da.json' /* webpackChunkName: "lang-da.json" */),
}
