<!-- Not in the sections folder because of a design conflict with the magazine -->
<template>
    <div class="popular-campers">
        <h2 class="popular-campers__heading">{{ $t('sections.popular_campers.heading') }}</h2>
        <VehicleList v-if="isLoading && vehicles.length === 0" :loading-count="3" />
        <VehicleList :vehicles="vehicles" list-name="magazine" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VehicleList from '~/components/vehicles/VehicleList.vue';

export default {
    name: 'PopularCampers',
    components: {
        VehicleList,
    },
    data() {
        return {
            isLoading: true,
            vehicles: [],
        };
    },
    async fetch() {
        await this.fetchVehicles();
    },
    computed: {
        ...mapGetters(['siteCountryCode']),
    },
    methods: {
        fetchVehicles() {
            this.isLoading = true;

            // Store not updated at this point so we manually get 'siteCountryCode'
            const locale = new Intl.Locale(this._i18n.localeProperties.iso);
            const countryCode = locale.region || null;
            // TOOD: Does not update on locale switch

            return this.$vehicleService
                .getVehicles({}, countryCode, null, 3, 'owner')
                .then(data => {
                    this.vehicles = data.vehicles;
                })
                .catch(err => {
                    this.$sentry.captureException(err);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.popular-campers {
    &__heading {
        @extend %heading-2;
        margin-bottom: 16px;
    }

    .vehicle-list {
        &__item {
            width: 100%;

            @include lg {
                width: calc(100% / 3 - 20px);
            }
        }
    }
}
</style>
