<template>
    <div :class="['error-page', `error-page__${statusCode}`]">
        <div v-if="error.statusCode === 503">
            <Maintenance />
        </div>
        <div v-else class="container">
            <div class="error-page__left">
                <h1>{{ errorTitle }}</h1>
                <div class="error-page__left--info-text-1">
                    {{ errorInfoText1 }}
                </div>
                <div class="error-code">{{ $t('pages.error.error_code_label') }} {{ statusCode }}</div>

                <button
                    v-if="!isServerError"
                    class="button button--primary button--block"
                    @click="$router.push(localePath({ name: 'index' }))"
                >
                    {{ $t('pages.error.cta_label') }}
                </button>

                <div v-if="!isServerError" class="error-page__left--info-text-2">
                    {{ errorInfoText2 }}
                </div>
                <div v-if="isServerError" class="error-page__mobile--info-text-2">
                    {{ $t(`pages.error.500.info_text_2_1`) }}
                    <a :href="$t('links.faq')" target="_blank" class="error-page__link">
                        {{ $t(`pages.error.500.help center_link_label`) }}
                    </a>
                    {{ $t(`pages.error.500.info_text_2_2`) }}
                </div>
                <a v-if="!isServerError" :href="$t('links.faq')" target="_blank" class="error-page__link">
                    {{ $t('pages.error.faq_link') }}
                </a>
            </div>
            <div class="error-page__right">
                <img :src="imageSrc" />
            </div>
            <div class="error-page__mobile">
                <h1 v-if="isServerError">{{ errorTitle }}</h1>
                <div v-if="isServerError" class="error-page__left--info-text-1">
                    {{ errorInfoText1 }}
                </div>
                <div v-if="isServerError" class="error-code">
                    {{ $t('pages.error.error_code_label') }} {{ statusCode }}
                </div>
                <button
                    v-if="!isServerError"
                    class="button button--primary button--block"
                    @click="$router.push(localePath({ name: 'index' }))"
                >
                    {{ $t('pages.error.cta_label') }}
                </button>
                <div v-if="!isServerError" class="error-page__mobile--info-text-2">
                    {{ errorInfoText2 }}
                </div>
                <div v-if="isServerError" class="error-page__mobile--info-text-2">
                    {{ $t(`pages.error.500.info_text_2_1`) }}
                    <a :href="$t('links.faq')" target="_blank" class="error-page__link">
                        {{ $t(`pages.error.500.help center_link_label`) }}
                    </a>
                    {{ $t(`pages.error.500.info_text_2_2`) }}
                </div>
                <a v-if="!isServerError" :href="$t('links.faq')" target="_blank" class="error-page__link">
                    {{ $t('pages.error.faq_link') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Maintenance from '~/components/Maintenance.vue';
export default {
    components: { Maintenance },
    props: {
        error: {
            type: null,
            default: null,
        },
    },
    head() {
        return {
            meta: [{ name: 'robots', content: 'noindex, follow' }],
        };
    },
    computed: {
        statusCode() {
            if (this.error instanceof Error) {
                if (this.error.message !== '404 page not found') {
                    return 404;
                }
            } else if (this.error.statusCode) {
                return this.error.statusCode;
            }

            return 500;
        },
        errorTitle() {
            if (this.statusCode === 401) {
                return this.$t(`pages.error.401.title`);
            }

            if (this.statusCode === 403) {
                return this.$t(`pages.error.403.title`);
            }

            if (this.isServerError) {
                return this.$t(`pages.error.500.title`);
            }

            return this.$t(`pages.error.400.title`);
        },
        errorInfoText1() {
            if (this.statusCode === 401) {
                return this.$t(`pages.error.401.info_text_1`);
            }

            if (this.statusCode === 403) {
                return this.$t(`pages.error.403.info_text_1`);
            }

            if (this.isServerError) {
                return this.$t(`pages.error.500.info_text_1`);
            }

            return this.$t(`pages.error.400.info_text_1`);
        },
        errorInfoText2() {
            if (this.statusCode === 401) {
                return this.$t(`pages.error.401.info_text_2`);
            }

            if (this.statusCode === 403) {
                return this.$t(`pages.error.403.info_text_2`);
            }

            return this.$t(`pages.error.400.info_text_2`);
        },
        imageSrc() {
            if (this.statusCode === 403) {
                return require('~/assets/images/403_error.svg');
            }

            if (this.statusCode >= 400 && this.statusCode < 500) {
                return require('~/assets/images/400_error.svg');
            }

            if (this.isServerError) {
                return require('~/assets/images/500_error.svg');
            }

            return require('~/assets/images/400_error.svg');
        },
        isServerError() {
            return this.statusCode >= 500 && this.statusCode < 600;
        },
    },
    mounted() {
        this.$sentry.captureException(this.error);

        this.$nextTick(() => {
            this.$gtm.trackEvent({
                event: 'mc_error',
                category: 'Errors',
                action: this.error.statusCode,
                label: window.location.href,
            });
        });
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.error-page {
    overflow: hidden;
    background-color: $white;

    .container {
        position: relative;
        padding: 48px 10px 32px;
        display: flex;
        flex-wrap: wrap;

        @include lg {
            padding: 48px 0 80px;
        }
    }

    h1 {
        @extend %heading-1;
        text-transform: initial;
        margin-bottom: 24px;

        @include md {
            font-size: 28px;
            line-height: 32px;
        }
    }

    &__left {
        position: relative;
        padding-top: 0;
        min-height: 220px;
        flex: 0 0 51.5%;
        max-width: 51.5%;

        &--info-text-1 {
            line-height: 24px;
        }

        .error-code {
            margin-bottom: 24px;
            line-height: 24px;
        }

        .button {
            display: none;

            @include lg {
                display: block;
            }
        }

        &--info-text-2 {
            display: none;

            @include lg {
                display: block;
                line-height: 24px;
                margin: 24px 0;
            }
        }

        .error-page__link {
            display: none;

            @include lg {
                display: inline;
            }
        }

        @include sm {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }

        @include md {
            padding-top: 24px;
        }

        @include lg {
            padding-top: 48px;
        }
    }

    &__right {
        flex: 0 0 69%;
        max-width: 69%;
        z-index: 2;
        position: absolute;
        right: -3rem;

        img {
            padding: 24px 0 0 16px;
            width: auto;
            height: 170px;

            @include md {
                width: 100%;
                height: 100%;
                padding: 0;
            }
        }

        @include sm {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
            position: relative;
            right: unset;
        }
    }

    &__mobile {
        width: 100%;

        h1 {
            text-align: center;
        }

        .button {
            width: 100%;

            @include md {
                margin-top: 30px;
            }
        }

        &--info-text-2 {
            margin: 30px 0;
            line-height: 24px;
        }

        @include lg {
            display: none;
        }
    }

    &__403 {
        img {
            padding: 0 0 0 16px;
            width: auto;
            height: 200px;

            @media screen and (min-width: 576px) and (max-width: 768px) {
                padding: 0 0 0 48px;
            }

            @include md {
                width: 100%;
                height: 100%;
                padding: 0;
            }
        }
    }

    &__500 {
        padding-top: 20px;

        h1 {
            margin-top: 16px;
        }

        .error-page__left {
            display: none;

            @include lg {
                display: block;
            }
        }

        .error-page__right {
            position: relative;
            right: unset;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;

            img {
                padding: 0;
            }
        }

        .error-page__mobile--info-text-2 {
            margin-top: 24px;

            a {
                text-decoration: none;
            }
        }
    }

    @include md {
        min-height: 70vh;
    }

    @include xl {
        min-height: calc(100vh - #{$main-header-md-height} - 240px);
    }
}

.main-footer {
    @include xl {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}
</style>
