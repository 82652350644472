<template>
    <nuxt-link :to="localePath(to)" :target="target"><slot /></nuxt-link>
</template>

<script>
export default {
    props: {
        to: {
            type: [String, Object],
            default: null,
        },
        target: {
            type: String,
            default: '_self',
        },
    },
};
</script>

<style></style>
