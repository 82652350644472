<template>
    <svg-icon name="arrow_circle_up" class="scroll-to-top" :class="modifierClasses" @click="scrollToTop" />
</template>

<script>
export default {
    name: 'ScrollToTop',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        modifierClasses() {
            return { 'scroll-to-top--visible': this.show };
        },
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    z-index: $z-magazine-cta-footer;
    -webkit-tap-highlight-color: transparent;

    &--visible {
        opacity: 1;
        visibility: visible;
    }

    @include lg {
        bottom: 60px;
        right: 40px;
    }
}
</style>
