export default class PriceCalculationsService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    calculate(type, subType, properties = null) {
        const config = {
            method: 'post',
            url: '/price-calculations',
            data: {
                type,
                subType,
                properties,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
