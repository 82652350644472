<template>
    <div class="modal" :class="modifierClasses">
        <div :style="options.maskStyle" class="modal__mask">
            <div class="modal__wrapper">
                <div
                    ref="modalContent"
                    v-closable="{
                        handler: 'tryToClose',
                        exclude: ['.modal__content'],
                        disabled: !options.closeable,
                    }"
                    class="modal__content"
                    :class="contentModifierClasses"
                >
                    <div class="modal__header" :class="headerModifierClasses">
                        <!-- Logo header -->
                        <div
                            v-if="options.mode === 'fullscreen' || options.header === 'logo'"
                            class="modal__logo"
                            @click="tryToClose"
                        >
                            <img width="auto" height="25" :src="defaultLogoSrc" alt="MyCamper" />
                        </div>

                        <template v-else-if="options.closeable">
                            <!-- Back header -->
                            <div v-if="options.header === 'return'" @click="tryToClose">
                                <svg-icon name="keyboard_arrow_left" />
                                <span>{{ $t('buttons.back') }}</span>
                            </div>
                            <!-- Close header -->
                            <a v-else class="modal__close" @click="tryToClose">
                                <svg-icon name="close" class="close__icon" />
                            </a>
                        </template>
                    </div>

                    <component
                        :is="options.component"
                        :data="options.data"
                        @canceled="handleCanceled"
                        @confirmed="handleConfirmed"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { actionTypes as modalActions } from '~/store/modal';
import defaultLogoSrc from '~/assets/images/mycamper-logo-with-text.svg';

export default {
    components: {
        AcceptUserTermsModal: () => import('~/components/modals/AcceptUserTermsModal.vue'),
        AdditionalDriverModal: () => import('~/components/modals/AdditionalDriverModal.vue'),
        AssetDamageModal: () => import('~/components/modals/AssetDamageModal.vue'),
        BookingCancelModal: () => import('~/components/modals/BookingCancelModal.vue'),
        BookingChangeRequestModal: () => import('~/components/modals/BookingChangeRequestModal.vue'),
        AddInsuranceAddonsModal: () => import('~/components/modals/AddInsuranceAddonsModal.vue'),
        CamperMaintenanceModal: () => import('~/components/modals/CamperMaintenanceModal.vue'),
        DepositPaymentModal: () => import('~/components/modals/DepositPaymentModal.vue'),
        BookingMenuSectionModal: () => import('~/components/modals/BookingMenuSectionModal.vue'),
        BookingReviewModal: () => import('~/components/modals/BookingReviewModal.vue'),
        CamptooWelcomeModal: () => import('~/components/modals/CamptooWelcomeModal.vue'),
        CompleteDamageModal: () => import('~/components/modals/CompleteDamageModal.vue'),
        ConfirmInsuranceRequirementsModal: () => import('~/components/modals/ConfirmInsuranceRequirementsModal.vue'),
        ConfirmOmocomDetailsModal: () => import('~/components/modals/ConfirmOmocomDetailsModal.vue'),
        ExtendDepositReleaseDeadlineModal: () => import('~/components/modals/ExtendDepositReleaseDeadlineModal.vue'),
        ExtraChargeModal: () => import('~/components/modals/ExtraChargeModal.vue'),
        ExtraChargeDiscountModal: () => import('~/components/modals/ExtraChargeDiscountModal.vue'),
        ForgotPasswordModal: () => import('~/components/modals/ForgotPasswordModal.vue'),
        InstallmentPaymentModal: () => import('~/components/modals/InstallmentPaymentModal.vue'),
        LoginModal: () => import('~/components/modals/LoginModal.vue'),
        NoBankAccountModal: () => import('~/components/modals/NoBankAccountModal.vue'),
        OnboardingModal: () => import('~/components/modals/OnboardingModal.vue'),
        PriceCalculatorModal: () => import('~/components/modals/PriceCalculatorModal.vue'),
        RenterDrivingLicenseClassModal: () => import('~/components/modals/RenterDrivingLicenseClassModal.vue'),
        RenterProfileCompletedModal: () => import('~/components/modals/RenterProfileCompletedModal.vue'),
        SelectNameModal: () => import('~/components/modals/SelectNameModal.vue'),
        SendMessageModal: () => import('~/components/modals/SendMessageModal.vue'),
        SignUpModal: () => import('~/components/modals/SignUpModal.vue'),
        StripeAccountVerificationModal: () => import('~/components/modals/StripeAccountVerificationModal.vue'),
        VehicleDeactivation: () => import('~/components/modals/VehicleDeactivation.vue'),
        VerifyVehicleModal: () => import('~/components/modals/VerifyVehicleModal.vue'),
        SupportRequestModal: () => import('~/components/modals/SupportRequestModal.vue'),
        OverlappingDatesModal: () => import('~/components/modals/OverlappingDatesModal.vue'),
        CompleteBookingPaymentModal: () => import('~/components/modals/CompleteBookingPaymentModal.vue'),
        UnavailableDatesModal: () => import('~/components/modals/UnavailableDatesModal.vue'),
        UnavailableDatesInfoModal: () => import('~/components/modals/UnavailableDatesInfoModal.vue'),
        ContinueLoginModal: () => import('~/components/modals/ContinueLoginModal.vue'),
        NewsletterModal: () => import('~/components/modals/NewsletterModal.vue'),
        VehicleCharacteristicsModal: () => import('~/components/modals/VehicleCharacteristicsModal.vue'),
        VehicleEquipmentModal: () => import('~/components/modals/VehicleEquipmentModal.vue'),
        VehicleRulesModal: () => import('~/components/modals/VehicleRulesModal.vue'),
        VehicleReviewsModal: () => import('~/components/modals/VehicleReviewsModal.vue'),
        CancellationInfoModal: () => import('~/components/modals/CancellationInfoModal.vue'),
        AdditionalEquipmentModal: () => import('~/components/modals/AdditionalEquipmentModal.vue'),
        VehicleDescriptionModal: () => import('~/components/modals/VehicleDescriptionModal.vue'),
        InsuranceLimitationModal: () => import('~/components/modals/InsuranceLimitationModal.vue'),
        AdditionalFiltersModal: () => import('~/components/modals/AdditionalFiltersModal.vue'),
        OptForOmocomInteriorInsuranceModal: () => import('~/components/modals/OptForOmocomInteriorInsuranceModal.vue'),
        ManageVehicleAddonsModal: () => import('~/components/modals/ManageVehicleAddonsModal.vue'),
    },
    props: {
        config: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            defaultLogoSrc,
        };
    },
    computed: {
        options() {
            const defaultConfig = {
                closeable: true,
                mode: 'modal', // fullscreen, modal
                header: 'default', // default, return, logo
                sections: false, // Whether the modal content uses sections (only applicable for fullscreen)
            };

            const options = { ...defaultConfig, ...this.config };

            return options;
        },
        modifierClasses() {
            let classes = `modal--${this.options.mode}`;

            if (this.options.sections) {
                classes += ' modal--sections';
            }

            return classes;
        },
        contentModifierClasses() {
            return {
                'modal__content--lesser-padding': this.options.style?.lesserPadding,
                'modal__content--no-padding': this.options.style?.noPadding,
                'modal__content--return': this.options.header === 'return' && this.options.mode !== 'fullscreen',
            };
        },
        headerModifierClasses() {
            return {
                'modal__header--logo': this.options.header === 'logo',
                'modal__header--return': this.options.header === 'return' && this.options.mode !== 'fullscreen',
            };
        },
    },
    watch: {
        $route() {
            if (this.$store.getters.modalIsObtrusive) {
                return;
            }

            this.close(); // Closes the modal if the user navigates to another page
        },
    },
    created() {
        this.$nextTick(() => {
            if (this.$refs?.modalContent && this.options.style?.width) {
                this.$refs.modalContent.style.maxWidth = `${this.options.style.width}px`;
            }
        });
    },
    mounted() {
        disableBodyScroll(this.$refs.modalContent, {
            allowTouchMove: el => el.classList.contains('vue-slider-dot'),
        });
    },
    methods: {
        tryToClose() {
            if (!this.options.closeable) {
                return;
            }

            this.options.reject();
            this.close();
        },
        handleCanceled() {
            this.options.reject();
            this.close();
        },
        handleConfirmed(data) {
            this.options.resolve(data);
            this.close();
        },
        close() {
            this.$store.dispatch(modalActions.MODAL_CLOSE);
            clearAllBodyScrollLocks(this.$refs.modalContent);
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

$mobile-x-padding: 20px;
$tablet-x-padding: 100px;

.modal {
    $header-height: 70px;

    &__mask {
        position: fixed;
        z-index: $z-modal;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .input {
        transform: translate3d(0, 0, 0);
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100dvh;
    }

    &__title {
        @extend %heading-2;
        margin-bottom: 30px;
    }

    &__subtitle {
        @extend %heading-3;
        margin-top: 1.5em;
        margin-bottom: 12px;
    }

    &__description {
        text-align: left;
    }

    &__logo {
        img {
            vertical-align: middle;
        }
    }

    &__close {
        cursor: pointer;
        float: right;
        padding: 5px;
        margin: 15px 15px 0 0;

        @include md {
            padding: 15px;
        }

        .close__icon {
            color: $primary;
            width: 32px;
            height: 32px;
        }
    }

    &__content {
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
        border-radius: $default-border-radius;
        position: relative;
        background-color: $white;
        margin: 0px auto;
        padding: 30px 30px 60px;
        max-width: 95vw;
        max-height: calc(95vh - 30px);
        overflow-y: auto;
        overflow-x: hidden;
        display: inline-block;
        margin-top: -30px; // Accommodates for bottom nav bar in mobile views

        @include md {
            padding: $tablet-x-padding;
            margin-top: 0;

            &--lesser-padding {
                padding: 40px 30px;
            }

            &--no-padding {
                padding: 0;
            }
        }
    }

    &__header {
        z-index: $z-main-header;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        &--logo {
            padding: 20px;
            border-bottom: 1px solid $gray;
        }

        &--return {
            position: sticky;
            background: $white;
            border-bottom: 1px solid $gray;
            padding: 23px $mobile-x-padding;
            margin-left: -$mobile-x-padding;
            margin-right: -$mobile-x-padding;

            @include sm {
                margin-left: -$tablet-x-padding;
                margin-right: -$tablet-x-padding;
            }

            span {
                vertical-align: middle;
            }

            .svg-icon {
                width: 24px;
                height: 24px;
                margin-right: 5px;
            }
        }
    }

    &--modal {
        .modal__content {
            // Fullscreen on mobile
            @media (max-width: #{$bp-lg-min - 1}) {
                max-width: 100vw;
                max-height: 100dvh;
                width: 100vw;
                height: 100dvh;
                margin: 0;
                padding: 52px $mobile-x-padding 20px; // 52px for header, 20px for padding
                border-radius: 0;

                button {
                    width: 100%;
                }
            }

            @media (min-width: $bp-sm-min) and (max-width: #{$bp-lg-min - 1}) {
                padding-left: $tablet-x-padding;
                padding-right: $tablet-x-padding;
            }

            &--return {
                padding-top: 0;
            }
        }
    }

    &--fullscreen {
        .modal__content {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            max-width: none;
            max-height: none;
            border-radius: 0;
            margin: 0;
            padding: 0;
            padding-top: $header-height;
            background-color: $white;
        }

        .modal__header {
            background: $white;
            position: fixed;
            padding: 0 20px;
            height: $header-height;
            border-bottom: 1px solid $border-color;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .modal__close {
            padding: 0;
        }
    }

    &--sections {
        .modal__content {
            background-color: $gray-light;
        }
    }
}
</style>
