/**
 * Product type names
 *
 * @note Keep this in sync with the type constants in the backend (see Product.php)
 */
export const LOW_SEASON_NIGHT = 'low_season_night';
export const LOW_SEASON_SHORT_BOOKING_NIGHT = 'low_season_short_booking_night';
export const LOW_SEASON_DISCOUNTED_NIGHT = 'low_season_discounted_night';
export const HIGH_SEASON_NIGHT = 'high_season_night';
export const HIGH_SEASON_SHORT_BOOKING_NIGHT = 'high_season_short_booking_night';
export const HIGH_SEASON_DISCOUNTED_NIGHT = 'high_season_discounted_night';
export const PLATFORM_INSURANCE = 'platform_insurance';
export const DEPOSIT = 'deposit';
export const CLEANING = 'cleaning';
export const INSURANCE_ADDON_TYPE = 'insurance_addon';
export const INSURANCE_ADDON = {
    roadside_assistance: 'roadside_assistance',
    deductible_buydown: 'deductible_buydown',
    deductible_insurance: 'deductible_insurance',
    interior_insurance: 'interior_insurance',
    luggage_insurance: 'luggage_insurance',
    cancellation_cost_insurance: 'cancellation_cost_insurance',
};
export const ADDITIONAL_DRIVER = 'additional_driver';
export const ID_VERIFICATION = 'id_verification';
export const TYPE_EQUIPMENT_ADDON = 'equipment_addon';
export const TYPE_SERVICE_ADDON = 'service_addon';
export const EQUIPMENT_GROUP_BIKE_RACK = 'bike_rack';
export const EQUIPMENT_GROUP_BIKE = 'bike';
export const EQUIPMENT_ADDON = {
    additional_chairs: { key: 'additional_chairs', icon: 'chair', allowsQuantity: true },
    awning_tent: { key: 'awning_tent', icon: 'tent' },
    bed_linen: { key: 'bed_linen', icon: 'bedding', allowsQuantity: true },
    bike_rack_child_2: { key: 'bike_rack_child_2', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    bike_rack_child_3: { key: 'bike_rack_child_3', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    bike_rack_child_4: { key: 'bike_rack_child_4', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    bike_rack_city_2: { key: 'bike_rack_city_2', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    bike_rack_city_3: { key: 'bike_rack_city_3', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    bike_rack_city_4: { key: 'bike_rack_city_4', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    bike_rack_e_bike_2: { key: 'bike_rack_e_bike_2', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    bike_rack_e_bike_3: { key: 'bike_rack_e_bike_3', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    bike_rack_e_bike_4: { key: 'bike_rack_e_bike_4', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    bike_rack_mountain_2: { key: 'bike_rack_mountain_2', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    bike_rack_mountain_3: { key: 'bike_rack_mountain_3', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    bike_rack_mountain_4: { key: 'bike_rack_mountain_4', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    bike_rack_racing_2: { key: 'bike_rack_racing_2', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    bike_rack_racing_3: { key: 'bike_rack_racing_3', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    bike_rack_racing_4: { key: 'bike_rack_racing_4', group: EQUIPMENT_GROUP_BIKE_RACK, icon: 'bike_rack' },
    child_bike: { key: 'child_bike', group: EQUIPMENT_GROUP_BIKE, icon: 'child_bike', allowsQuantity: true },
    child_seat: { key: 'child_seat', icon: 'child_seat', allowsQuantity: true },
    city_bike: { key: 'city_bike', group: EQUIPMENT_GROUP_BIKE, icon: 'bike', allowsQuantity: true },
    coffee_machine: { key: 'coffee_machine', icon: 'coffee_machine' },
    cooler_box: { key: 'cooler_box', icon: 'cooler_box' },
    e_bike: { key: 'e_bike', group: EQUIPMENT_GROUP_BIKE, icon: 'e_bike', allowsQuantity: true },
    extra_mirrors: { key: 'extra_mirrors', icon: 'car_mirror' },
    foldable_bike: { key: 'foldable_bike', group: EQUIPMENT_GROUP_BIKE, icon: 'foldable_bike', allowsQuantity: true },
    grill: { key: 'grill', icon: 'grill' },
    hammock: { key: 'hammock', icon: 'hammock', allowsQuantity: true },
    kajak: { key: 'kajak', icon: 'kajak', allowsQuantity: true },
    mobile_toilet: { key: 'mobile_toilet', icon: 'mobile_toilet' },
    mountain_bike: { key: 'mountain_bike', group: EQUIPMENT_GROUP_BIKE, icon: 'mountain_bike', allowsQuantity: true },
    mover: { key: 'mover', icon: 'mover' },
    outdoor_furniture: { key: 'outdoor_furniture', icon: 'outdoor_furniture' },
    pavillion: { key: 'pavillion', icon: 'festival' },
    racing_bike: { key: 'racing_bike', group: EQUIPMENT_GROUP_BIKE, icon: 'racing_bike', allowsQuantity: true },
    roof_box: { key: 'roof_box', icon: 'roof_box' },
    roof_tent: { key: 'roof_tent', icon: 'roof_tent' },
    snow_chains: { key: 'snow_chains', icon: 'snow_chains' },
    sup: { key: 'sup', icon: 'sup', allowsQuantity: true },
    towels: { key: 'towels', icon: 'towels', allowsQuantity: true },
};

export const SERVICE_ADDON = {
    parking_place: { key: 'parking_place', icon: 'parking' },
    interior_cleaning: { key: 'interior_cleaning', icon: 'interior_cleaning', chargedOnlyPerBooking: true },
    toilet_cleaning: { key: 'toilet_cleaning', icon: 'toilet_cleaning', chargedOnlyPerBooking: true },
    pet_surcharge: { key: 'pet_surcharge', icon: 'pet_surcharge' },
    pick_up: { key: 'pick_up', icon: 'distance', chargedOnlyPerBooking: true },
    // early_checkin: { key: 'early_checkin', icon: 'early_checkin', chargedOnlyPerBooking: true },
    // late_checkout: { key: 'late_checkout', icon: 'late_checkout', chargedOnlyPerBooking: true },
};

/**
 * Product pricing strategies
 */
export const PRICING_STRATEGY_PER_BOOKING_NIGHT = 'per_booking_night';
export const PRICING_STRATEGY_PER_BOOKING = 'per_booking';

/**
 * Products price types
 */
export const PRICE_TYPE_FIXED = 'fixed';
export const PRICE_TYPE_INCLUDED = 'included';
