import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isDate from 'date-fns/isDate';

/**
 * Prints the given value as date/time with the given format
 * @param {number} value Value to format
 */
export function dateFormatFilter({ $utils }) {
    return (value, formatSpec) => {
        const date = isDate(value) ? value : parseISO(value);
        const dateFormat = formatSpec || $utils.defaultDateFormat();

        return format(date, dateFormat, { locale: $utils.getDateFnsLocale() });
    };
}

export function datetimeFormatFilter({ $utils }) {
    return value => {
        const date = isDate(value) ? value : parseISO(value);
        const dateFormat = `${$utils.defaultDateFormat()} HH:mm`;

        return format(date, dateFormat, { locale: $utils.getDateFnsLocale() });
    };
}

export function fullDateFormatFilter({ $utils, i18n }) {
    return value => {
        const date = isDate(value) ? value : parseISO(value);
        const dateFormat = i18n.locale === 'de' ? 'eee, d. MMM yyyy' : 'eee, dd MMM yyyy';

        return format(date, dateFormat, { locale: $utils.getDateFnsLocale() });
    };
}
