import qs from 'qs';

function setHeader(config, key, value) {
    if (!config.headers) {
        config.headers = {};
    }

    config.headers[key] = value;
}

export default function ({ $axios, i18n, store }) {
    $axios.onRequest(config => {
        $axios.setHeader('Accept-Language', i18n.localeProperties.iso);
        $axios.setHeader('Accept', 'application/json, text/plain, */*');

        if (!config.headers['Content-Type']) {
            setHeader(config, 'Content-Type', 'application/json');
        }

        const user = store.getters.user;

        // Check the condition and set the custom header if it's met
        if (process.client && user) {
            if (store.getters.isImpersonating) {
                setHeader(config, 'X-Impersonate-User', user.id);
            }

            if (store.getters.getAdminId) {
                setHeader(config, 'X-Impersonate-Admin-User', store.getters.getAdminId);
            }
        }

        config.paramsSerializer = params =>
            qs.stringify(params, {
                arrayFormat: 'brackets',
                // Skip only null and undefined, allow false and 0
                filter: (prefix, value) => (value === null ? undefined : value),
            });

        return config;
    });

    /**
     * When backend is in debugging mode, the backend API responses include some debugging data
     * to allow detection of performance issues.
     * These are logged to console here so dev's can pick them up.
     */
    $axios.onResponse(response => {
        /**
         * If the backend is in debug mode, it will include a `db_query_count` property
         * If this number is high, it's a sign that the backend is doing too much work.
         * The count is logged to console as an error to make it stand out for developers.
         *
         * @param {object} response
         */
        const logHighDbQueryCountAsError = response => {
            if (process.client && response.data && response.data.db_query_count) {
                const queryCount = response.data.db_query_count;
                const maxCount = 30;
                const { config } = response;
                let request = `${config.method.toUpperCase()} ${config.url}`;

                if (config.params) {
                    request = `${request}?${config.paramsSerializer(config.params)}`;
                }

                if (queryCount > maxCount) {
                    // eslint-disable-next-line no-console
                    console.warn(
                        `Database query limit exceeded (query count: ${queryCount}, limit: ${maxCount}, request: ${request})`
                    );
                }
            }
        };

        /**
         * N+1 queries is a backend issue where an additional DB query is issued for each
         * object that's fetched from the database before it's returned in the API.
         *
         * This causes performance issues and must be dealt with.
         *
         * @param {object} response
         */
        const logNplus1QueriesAsWarning = response => {
            if (process.client && response.data && response.data.warning_queries) {
                response.data.warning_queries.forEach(query => {
                    // eslint-disable-next-line no-console
                    console.warn(
                        `***** Detected N+1 Query *****\nModel: ${query.model}\nRelation: ${query.relation}\nCount: ${query.count}`,
                        query
                    );
                });
            }
        };

        logNplus1QueriesAsWarning(response);
        logHighDbQueryCountAsError(response);

        return response;
    });

    $axios.onError(error => {
        // For network errors, we do not even get a response
        if (!error.response) {
            return Promise.reject(error);
        }

        return Promise.reject(error.response.data);
    });
}
