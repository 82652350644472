let clickHandler;
let escapeKeyHandler;

const closable = {
    bind: (el, binding, vnode) => {
        clickHandler = e => {
            const { handler, exclude, openClass, disabled } = binding.value;

            if (disabled) {
                return;
            }

            // Makes sure that handler function is not called all the time but only when element is actually visible.
            // If element has v-if directive there is no need to send openClass
            if (openClass && !el.classList.contains(openClass)) {
                return;
            }

            // Ignore clicks on excluded elements
            let clickedOnExcludedEl = false;
            exclude.forEach(refName => {
                if (!clickedOnExcludedEl) {
                    const excludedEl = document.querySelector(refName);

                    if (excludedEl) {
                        clickedOnExcludedEl = excludedEl.contains(e.target);
                    }
                }
            });

            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                vnode.context[handler]();
            }
        };

        escapeKeyHandler = e => {
            const { handler, openClass, disabled } = binding.value;

            if (e.keyCode !== 27 || disabled) {
                return;
            }

            if (openClass && !el.classList.contains(openClass)) {
                return;
            }

            vnode.context[handler]();
        };

        document.addEventListener('mouseup', clickHandler);
        document.addEventListener('keyup', escapeKeyHandler);
    },
    unbind: () => {
        document.removeEventListener('mouseup', clickHandler);
        document.removeEventListener('keyup', escapeKeyHandler);
    },
};

export default closable;
