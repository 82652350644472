import { Cashify } from 'cashify';

/**
 * Formats the given price integer for printout
 * @param {number} value Value to format
 */
export function priceFilter(value) {
    const valueNum = parseInt(value, 10);

    if (Number.isNaN(valueNum)) {
        return '';
    }

    return value / 100;
}

/**
 * Returns the given number rounded
 * @param {number} value Value to round
 */
export function roundedFilter(value, decimals = 0) {
    if (typeof value !== 'number' || Number.isNaN(value)) {
        return '';
    }

    const factor = 10 ** decimals;

    return Math.round(value * factor) / factor;
}

/**
 *
 * @param {number} value Price to format
 * @param {string} valueCurrency Currency for the given value
 * @param {number} fractionDigits Optional, number of decimals
 * @param {boolean} shouldConvert Optional, whether to convert to the userCurrency
 * @returns string
 */
export function formatAsPrice(
    value = 0,
    valueCurrency = 'sek',
    fractionDigits = 2,
    shouldConvert = true,
    locale,
    baseCurrency,
    exchangeRates
) {
    let convertedValue = value;
    let currency = valueCurrency;

    if (baseCurrency !== valueCurrency && shouldConvert && exchangeRates) {
        const cashify = new Cashify(exchangeRates);
        convertedValue = cashify.convert(value, {
            from: valueCurrency,
            to: baseCurrency,
        });
        currency = baseCurrency;
    }

    return Intl.NumberFormat(Intl.NumberFormat.supportedLocalesOf(locale), {
        style: 'currency',
        currencyDisplay: 'code',
        currency,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(convertedValue);
}

export function formatAsNumber(
    value = 0,
    fractionDigits = 2,
    locale,
    trailingZeroDisplay = 'auto',
    useGrouping = 'min2'
) {
    return Intl.NumberFormat(Intl.NumberFormat.supportedLocalesOf(locale), {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
        trailingZeroDisplay,
        useGrouping,
    }).format(value);
}

export function formatAsPriceFilter({ i18n, store }) {
    const locale = i18n.localeProperties.iso;

    return (value, valueCurrency = 'sek', fractionDigits = 2, shouldConvert = true) =>
        formatAsPrice(
            value,
            valueCurrency,
            fractionDigits,
            shouldConvert,
            locale,
            store.getters.userCurrency,
            store.getters.exchangeRates
        );
}

export function formatAsNumberFilter({ i18n }) {
    const locale = i18n.localeProperties.iso;

    return (value, fractionDigits = 2, trailingZeroDisplay, useGrouping) =>
        formatAsNumber(value, fractionDigits, locale, trailingZeroDisplay, useGrouping);
}
