<template>
    <div class="vehicle-list">
        <template v-if="loadingCount">
            <VehicleCard
                v-for="i in loaders"
                :key="'loading-' + i"
                v-near-viewport-fold-detection="() => setNearViewportFoldCards(i)"
                :loading="true"
                class="vehicle-list__item"
            />
        </template>
        <template v-else>
            <div v-for="(vehicle, index) in vehicles" :key="vehicle.id" class="vehicle-list__item">
                <slot :name="`title-slot-${index}`" />
                <VehicleCard
                    v-near-viewport-fold-detection="() => setNearViewportFoldCards(index)"
                    :vehicle="vehicle"
                    :show-matched-dates="showMatchedDates"
                    :image-count="imageCount"
                    :index-in-list="index"
                    :origin="listName"
                    :lazy-load-card-images="true"
                    :is-near-viewport-fold="nearViewportFoldCards.includes(index)"
                />
            </div>
        </template>
    </div>
</template>

<script>
import VehicleCard from '~/components/vehicles/VehicleCard.vue';

export default {
    components: {
        VehicleCard,
    },
    props: {
        loadingCount: {
            type: Number,
            default: null,
        },
        vehicles: {
            type: Array,
            default: () => [],
        },
        showMatchedDates: {
            type: Boolean,
            default: false,
        },
        imageCount: {
            type: Number,
            default: -1,
        },
        listName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            nearViewportFoldCards: [],
        };
    },
    computed: {
        loaders() {
            return [...Array(this.loadingCount).keys()];
        },
    },
    methods: {
        setNearViewportFoldCards(index) {
            if (!this.nearViewportFoldCards.includes(index)) {
                this.nearViewportFoldCards.push(index);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.vehicle-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &__item {
        margin: 10px;
    }

    &.vehicles__loading {
        justify-content: flex-start;
    }
}
</style>
