import { actionTypes as myVehiclesActions } from '~/store/myVehicles';
import { actionTypes as userActions } from '~/store/user';
import { actionTypes as localizationActions } from '~/store/localization';
import { actionTypes as configActions } from '~/store/config';
import pollUserStatusPlugin from '~/store/plugins/pollUserStatusPlugin';
import clearStateOnLogoutPlugin from '~/store/plugins/clearStateOnLogoutPlugin';
import localStorageSync from '~/store/plugins/localStorageSync';

export const plugins = [clearStateOnLogoutPlugin, pollUserStatusPlugin, localStorageSync];

export const actions = {
    async nuxtServerInit({ dispatch, getters }, context) {
        const localeCookie = context.app.$cookies.get('locale');

        // If there's no 'locale' cookie, determine the country based on timezone.
        if (!localeCookie) {
            const country = context.$meService.getUserCountryBasedOnTimezone();
            const determinedLocale = country?.locale || null;

            if (determinedLocale && context.i18n.localeCodes.includes(determinedLocale)) {
                context.app.$cookies.set('locale', determinedLocale);
                context.i18n.setLocale(determinedLocale);
            }
        }

        dispatch(localizationActions.SWITCH_LOCALE, context.i18n);
        dispatch(configActions.FETCH_USER_REVIEWS, context.i18n);
        dispatch(configActions.FETCH_PLATFORM_VEHICLES_COUNT, context.i18n);

        if (this.$auth.loggedIn) {
            await dispatch(userActions.FETCH_ME);

            if (getters.isOwner) {
                await dispatch(myVehiclesActions.FETCH_VEHICLES);
            }
        }
    },
};
