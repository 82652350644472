<template>
    <div v-if="vehicle" class="vehicle-location vehicle-location__wrapper">
        <template v-if="withType">
            <VehicleField
                type="value"
                class="vehicle-location__type"
                :vehicle="vehicle"
                field="subType"
                :is-link="true"
            />
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span>&nbsp;{{ $t('common.in') }}&nbsp;</span>
        </template>
        <a v-if="vehicle.locationPage" :href="vehicle.locationPage.url" class="vehicle-location__link">
            <span v-if="vehicle.city" class="vehicle-location__city">{{ vehicle.city }}</span>
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span v-if="vehicle.city && vehicle.country">,&nbsp;</span>
            <span v-if="vehicle.country">{{ vehicle.country }}</span>
        </a>
        <template v-else>
            <span v-if="vehicle.city" class="vehicle-location__city">{{ vehicle.city }}</span>
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span v-if="vehicle.city && vehicle.country">,&nbsp;</span>
            <span v-if="vehicle.country">{{ vehicle.country }}</span>

            <i18n
                v-if="vehicle.hasOwnProperty('distance')"
                tag="span"
                class="vehicle-location__distance"
                path="vehicle_card.distance"
                >{{ vehicle.distance | rounded }}</i18n
            >
        </template>
    </div>
</template>

<script>
import VehicleField from '~/components/vehicle/VehicleField';
export default {
    name: 'VehicleLocation',
    components: { VehicleField },
    props: {
        vehicle: {
            type: Object,
            required: true,
        },
        withType: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/sass/_variables.scss';

.vehicle-location {
    &__type {
        list-style: none;
    }

    &__wrapper {
        display: flex;
        overflow: hidden;

        span {
            font-weight: bold;
        }
    }

    ::v-deep a,
    a {
        text-decoration: none;
        font-weight: bold;
        color: $primary-text-color;

        &:hover,
        &:active {
            text-decoration: underline;
        }
    }

    &__link {
        display: flex;
        overflow: hidden;
    }

    &__distance {
        padding-left: 4px;
        white-space: nowrap;
    }

    &__city {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
