/* eslint-disable no-param-reassign, no-shadow */

import VehicleService from '~/services/VehicleService';
import { OMOCOM } from '~/config/insurance';

export const mutationTypes = {
    VEHICLES_SET: 'MY_VEHICLES/VEHICLES_SET',
    VEHICLES_UNSET: 'MY_VEHICLES/VEHICLES_UNSET',
};

export const actionTypes = {
    CLEAR: 'MY_VEHICLES/CLEAR',
    FETCH_VEHICLES: 'MY_VEHICLES/FETCH_VEHICLES',
    CREATE_VEHICLE: 'MY_VEHICLES/CREATE_VEHICLE',
    DELETE_VEHICLE: 'MY_VEHICLES/DELETE_VEHICLE',
};

export const state = () => ({
    myVehicles: null,
});

const isInfoEntered = (vehicle, subview) => {
    if (!vehicle) {
        return false;
    }

    return vehicle.infoStatuses[subview];
};

export const getters = {
    myVehicles: state => state.myVehicles,
    myActiveVehicles: state => {
        if (!Array.isArray(state.myVehicles)) {
            return [];
        }

        return state.myVehicles.filter(vehicle => vehicle.isActive || vehicle.activationCount > 0);
    },
    myFirstVehicle: state => {
        if (!Array.isArray(state.myVehicles) || state.myVehicles.length === 0) {
            return null;
        }

        return state.myVehicles[0];
    },
    hasAnyVehicleWithOmocomInsurance(state) {
        if (!Array.isArray(state.myVehicles)) {
            return false;
        }

        return state.myVehicles.some(
            vehicle => vehicle.hasPlatformInsurance && vehicle.insuranceVendor?.name?.toLowerCase() === OMOCOM
        );
    },
    isFirstVehicleComplete(_, getters) {
        return getters.myFirstVehicle && getters.myFirstVehicle.isCompleted;
    },
    isFirstVehicleActive(_, getters) {
        return getters.myFirstVehicle && getters.myFirstVehicle.isActive;
    },
    hasAnyActiveVehicle(state) {
        if (!Array.isArray(state.myVehicles) || state.myVehicles.length === 0) {
            return false;
        }

        return state.myVehicles.some(v => v.isActive);
    },
    hasAnyInactiveVehicle(state) {
        if (!Array.isArray(state.myVehicles) || state.myVehicles.length === 0) {
            return false;
        }

        return state.myVehicles.some(v => !v.isActive);
    },
    hasActivatedAnyVehicleAtSomePoint(state) {
        if (!Array.isArray(state.myVehicles) || state.myVehicles.length === 0) {
            return false;
        }

        return state.myVehicles.some(v => v.activationCount > 0);
    },
    firstVehicleIncompleteSubviews(state, getters) {
        if (!state.myVehicles) {
            return [];
        }

        return Object.keys(getters.isFirstVehicleSubviewComplete).filter(
            subview => !getters.isFirstVehicleSubviewComplete[subview]
        );
    },
    isFirstVehicleSubviewComplete: (_, getters) => ({
        basic: isInfoEntered(getters.myFirstVehicle, 'basic'),
        location: isInfoEntered(getters.myFirstVehicle, 'location'),
        insurance: isInfoEntered(getters.myFirstVehicle, 'insurance'),
        equipment: isInfoEntered(getters.myFirstVehicle, 'equipment'),
        checkinCheckout: isInfoEntered(getters.myFirstVehicle, 'checkinCheckout'),
        cancellation: isInfoEntered(getters.myFirstVehicle, 'cancellation'),
        rules: isInfoEntered(getters.myFirstVehicle, 'rules'),
        images: isInfoEntered(getters.myFirstVehicle, 'images'),
        pricing: isInfoEntered(getters.myFirstVehicle, 'pricing'),
        availability: isInfoEntered(getters.myFirstVehicle, 'availability'),
        description: isInfoEntered(getters.myFirstVehicle, 'description'),
        activation: isInfoEntered(getters.myFirstVehicle, 'activation'),
    }),
};

export const mutations = {
    [mutationTypes.VEHICLES_SET](state, payload) {
        state.myVehicles = payload;
    },
    [mutationTypes.VEHICLES_UNSET](state) {
        state.myVehicles = null;
    },
};

export const actions = {
    [actionTypes.FETCH_VEHICLES]({ commit }) {
        return new VehicleService(this).getMyVehicles().then(vehicles => {
            commit(mutationTypes.VEHICLES_SET, vehicles);
        });
    },
    [actionTypes.CLEAR]({ commit }) {
        commit(mutationTypes.VEHICLES_UNSET);
    },
    [actionTypes.CREATE_VEHICLE]({ dispatch }, data) {
        return new VehicleService(this).createVehicle(data).then(vehicle => {
            return dispatch(actionTypes.FETCH_VEHICLES).then(() => {
                return vehicle;
            });
        });
    },
    [actionTypes.DELETE_VEHICLE]({ dispatch }, id) {
        return new VehicleService(this).deleteVehicle(id).then(vehicle => {
            return dispatch(actionTypes.FETCH_VEHICLES).then(() => {
                return vehicle;
            });
        });
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
    mutationTypes,
};
