<template>
    <div :id="uid" :class="imageCarouselClasses">
        <div v-if="fullscreen" class="image-carousel__header">
            <button class="button button--secondary-contrast button--small" @click.prevent="$emit('toggle-carousel')">
                <svg-icon name="close" />
            </button>
        </div>
        <div
            v-if="type === 'tiny-slider'"
            :class="carouselWrapperClasses"
            @beforeChange="handleSlideChange"
            @mouseenter="showControls = true"
            @mouseleave="showControls = false"
        >
            <div v-if="images.length > 0" ref="slider" class="image-carousel__slider">
                <div v-for="(image, index) in images" :key="index" @click="$emit('slide-click', index)">
                    <template v-if="image.srcFull">
                        <a :href="image.srcFull" target="_blank">
                            <img v-if="!autoHeight" :src="image.src" :alt="image.alt" class="tns-lazy-img" />
                            <img v-if="autoHeight" :src="image.src" :alt="image.alt" />
                        </a>
                    </template>
                    <template v-else>
                        <img v-if="!autoHeight" :src="image.src" :alt="image.alt" class="tns-lazy-img" />
                        <img v-if="autoHeight" :src="image.src" :alt="image.alt" />
                    </template>
                </div>
            </div>
            <div class="image-carousel__overlay" />

            <LoadingIndicator v-if="images.length > 0" class="image-carousel__loading" />

            <button ref="buttonPrev" class="slider-button slider-prev-button js-slider-control">
                <svg-icon name="keyboard_arrow_left" @click.prevent />
            </button>
            <button ref="buttonNext" class="slider-button slider-next-button js-slider-control">
                <svg-icon name="keyboard_arrow_right" @click.prevent />
            </button>
        </div>
        <!-- eslint-disable-next-line vue/valid-template-root False positive-->
        <div v-else-if="type === 'slick'" :class="carouselWrapperClasses">
            <VueSlickCarousel
                v-if="images.length > 0"
                v-bind="slickSettings"
                :ref="`imageCarousel_${indexOfClickedImage}`"
                class="image-carousel__slider"
                @beforeChange="handleSlideChange"
                @afterChange="setCarouselSwiping"
            >
                <div
                    v-for="(image, index) in images"
                    :key="index"
                    class="image-carousel__slide"
                    @click="$emit('slide-click', index)"
                >
                    <picture>
                        <template v-if="image.srcset">
                            <source
                                v-if="image.webpSrcset"
                                :srcset="image.webpSrcset"
                                :alt="image.alt"
                                :sizes="sizes"
                                type="image/webp"
                            />
                            <source :srcset="image.srcset" :alt="image.alt" :sizes="sizes" type="image/jpeg" />

                            <img :srcset="image.srcset" :src="image.src" :alt="image.alt" :sizes="sizes" />
                        </template>
                        <template v-else>
                            <source v-if="image.webpSrc" :srcset="image.webpSrc" type="image/webp" />
                            <source :srcset="image.src" type="image/jpeg" />

                            <img :src="image.src" :alt="image.alt" />
                        </template>
                    </picture>
                </div>
                <template #prevArrow>
                    <div class="slider-button slider-prev-button js-slider-control">
                        <svg-icon name="keyboard_arrow_left" @click.prevent="$emit('arrow-click')" />
                    </div>
                </template>
                <template #nextArrow>
                    <div class="slider-button slider-next-button js-slider-control">
                        <svg-icon name="keyboard_arrow_right" @click.prevent="$emit('arrow-click')" />
                    </div>
                </template>
            </VueSlickCarousel>
        </div>

        <div v-if="fullscreen" class="image-carousel__footer">
            <div class="image-carousel__progress">
                {{ carouselProgress }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueSlickCarousel from 'vue-slick-carousel';
import LoadingIndicator from '~/components/LoadingIndicator.vue';

export default {
    components: {
        LoadingIndicator,
        VueSlickCarousel,
    },
    props: {
        type: {
            type: String,
            default: 'slick',
        },
        images: {
            type: Array,
            default: () => [],
        },
        sizes: {
            type: String,
            default: null,
        },
        // Note: Setting this to true disables lazyload
        autoHeight: {
            type: Boolean,
            default: false,
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
        indexOfClickedImage: {
            type: Number,
            default: 0,
        },
        useKeyboard: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const slickSettings = {
            dots: true,
            adaptiveHeight: this.autoHeight,
            initialSlide: 0,
            cssEase: 'ease-out',
            speed: 300,
            swipe: this.images?.length > 1,
        };

        if (!this.autoHeight) {
            slickSettings.lazyLoad = 'progressive';
        }

        return {
            slider: null,
            slickSettings,
            activeSlide: 1,
            activeSlideIdx: 0,
        };
    },
    computed: {
        ...mapGetters(['isLargeScreen']),
        imageCarouselClasses() {
            return {
                'image-carousel': true,
                'image-carousel--fullscreen': this.fullscreen,
            };
        },
        carouselWrapperClasses() {
            return {
                'image-carousel__wrapper': true,
                'image-carousel__wrapper--fixed-height': !this.autoHeight,
            };
        },
        uid() {
            return Math.random().toString(36).substr(2, 9);
        },
        carouselProgress() {
            return `${this.activeSlide} / ${this.images.length}`;
        },
    },
    created() {
        if (this.fullscreen) {
            this.slickSettings.dots = false;
            this.slickSettings.initialSlide = this.indexOfClickedImage;
            this.activeSlide = this.indexOfClickedImage + 1;
        }
    },
    async mounted() {
        if (this.fullscreen) {
            const bodyScrollLock = await import('body-scroll-lock');
            this.disableBodyScroll = bodyScrollLock.disableBodyScroll;
            this.clearAllBodyScrollLocks = bodyScrollLock.clearAllBodyScrollLocks;

            this.disableBodyScroll(document.getElementById(this.uid));
        }

        if (this.type === 'slick') {
            this.setStylingForDots();
        }

        if (this.type === 'tiny-slider') {
            this.initTinySlider();
        }

        if (window.innerWidth > 991 && this.useKeyboard) {
            window.addEventListener('keyup', this.changeSlideWithKeys, { passive: true });
        }
    },
    beforeDestroy() {
        if (window.innerWidth > 991 && this.useKeyboard) {
            window.removeEventListener('keyup', this.changeSlideWithKeys);
        }

        if (this.fullscreen) {
            this.clearAllBodyScrollLocks();
        }
    },
    methods: {
        initTinySlider() {
            if (this.images.length === 0) {
                return;
            }

            const settings = {
                container: this.$refs.slider,
                items: 1,
                nav: true,
                rewind: true,
                mouseDrag: true,
                lazyload: !this.autoHeight,
                autoHeight: this.autoHeight,
                prevButton: this.$refs.buttonPrev,
                nextButton: this.$refs.buttonNext,
            };

            this.slider = this.$tinySlider(settings);
        },
        setStylingForDots() {
            const dots =
                this.$refs[`imageCarousel_${this.indexOfClickedImage}`]?.$el?.querySelectorAll('.slick-dots li');
            const dotsLength = dots?.length;

            if (!dots || dotsLength <= 5 || !dotsLength) {
                return;
            }

            for (let i = 0; i < dotsLength; i++) {
                dots[i].style.transform = 'scale(1)';
                this.calculateDotLeftPosition(dots[i], dotsLength);
            }

            if (this.activeSlideIdx < 2) {
                this.scaleDot(dots[3], 0.8);
                this.scaleDot(dots[4], 0.65);
            } else if (this.activeSlideIdx > dotsLength - 3) {
                this.scaleDot(dots[dotsLength - 4], 0.8);
                this.scaleDot(dots[dotsLength - 5], 0.65);
            } else {
                this.scaleDot(dots[this.activeSlideIdx - 2], 0.8);
                this.scaleDot(dots[this.activeSlideIdx + 2], 0.8);
            }
        },
        scaleDot(dot, scale) {
            dot.style.transform = `scale(${scale})`;
        },
        calculateDotLeftPosition(dot, length) {
            let value;

            if (this.activeSlideIdx < 3) {
                value = 0;
            } else if (this.activeSlideIdx >= length - 2) {
                value = -(length - 5) * 16;
            } else {
                value = -(this.activeSlideIdx - 2) * 16;
            }

            dot.style.left = `${value}px`;
        },
        setCarouselSwiping() {
            if (!this.isLargeScreen) {
                if (this.$refs[`imageCarousel_${this.indexOfClickedImage}`]) {
                    this.$refs[`imageCarousel_${this.indexOfClickedImage}`].$refs.innerSlider.clickable = true;
                }
            }
        },
        handleSlideChange(oldIndex, newIndex) {
            this.activeSlide = newIndex + 1;
            this.activeSlideIdx = newIndex;
            this.$emit('slide-change');

            if (this.type === 'slick') {
                this.setStylingForDots();
            }
        },
        changeSlideWithKeys(e) {
            // Left arrow key (37); Right arrow key (39)
            if (e.keyCode === 37) {
                this.$refs[`imageCarousel_${this.indexOfClickedImage}`].prev();
            }

            if (e.keyCode === 39) {
                this.$refs[`imageCarousel_${this.indexOfClickedImage}`].next();
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';
@import '@/sass/lib/tiny-slider.scss';
$header-height: 94px;
$footer-height: 72px;

.slider-button {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    background: none;
    border: none;
    outline: none;
    width: 15%;
    padding: 0;
    text-align: center;
    z-index: 1;

    @include md {
        display: flex;
        align-items: center;
    }

    .slick-slider & {
        height: 100%;
        transform: translate(0, 0);
    }

    .svg-icon {
        color: $white;
        width: 48px;
        height: 48px;
        max-width: 100%; // Avoids having the arrows point out outside the container when the carousel is narrow
        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
    }

    &.slider-prev-button {
        left: 0;
        right: auto;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &.slider-next-button {
        right: 0;
        left: auto;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    }
}

.tns-nav {
    position: absolute;
    bottom: 15px;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);

    button {
        display: inline-block;
        opacity: 0.4;
        background: $white;
        border: none;
        border-radius: 100%;
        padding: 0;
        width: 6px;
        height: 6px;
        margin: 4px;
        vertical-align: middle;
        transition: all 0.2s;

        &.tns-nav-active {
            width: 10px;
            height: 10px;
            opacity: 1;
        }
    }
}

.slick-dots {
    position: absolute;
    bottom: 15px;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    display: flex !important;
    max-width: 80px;
    overflow: hidden;

    li {
        position: relative;
        display: inline-block;
        opacity: 0.4;
        background: $white;
        border: none;
        border-radius: 100%;
        padding: 0;
        width: 8px;
        height: 8px;
        margin: 4px;
        vertical-align: middle;
        transition: all 0.2s;

        button {
            opacity: 0;
        }

        &.slick-active {
            opacity: 1;
        }
    }
}

.image-carousel {
    position: relative;
    text-align: center;
    overflow: hidden;
    height: 100%;

    &__overlay {
        height: 50px;
        width: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 80%, rgba(0, 0, 0, 0.4) 100%);
        z-index: 1;
        position: absolute;
        bottom: 0;
    }

    .slider-button {
        opacity: 0;
        transition: opacity 0.25s ease-in-out;

        &:before {
            content: '';
        }
    }

    &:hover .slider-button {
        opacity: 1;
    }

    &__slider {
        position: relative;
        z-index: 1; // To put it above the loading indicator
    }

    &__slide {
        background: #222;
    }

    .slick-slider {
        img {
            // Workaround for Slick slider images not being clickable or "scrollable" when on mobile and allowing swipe
            // "scrollable" = You couldnt scroll the page by starting dragging on the image
            // https://github.com/gs-shop/vue-slick-carousel/issues/98
            // https://github.com/gs-shop/vue-slick-carousel/issues/108
            pointer-events: none;
            @media (hover: hover) {
                pointer-events: auto;
            }
        }
    }

    &__wrapper {
        // Setting heights to 100% to be able to specify the carousel height in outer components
        // The "fixed-height" modifier is set if the autoHeight prop is false
        &--fixed-height {
            height: 100%;

            .image-carousel__slider,
            .tns-outer,
            .tns-inner,
            .tns-item,
            .tns-ovh,
            .slick-list,
            .slick-list div {
                height: 100%;
            }
        }
    }

    &--fullscreen {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-modal + 1;
        background: white;

        .image-carousel {
            &__slider {
                img,
                source {
                    object-fit: contain;
                }
            }

            &__slide {
                background: $white;
            }

            &__header {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 2rem 1rem 1rem;

                button {
                    .svg-icon {
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            &__wrapper {
                &--fixed-height {
                    height: calc(100% - ($header-height + $footer-height));
                }
            }

            &__footer {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $white;
                padding: 1rem 1rem 2rem;
            }

            // landscape mobile
            @media screen and (max-height: $bp-sm-min) and (orientation: landscape) {
                &__header {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: 2;
                    background: none;
                }

                &__wrapper {
                    height: 100%;
                }

                &__footer {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 2;
                    background: none;
                }

                &__header button,
                &__progress {
                    background: rgba(255, 255, 255, 0.5);
                    backdrop-filter: blur(18px);
                    border-radius: $default-border-radius;
                }

                &__progress {
                    padding: 1rem;
                }

                &__slider {
                    height: 100%;
                    top: 0;
                    background-color: $white;
                    img,
                    source {
                        width: 100vw;
                        object-fit: cover;
                    }
                }
            }

            // desktop (orientation to avoid messing height up with phones in landscape mode)
            @media screen and (min-width: $bp-md-min) and (orientation: portrait) {
                &__slider {
                    height: calc(100% - $header-height);
                }

                &__header {
                    position: static;
                    background: $white;
                    z-index: initial;
                }
            }

            // separated to fix arrows overlapping on certain screen sizes
            @media screen and (min-width: $bp-md-min) {
                &__slider {
                    img,
                    source {
                        max-width: 75%; // don't cover arrows
                    }
                }
            }
        }

        .slider-button {
            display: none;

            .svg-icon {
                color: $darkest-gray;
                filter: none;
            }

            @include md {
                display: flex;
                opacity: 1;
                width: fit-content;
            }
        }

        .slider-prev-button,
        .slider-next-button {
            background: transparent;
        }

        .slider-prev-button {
            left: 16px;
        }

        .slider-next-button {
            right: 16px;
        }
    }

    img {
        // important is needed because Google Maps will override the rules otherwise
        height: 100% !important;
        object-fit: cover;
        object-position: center;
        margin: auto;
        width: 100%;
    }

    &__loading {
        position: absolute;
        top: 50px;
        width: 100%; // The content is centered inside the loading indicator
    }
}
</style>
