<template>
    <div v-if="!shouldHide" class="sticky-support-button">
        <div v-if="showInfoBox" class="sticky-support-button__info-boxes">
            <h3 class="info-boxes__heading">{{ $t('support.heading') }}</h3>
            <p class="info-boxes__description">{{ $t('support.booking_id_note') }}</p>
            <div class="info-box">
                <a class="info-box__link" :href="'tel:' + supportPhoneConfig.supportPhoneNormalized">
                    <svg-icon class="info-box__icon" name="phone" />{{ supportPhoneConfig.supportPhone }}
                </a>
            </div>
            <div class="info-box">
                <a class="info-box__link" :href="'mailto:' + $store.getters.supportEmail">
                    <svg-icon class="info-box__icon" name="mail" />{{ $store.getters.supportEmail }}
                </a>
            </div>
            <div class="info-box">
                <a :href="$t('links.faq')" target="_blank" class="contact-box__link">
                    <svg-icon class="info-box__icon" name="info" />{{ $t('support.see_faq') }}
                </a>
            </div>

            <a
                v-if="$te('support.opening_hours')"
                :href="$t('links.mycamper_opening_hours')"
                target="_blank"
                class="sticky-support-button__opening-hours"
            >
                {{ $t('support.opening_hours') }}
            </a>
        </div>
        <div class="sticky-support-button__button" @click="showInfoBox = !showInfoBox">
            <svg-icon v-if="!showInfoBox" name="floating_support" class="sticky-support-button__icon" />
            <svg-icon v-if="showInfoBox" name="close" class="sticky-support-button__icon" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            showInfoBox: false,
        };
    },
    computed: {
        ...mapGetters(['supportPhonePerLocale', 'supportPhoneNormalizedPerLocale']),
        shouldHide() {
            return this.getRouteBaseName() === 'messages';
        },
        supportPhoneConfig() {
            return {
                supportPhone: this.supportPhonePerLocale(this.$i18n.locale),
                supportPhoneNormalized: this.supportPhoneNormalizedPerLocale(this.$i18n.locale),
            };
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';
.sticky-support-button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    z-index: $z-sticky-support-button;
    cursor: pointer;

    @include print {
        display: none;
    }

    // Note: This is positioned and sized this way (icon w & h) so it will
    // disappear behind the VehicleMobileFooter in mobile
    right: 10px;
    bottom: 10px;

    &__icon.svg-icon {
        height: 30px;
        width: 30px;
    }

    &__button {
        background: $blue;
        color: $white;
        padding: 5px;
        border-radius: 500px;
    }

    @include lg {
        right: 20px;
        bottom: 20px;

        &__button {
            padding: 10px;
        }

        &__icon.svg-icon {
            height: 50px;
            width: 50px;
        }
    }

    &__opening-hours {
        display: block;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    &__info-boxes {
        @extend %card;
        padding: 10px;
        margin-bottom: 10px;

        .info-boxes__heading {
            text-align: center;
            padding: 10px;
            @extend %heading-3;
        }

        .info-boxes__description {
            max-width: 260px;
            @include font-size(14px);
        }

        .info-box {
            padding: 10px;
            border-bottom: 1px solid $gray;

            &:last-child {
                border: none;
            }

            &__icon.svg-icon {
                height: 24px;
                width: 24px;
                margin-right: 10px;
            }
        }
    }
}
</style>
