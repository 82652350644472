/* eslint-disable no-param-reassign, no-shadow */

import MeService from '~/services/MeService';

export const mutationTypes = {
    USER_STATUS_SET: 'USER_STATUS/SET',
    USER_STATUS_UNSET: 'USER_STATUS/UNSET',
};

export const actionTypes = {
    FETCH: 'USER_STATUS/FETCH',
    CLEAR: 'USER_STATUS/CLEAR',
};

export const initialState = {
    unreadConversations: [],
    actionableBookings: 0,
    assetUpdatedAt: null,
    assetId: null,
};

export const state = () => ({
    userStatus: { ...initialState },
});

export const getters = {
    unreadConversations: state => state.userStatus.unreadConversations,
    unreadConversationCount: state => state.userStatus.unreadConversations.length,
    actionableBookings: state => state.userStatus.actionableBookings,
    assetUpdatedAt: state => state.userStatus.assetUpdatedAt,
    statusAssetId: state => state.userStatus.assetId,
};

export const mutations = {
    [mutationTypes.USER_STATUS_SET](state, userStatus) {
        state.userStatus = { ...userStatus };
    },
    [mutationTypes.USER_STATUS_UNSET](state) {
        state.userStatus = { ...initialState };
    },
};

export const actions = {
    [actionTypes.FETCH]({ commit, rootState }) {
        let assetId;

        if (rootState.editVehicle.currentVehicle) {
            assetId = rootState.editVehicle.currentVehicle.id;
        }

        return new MeService(this).getStatus(assetId).then(userStatus => {
            commit(mutationTypes.USER_STATUS_SET, userStatus);
        });
    },
    [actionTypes.CLEAR]({ commit }) {
        commit(mutationTypes.USER_STATUS_UNSET);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
    mutationTypes,
};
