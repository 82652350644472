export default class DepositService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    get(depositId) {
        const config = {
            method: 'get',
            url: `/deposits/${depositId}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
