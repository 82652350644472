/**
 * Asset type and sub-type constants
 */

export const ASSET_TYPE_VEHICLE = 'vehicle';

export const ASSET_SUB_TYPE_MOTORHOME = 'motorhome';
export const ASSET_SUB_TYPE_CAMPERVAN = 'campervan';
export const ASSET_SUB_TYPE_CARAVAN = 'caravan';
export const ASSET_SUB_TYPE_OTHER_CAMPER = 'other_camper';
