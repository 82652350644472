export default class UserService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    getStats(userId) {
        const config = {
            method: 'get',
            url: `/users/${userId}/stats`,
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
