/* eslint-disable no-param-reassign, no-shadow */

export const mutationTypes = {
    SET_CURRENT_PAGE: 'CMS/SET_CURRENT_PAGE',
    SET_LOADING: 'CMS/SET_LOADING',
    CLEAR_STATE: 'CMS/CLEAR_STATE',
};

export const actionTypes = {
    FETCH_PAGE: 'CMS/FETCH_PAGE',
    SET_LOADING: 'CMS/SET_LOADING',
    CLEAR: 'CMS/CLEAR',
};

const initialState = () => ({
    page: null,
    isLoading: false,
});

export const state = initialState;

export const getters = {
    cmsPage: state => state.page,
    currentCmsPath: state => (state.page ? state.page.path : null),
    isLoadingCmsPage: state => !!state.isLoading,
    getCmsPageStructuredData: state => baseUrl => {
        const page = state.page;

        if (!page) {
            return null;
        }

        const structuredData = [];

        /**
         * FAQPage
         */
        if (page.faq && page.faq.length > 0) {
            const faqData = { '@context': 'https://schema.org', '@type': 'FAQPage', mainEntity: [] };

            page.faq.forEach(faq => {
                faqData.mainEntity.push({
                    '@type': 'Question',
                    name: faq.question,
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: faq.answer,
                    },
                });
            });

            structuredData.push(faqData);
        }

        /**
         * BreadcrumbList
         */
        if (page.breadcrumbTitle || (page.parent && page.parent.breadcrumbTitle)) {
            const breadcrumbData = {
                '@context': 'https://schema.org/',
                '@type': 'BreadcrumbList',
                itemListElement: [],
            };

            let position = 1;

            if (page.parent) {
                breadcrumbData.itemListElement.push({
                    '@type': 'ListItem',
                    position,
                    name: page.parent.breadcrumbTitle,
                    item: `${baseUrl}${page.parent.path}`,
                });

                position += 1;
            }

            breadcrumbData.itemListElement.push({
                '@type': 'ListItem',
                position,
                name: page.breadcrumbTitle,
                item: `${baseUrl}${page.path}`,
            });

            structuredData.push(breadcrumbData);
        }

        if (structuredData.length === 0) {
            return null;
        }

        return structuredData;
    },
};

export const mutations = {
    [mutationTypes.SET_CURRENT_PAGE](state, page) {
        state.page = page;
    },
    [mutationTypes.SET_LOADING](state, loading = true) {
        state.isLoading = loading;
    },
    [mutationTypes.CLEAR_STATE](state) {
        Object.assign(state, initialState());
    },
};

export const actions = {
    [actionTypes.SET_LOADING]({ commit }, loading = true) {
        commit(mutationTypes.SET_LOADING, loading);
    },
    [actionTypes.FETCH_PAGE]({ commit }, path) {
        const fixedPath = path
            .replace(/%EF%BB%BF/gm, '')
            .replace(/\uFEFF/gm, '')
            .replace(/\u00BB\u00BF/gm, '')
            .trim();

        return this.$pageService.getPageWithPath(fixedPath, this.app.i18n.localeProperties.iso).then(page => {
            commit(mutationTypes.SET_CURRENT_PAGE, page);

            return page;
        });
    },
    [actionTypes.CLEAR]({ commit }) {
        commit(mutationTypes.CLEAR_STATE);
    },
};

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
};
