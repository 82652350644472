import { setupVehicleFields } from '~/config/vehicleFields';
import { actionTypes as localizationActions } from '~/store/localization';

export default function (context) {
    // onLanguageSwitched called right after a new locale has been set
    context.i18n.onLanguageSwitched = async (_oldLocale, newLocale) => {
        setupVehicleFields(context);
        context.$cookies.set('locale', newLocale);

        await context.store.dispatch(localizationActions.SWITCH_LOCALE, context.i18n);
    };
}
