import { actionTypes as editVehicleActions } from '~/store/editVehicle';
import { actionTypes as userActions } from '~/store/user';
import { actionTypes as userStatusActions } from '~/store/userStatus';
import { actionTypes as myVehiclesActions } from '~/store/myVehicles';
import { actionTypes as cartActions } from '~/store/cart';
import { actionTypes as cmsActions } from '~/store/cms';
import { actionTypes as impersonateActions } from '~/store/impersonate';
import { actionTypes as filterActions } from '~/store/filters';

/**
 * Clear state that needs to be cleared when the user logs out
 */
export default function clearStateOnLogoutPlugin(store) {
    store.subscribeAction(action => {
        if (action.type !== 'USER/LOGOUT') {
            return;
        }

        store.dispatch(userActions.CLEAR);
        store.dispatch(userStatusActions.CLEAR);
        store.dispatch(editVehicleActions.CLEAR);
        store.dispatch(myVehiclesActions.CLEAR);
        store.dispatch(cartActions.CLEAR);
        store.dispatch(cmsActions.CLEAR);
        store.dispatch(impersonateActions.CLEAR);
        store.dispatch(filterActions.CLEAR_USER_MODIFIED_AUTO_ACTIVATED_FILTERS);
    });
}
