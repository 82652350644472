export default class PaymentService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    get(paymentId) {
        const config = {
            method: 'get',
            url: `/payments/${paymentId}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Create payment for the given "payable"
     *
     * @param {string} payableType 'installment'/'deposit'
     * @param {integer} payableId Payable ID
     * @param {object} stripeInfo | {stripeAccount, paymentMethodId, paymentMethodType, paymentWalletType}
     * @param {boolean} immediateCapture
     * @returns {Promise}
     */
    createPayment(payableType, payableId, stripeInfo, immediateCapture) {
        if (!stripeInfo.stripeAccount || !stripeInfo.paymentMethodId || !stripeInfo.paymentMethodType) {
            throw new Error(
                'Invalid stripe info. Missing stripeAccount, paymentMethodId or paymentMethodType.',
                stripeInfo
            );
        }

        const config = {
            method: 'post',
            url: `/payments`,
            data: {
                payableType,
                payableId,
                stripeAccount: stripeInfo.stripeAccount,
                method: 'stripe',
                paymentMethodId: stripeInfo.paymentMethodId,
                paymentMethodType: stripeInfo.paymentMethodType,
                immediateCapture,
            },
        };

        if (stripeInfo.paymentWalletType) {
            config.data.paymentWalletType = stripeInfo.paymentWalletType;
        }

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Create payment for the given "payable" with given deposit as method
     *
     * @param {string} payableType 'installment'/'deposit'
     * @param {integer} payableId Payable ID
     * @param {integer} depositId
     */
    createPaymentUsingDeposit(payableType, payableId, depositId) {
        const config = {
            method: 'post',
            url: `/payments`,
            data: { payableType, payableId, depositId, method: 'deposit' },
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
