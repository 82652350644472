<template>
    <div class="footer-social">
        <p class="footer-social__subtitle">{{ $t('footer_menu.follow_us_subtitle') }}</p>
        <ul>
            <li>
                <a href="https://www.facebook.com/mycamper.ch/" rel="noopener" target="_blank">
                    <svg-icon name="facebook-fill" />
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/mycamper.official" rel="noopener" target="_blank">
                    <svg-icon name="instagram" />
                </a>
            </li>
            <li>
                <a href="https://www.linkedin.com/company/mycamper-ag/mycompany/" rel="noopener" target="_blank">
                    <svg-icon name="linkedin" />
                </a>
            </li>
            <li>
                <a href="https://www.youtube.com/@mycamper.official" rel="noopener" target="_blank">
                    <svg-icon name="youtube" />
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'FooterSocial',
};
</script>

<style scoped lang="scss">
@import '@/sass/_variables.scss';

.footer-social {
    ul {
        display: flex;
        align-items: center;
    }

    .svg-icon {
        width: 32px;
        height: 32px;
    }

    li:not(:last-child) {
        margin-right: 16px;
    }

    &__subtitle {
        font-weight: bold;
        color: $gray-dark;
    }
}
</style>
