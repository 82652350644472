<template>
    <div
        :class="{
            'main-header--floating': isFloating,
            'is-mobile-menu-open': mobileMenuOpen,
        }"
        class="main-header"
    >
        <div class="main-header__container">
            <LocalizedLink :to="{ name: 'index' }" class="main-header__logo">
                <img :src="defaultLogoSrc" alt="MyCamper" />
            </LocalizedLink>
            <button
                class="button button--secondary-contrast main-header__mobile-menu-button"
                :class="{ 'is-open': mobileMenuOpen }"
                @click="setMobileMenuOpen(!mobileMenuOpen)"
            >
                <span v-if="!mobileMenuOpen">{{ $t('main_menu.mobile_menu_label') }}</span>
                <svg-icon v-if="!mobileMenuOpen" name="menu" />
                <svg-icon v-if="mobileMenuOpen" name="close" />
            </button>
            <MainMenuUser
                v-if="isLoggedIn && !(isAdmin || isEditor)"
                class="main-header__menu"
                :class="{ 'main-header__menu--is-closed': !mobileMenuOpen }"
            />
            <MainMenuGuest
                v-if="!isLoggedIn || isAdmin || isEditor"
                class="main-header__menu"
                :class="{ 'main-header__menu--is-closed': !mobileMenuOpen }"
            />
        </div>
        <GettingStartedProgressBar
            v-if="shouldDisplayProgressBar && !mobileMenuOpen"
            class="main-header__progress-bar"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionTypes as configActions } from '~/store/config';

import MainMenuUser from '~/components/navigation/MainMenuUser.vue';
import MainMenuGuest from '~/components/navigation/MainMenuGuest.vue';
import GettingStartedProgressBar from '~/components/getting-started/GettingStartedProgressBar.vue';

import defaultLogoSrc from '~/assets/images/mycamper-logo-with-text.svg';

export default {
    components: {
        MainMenuUser,
        MainMenuGuest,
        GettingStartedProgressBar,
    },
    props: {
        isFloating: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            defaultLogoSrc,
        };
    },
    head() {
        return {
            link: [
                {
                    hid: 'main-logo',
                    rel: 'preload',
                    as: 'image',
                    href: this.defaultLogoSrc,
                    fetchpriority: 'high',
                },
            ],
        };
    },
    computed: {
        ...mapGetters([
            'isLoggedIn',
            'isFullyOnboarded',
            'isFirstVehicleComplete',
            'isRenter',
            'isOwner',
            'isAdmin',
            'isEditor',
            'user',
            'hasActivatedAnyVehicleAtSomePoint',
            'mobileMenuOpen',
        ]),
        shouldDisplayProgressBar() {
            if (!this.isLoggedIn || this.isAdmin || this.isEditor || this.isRenter) {
                return false;
            }

            if (this.$route.meta && this.$route.meta.hideGettingStartedProgressBar) {
                return false;
            }

            if (this.isOwner) {
                if (!this.user.isNordic) {
                    return false;
                }

                return !this.isFullyOnboarded || !this.hasActivatedAnyVehicleAtSomePoint;
            }

            return false;
        },
    },
    watch: {
        $route: {
            handler() {
                this.setMobileMenuOpen(false);
            },
            immediate: true,
        },
    },
    methods: {
        setMobileMenuOpen(value) {
            this.$store.dispatch(configActions.SET_MOBILE_MENU_OPEN, value);
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.main-header {
    background: $white;
    z-index: $z-main-header;
    border-bottom: 1px solid $border-color;

    &.is-mobile-menu-open {
        z-index: $z-main-header-fullscreen;
    }

    &__menu--is-closed.main-menu {
        display: none;
    }

    &__mobile-menu-button.button {
        z-index: $z-mobile-menu-button;
        padding: 10px 12px;

        .svg-icon {
            width: 24px;
            height: 24px;
        }

        &.is-open {
            position: fixed;
            right: 0;
            border: none;
            background-color: transparent;

            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
            }

            .svg-icon {
                color: $primary;
                width: 32px;
                height: 32px;
            }
        }
    }

    &__logo {
        img {
            height: 20px;
            width: 143px;
            vertical-align: middle;

            @include xs {
                width: 178px;
                height: 25px;
            }
        }
    }

    &__container {
        padding: 0 20px;
        height: 70px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    @include menu-breakpoint {
        &__menu--is-closed.main-menu {
            display: flex;
        }
        &__mobile-menu-button.button {
            display: none;
        }

        &__container {
            height: $main-header-md-height;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &--floating {
        background: none;
        position: absolute;
        width: 100%;
    }
}
</style>
