export default class LeadService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    createLead(data) {
        const config = {
            method: 'post',
            url: '/leads',
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    // Legacy method name, to be renamed when we push "Call me"-leads to CRM instead of Support desk
    sendSupportEmail(data) {
        const config = {
            method: 'post',
            url: '/support',
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
