import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';

/**
 * Configure Google Tag Manager
 */
export default function ({ $config, app }) {
    const config = { ...$config.gtm };
    config.vueRouter = app.router;
    Vue.use(VueGtm, config);
}
