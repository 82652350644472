<template>
    <div class="main-footer">
        <div class="wrap">
            <div>
                <div class="main-footer__wrapper">
                    <div>
                        <div class="main-footer__logo">
                            <LocalizedLink :to="{ name: 'index' }">
                                <img width="178" height="25" :src="defaultLogoSrc" alt="MyCamper" />
                            </LocalizedLink>
                            <p>{{ $t('modals.login.rent_note') }}</p>
                        </div>
                        <button
                            v-if="newsletterButtonVisible"
                            class="button button--primary main-footer__newsletter-btn"
                            @click="displayNewsletterModal()"
                        >
                            {{ $t('footer_menu.subscribe_to_newsletter') }}
                        </button>
                    </div>

                    <FooterMenu />
                </div>

                <div class="main-footer__wrapper">
                    <FooterSocial />
                    <FooterCopyright />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import defaultLogoSrc from '~/assets/images/mycamper-logo-with-text.svg';
import FooterCopyright from '~/components/navigation/FooterCopyright';
import FooterSocial from '~/components/navigation/FooterSocial';
import FooterMenu from '~/components/navigation/FooterMenu';
import { actionTypes as modalActions } from '~/store/modal';

export default {
    components: { FooterMenu, FooterSocial, FooterCopyright },
    data() {
        return {
            defaultLogoSrc,
        };
    },
    computed: {
        ...mapGetters(['user']),
        newsletterButtonVisible() {
            return !this.user?.subscriptions?.newsletter;
        },
    },
    methods: {
        displayNewsletterModal(data) {
            this.$store.dispatch(modalActions.MODAL_OPEN, {
                component: 'NewsletterModal',
                data,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.main-footer {
    background: $cream-white;
    padding: 40px 10px 12px;
    @include font-size(16px);

    @include sm {
        padding: 40px 0 32px;
    }

    @include menu-breakpoint {
        .lang-fi & {
            @include font-size(14px);
        }
    }

    a {
        color: $primary-text-color;
    }

    &__newsletter-btn {
        width: 100%;

        @include sm {
            width: auto;
        }
    }

    .footer-menu {
        margin: 40px 0 24px;

        @include menu-breakpoint {
            margin: 0;
            display: flex;
        }
    }

    &__logo {
        margin-bottom: 40px;
        text-align: center;
        @include font-size(18px);

        p {
            margin: 8px 0 0;
        }

        img {
            vertical-align: middle;
        }

        @include menu-breakpoint {
            text-align: left;
        }
    }

    &__wrapper {
        @include menu-breakpoint {
            display: flex;
            justify-content: space-between;
        }
    }

    .footer-social {
        @include menu-breakpoint {
            order: 1;
            margin-right: 33px; // need this to be aligned with footer support links
        }
    }

    .footer-copyright {
        @media screen and (max-width: $bp-hamburger-menu-max) {
            margin-top: 24px;
            padding-top: 24px;
            border-top: 1px solid $primary;
        }
    }

    &__subtitle {
        font-weight: bold;
        color: $gray-dark;
    }

    .footer-link {
        text-decoration: none;
        border-bottom: 2px solid transparent;
        font-weight: 400;

        &.nuxt-link-active,
        &:hover {
            border-bottom-color: $primary-text-color;
        }
    }
}
</style>
